import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Message, Segment} from "semantic-ui-react";
import InlineError from "../messages/InlineError";
import TextStore from "../../stores/TextStore";

class ResetPasswordForm extends Component {
    state = {
        data: {
            token: this.props.token,
            password: '',
            passwordConfirmation: ''
        },
        loading: false,
        errors: {}
    };

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data, loading: false});
                        } else {
                            this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                        }
                    } else {
                        this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                    }
                });
        }
    };

    validate = data => {
        const errors = {};
        if (!data.password) errors.password = TextStore.getCantBeBlankError();
        if (!data.passwordConfirmation) errors.passwordConfirmation = TextStore.getCantBeBlankError();

        if (data.password && data.password.length < 4) errors.password = TextStore.getPasswordLengthError();
        if (data.passwordConfirmation &&
            data.passwordConfirmation.length < 4)
            errors.passwordConfirmation = TextStore.getPasswordLengthError();

        if (data.password !== data.passwordConfirmation) {
            errors.password = "";
            errors.passwordConfirmation = "";
            errors.global = "Пароли должны совпадать"
        }
        return errors;
    };

    render() {
        const {errors, data, loading} = this.state;
        return (
            <Form onSubmit={this.onSubmit} loading={loading}
                  className="large">
                <Segment stacked>
                    <Form.Field error={!!errors.password}>
                        <label htmlFor="password">Новый пароль</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Пароль"
                            value={data.password}
                            onChange={this.onChange}
                        />
                        {errors.password && <InlineError text={errors.password}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.passwordConfirmation}>
                        <label htmlFor="passwordConfirmation">Подтвердите пароль</label>
                        <input
                            type="password"
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            placeholder="Пароль еще раз"
                            value={data.passwordConfirmation}
                            onChange={this.onChange}
                        />
                        {errors.passwordConfirmation && <InlineError text={errors.passwordConfirmation}/>}
                    </Form.Field>
                    <Button primary fluid>Сохранить</Button>
                </Segment>
                {
                    errors.global &&
                    <Message negative>
                        <Message.Header>Ошибка</Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                }
            </Form>
        );
    }
}

ResetPasswordForm.propTypes = {
    submit: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired
};

export default ResetPasswordForm;