import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Divider, Header} from "semantic-ui-react";

class ApplicationUserPermissionForm extends Component {
    state = {
        permission: {}
    };

    componentDidMount() {
        this.setState({
            permission: this.props.permission
        })
    }

    onChange = (param, data) => {
        this.setState({
            ...this.state,
            permission: {...this.state.permission, [param.target.name]: data.checked}
        });
        // console.log("props", this.state.permission);
    };

    render() {
        const {permission} = this.state;
        return (
            <div>
                <Header as="h2">Пользователи</Header>
                <Divider/>
                <Checkbox name="allowCreateUser" id="allowCreateUser" onChange={this.onChange}
                          checked={permission.allowCreateUser} label="Создание пользователей"/><br/>
                <Checkbox name="allowEditUser" id="allowEditUser" onChange={this.onChange}
                          checked={permission.allowEditUser} label="Редактирование пользователей"/><br/>
                <Checkbox name="allowPrint" id="allowPrint" onChange={this.onChange}
                          checked={permission.allowPrint} label="Печать пользовательских штрихкодов"/>

                <Divider/>
                <Header as="h2">ТМЦ</Header>
                <Divider/>
                <Checkbox name="allowCreateItem" id="allowCreateItem" onChange={this.onChange}
                          checked={permission.allowCreateItem} label="Создание ТМЦ"/><br/>
                <Checkbox name="allowEditItem" id="allowEditItem" onChange={this.onChange}
                          checked={permission.allowEditItem} label="Редактирование ТМЦ"/><br/>
                <Checkbox name="allowDeleteItem" id="allowDeleteItem" onChange={this.onChange}
                          checked={permission.allowDeleteItem} label="Удаление ТМЦ"/><br/>
                <Checkbox name="allowProgrammingTags" id="allowProgrammingTags" onChange={this.onChange}
                          checked={permission.allowProgrammingTags} label="Программирование ТМЦ"/>

                <Divider/>
                <Header as="h2">Инвентаризация</Header>
                <Divider/>
                <Checkbox name="allowCloseInventory" id="allowCloseInventory" onChange={this.onChange}
                          checked={permission.allowCloseInventory} label="Закрытие инвентаризации"/><br/>
                <Checkbox name="allowCreateInventory" id="allowCreateInventory" onChange={this.onChange}
                          checked={permission.allowCreateInventory} label="Создание инвентаризации"/><br/>
                <Checkbox name="allowIssueInventoryTask" id="allowIssueInventoryTask" onChange={this.onChange}
                          checked={permission.allowIssueInventoryTask} label="Выдача заданий инвентаризации"/>

                <Divider/>
                <Header as="h2">Местоположения</Header>
                <Divider/>
                <Checkbox name="allowDeleteLocItem" id="allowDeleteLocItem" onChange={this.onChange}
                          checked={permission.allowDeleteLocItem} label="Удаление местоположений"/>

                <Divider/>
                <Header as="h2">Справочники</Header>
                <Divider/>
                <Checkbox name="allowEditCatalogs" id="allowEditCatalogs" onChange={this.onChange}
                          checked={permission.allowEditCatalogs} label="Редактирование справочников"/>
            </div>
        );
    }
}


ApplicationUserPermissionForm.propTypes = {
    permission: PropTypes.shape({
        allowCloseInventory: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowDeleteItem: PropTypes.bool.isRequired,
        allowDeleteLocItem: PropTypes.bool.isRequired,
        id: PropTypes.string.isRequired
    }).isRequired
};

export default ApplicationUserPermissionForm;