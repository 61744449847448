import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Message} from "semantic-ui-react";
import InlineError from "../../messages/InlineError";
import TextStore from "../../../stores/TextStore";

class ItemTagForm extends Component {
    state = {
        loading: false,
        errors: {},
        data: {
            data: ''
        }
    };

    componentDidMount() {
        if (this.props.tagData)
            this.setState({data: {data: this.props.tagData}});
    }

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => this.setState({errors: err.response.data.errors, loading: false}));
        }
    };

    validate = data => {
        const errors = {};
        if (!data.data) errors.data = TextStore.getCantBeBlankError();
        return errors;
    };

    render() {
        const {data, loading, errors} = this.state;
        let readOnly = this.props.readOnly;
        return (
            <Form
                loading={loading}
                onSubmit={this.onSubmit}
                className="large">
                <Form.Field error={!!errors.data}>
                    <label htmlFor="data">Метка</label>
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="data"
                        name="data"
                        placeholder="000000000000000000000000"
                        value={data.data}
                        onChange={this.onChange}
                    />
                    {errors.data && <InlineError text={errors.data}/>}
                </Form.Field>

                {<Button fluid primary>{this.props.readOnly ? "Подтвердить" : "Сохранить"}</Button>}
                {
                    errors.global &&
                    <Message negative>
                        <Message.Header>Ошибка</Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                }
            </Form>
        );
    }
}


ItemTagForm.propTypes = {
    submit: PropTypes.func.isRequired,
    tagId: PropTypes.string.isRequired,
    tagData: PropTypes.string,
    readOnly: PropTypes.bool.isRequired
};

export default ItemTagForm;