import React, {Component} from 'react';
import LicenseForm from "../forms/license/LicenseForm";
import {Header} from 'semantic-ui-react'

class LicensePage extends Component {
    render() {
        return (
            <div style={{width:'100%', textAlign: 'center'}}>
                <div style={{width:'600px', margin: '30px', display: 'inline-block',textAlign: 'left'}}>
                    <Header as='h1'>Информация о лицензии</Header>
                    <LicenseForm/>
                </div>
            </div>
        );
    }
}

export default LicensePage