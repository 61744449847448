import _ from 'lodash'
import React, {Component} from 'react'
import {Button, Dropdown, Icon, Message, Modal, Table} from 'semantic-ui-react'
import api from '../../../api';
import {Link} from "react-router-dom";
import TextStore from "../../../stores/TextStore";
import ApplicationUserPermissionForm from '../../forms/applicationUsers/ApplicationUserPermissionForm';
import {connect} from "react-redux";
import PropTypes from "prop-types";

class ApplicationUsersPage extends Component {
    state = {
        column: null,
        data: [],
        direction: null,
        openUserPermission: false,
        loadingPermission: false,
        errorsUserPermission: {},
        userPermission: {
            allowCloseInventory: false,
            allowCreateInventory: false,
            allowCreateItem: false,
            allowCreateUser: false,
            allowEditCatalogs: false,
            allowEditItem: false,
            allowEditUser: false,
            allowIssueInventoryTask: false,
            allowPrint: false,
            allowProgrammingTags: false,
            allowDeleteItem : false,
            allowDeleteLocItem : false,
            id: ""
        }
    };

    userPermissionForm;

    componentDidMount() {
        this.updateDrivers();
    }

    updateDrivers() {
        api.applicationUsers.getApplicationUsers()
            .then(n => {
                this.setState({
                    data: n.data.applicationUsers
                });
            });
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    showUserPermission = (id) => () => {
        this.setState({
            openUserPermission: true,
            loadingPermission: true
        });
        api.applicationUsers.getPermission(id)
            .then(res => this.setState({
                userPermission: res.data.permission,
                loadingPermission: false
            }))
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errorsUserPermission: err.response.data.errors, loadingPermission: false});
                    } else {
                        this.setState({
                            errorsUserPermission: {global: TextStore.getUnknownNetworkError()},
                            loadingPermission: false
                        });
                    }
                } else {
                    this.setState({
                        errorsUserPermission: {global: TextStore.getMethodNotFoundError()},
                        loadingPermission: false
                    });
                }
            });
    };

    close = (result) => () => {
        this.setState({errorsUserPermission: {}});
        if (!result) {
            this.setState({openUserPermission: false});
            return;
        }
        if (!this.userPermissionForm)
            return;
        if (!this.userPermissionForm.state)
            return;
        if (!this.userPermissionForm.state.permission)
            return;
        api.applicationUsers.savePermission(this.userPermissionForm.state.permission)
            .then(() => this.setState({openUserPermission: false}))
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errorsUserPermission: err.response.data.errors, loadingPermission: false});
                    } else {
                        this.setState({
                            errorsUserPermission: {global: TextStore.getUnknownNetworkError()},
                            loadingPermission: false
                        });
                    }
                } else {
                    this.setState({
                        errorsUserPermission: {global: TextStore.getMethodNotFoundError()},
                        loadingPermission: false
                    });
                }
            });
    };

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {column, data, direction, openUserPermission, loadingPermission, errorsUserPermission, userPermission} = this.state;

        return (
            <div className="ui container">
                <Table sortable singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={column === 'name' ? direction : null}
                                              onClick={this.handleSort('name')}>
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'lastName' ? direction : null}
                                              onClick={this.handleSort('lastName')}>
                                Фио
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'userName' ? direction : null}
                                              onClick={this.handleSort('userName')}>
                                Имя пользователя
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'comment' ? direction : null}
                                              onClick={this.handleSort('comment')}>
                                Комментарий
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>
                                Действия
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(data, ({id, email, firstName, lastName, surName, userName, comment}) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{email}</Table.Cell>
                                    <Table.Cell>{lastName + " " + firstName + " " + surName}</Table.Cell>
                                    <Table.Cell>{userName}</Table.Cell>
                                    <Table.Cell>{comment}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {this.props.permission.allowEditUser && (
                                            <Dropdown text='Действия'>
                                                <Dropdown.Menu>
                                                    {this.props.permission.allowEditUser && (
                                                        <Dropdown.Item
                                                            icon={"unlock"}
                                                            text='Права'
                                                            onClick={this.showUserPermission(id)}
                                                        />
                                                    )}
                                                    {this.props.permission.allowEditUser && (
                                                        <Dropdown.Item
                                                            as={Link}
                                                            to={"/applicationUser/edit/" + id}
                                                            target={"_blank"}
                                                            icon={"edit"}
                                                            text='Изменить'
                                                        />
                                                    )}
                                                    {this.props.permission.allowEditUser && (
                                                        <Dropdown.Item
                                                            as={Link}
                                                            to={"/applicationUser/delete/" + id}
                                                            target={"_blank"}
                                                            icon={"delete"}
                                                            text='Удалить'
                                                        />
                                                    )}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell>
                                {this.props.permission.allowCreateUser && (
                                    <Button
                                        as={Link}
                                        to="/applicationUser/create/0"
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        positive
                                        size='small'
                                    >
                                        <Icon name='group'/> Добавить
                                    </Button>
                                )}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>

                <Modal
                    dimmer="blurring"
                    className="middle aligned center aligned"
                    style={this.inlineStyle.modal}
                    open={openUserPermission}>
                    <Modal.Header>
                        Настройка прав
                    </Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        {loadingPermission &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}
                        {!loadingPermission &&
                        <ApplicationUserPermissionForm permission={userPermission} ref={(form) => {
                            this.userPermissionForm = form
                        }}/>}
                        {errorsUserPermission.global && <Message negative>
                            <Message.Header>Ошибка</Message.Header>
                            <p>{errorsUserPermission.global}</p>
                        </Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Отмена"
                            onClick={this.close(false)}
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Применить'
                            onClick={this.close(true)}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}

ApplicationUsersPage.propTypes = {
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(ApplicationUsersPage);