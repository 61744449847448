import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from "react-router-dom";
import {connect} from 'react-redux';
import AuthStore from "../../stores/AuthStore";

const UserRoute = ({isAuthenticated, component: Component, ...rest}) => (
    <Route {...rest} render={props => isAuthenticated ? <Component {...props}/> : <Redirect to="/"/>}/>
);

UserRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    if (window.location.pathname.replace('/', '').length > 0) {
        if (AuthStore.getRoute() !== '--logout') {
            AuthStore.setRoute(window.location.pathname.replace('/', ''));
        }
    }
    return {
        isAuthenticated: !!state.user.token
    }
}

export default connect(mapStateToProps)(UserRoute);