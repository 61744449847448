import React from 'react';
import {connect} from 'react-redux';
import {Grid, Header, Icon, Message} from "semantic-ui-react";
import PropTypes from 'prop-types';
import api from "../../../api";
import TextStore from "../../../stores/TextStore";
import ApplicationUserForm from "../../forms/applicationUsers/ApplicationUserForm";
import PermissionDenied from "../../messages/PermissionDenied";

class ApplicationUserPage extends React.Component {

    state = {
        loading: true,
        applicationUser: {},
        departments: {},
        positions: {},
        success: true,
        errors: {}
    };

    componentDidMount() {
        api.departments.getDepartments().then(deps => {
            this.setState({departments: deps.data.departments});
            api.positions.getPositions().then(pos => {
                this.setState({positions: pos.data.positions});
                if (this.props.match.params.type === 'edit' ||
                    this.props.match.params.type === 'delete') {

                    api.applicationUsers.getApplicationUser(this.props.match.params.id)
                        .then(res => {
                            this.setState({
                                loading: false,
                                success: true,
                                applicationUser: res.data.applicationUser
                            });
                        })
                        .catch(err => {
                            if (!!err.response) {
                                if (err.response.data) {
                                    this.setState({errors: err.response.data.errors, loading: false, success: false});
                                } else {
                                    this.setState({
                                        errors: {global: TextStore.getUnknownNetworkError()},
                                        loading: false,
                                        success: false
                                    });
                                }
                            } else {
                                this.setState({
                                    errors: {global: TextStore.getMethodNotFoundError()},
                                    loading: false,
                                    success: false
                                });
                            }
                        });
                } else if (this.props.match.params.type === 'create') {
                    setTimeout(() => {
                        this.setState({
                            loading: false,
                            success: true,
                            applicationUser: {
                                department: deps.data.departments[0].id,
                                position: pos.data.positions[0].id,
                                comment: '',
                                email: '',
                                firstName: '',
                                lastName: '',
                                surName: '',
                                userName: ''
                            }
                        });
                    }, 0);
                }
            });
        });
    }

    submit = (data) => {
        if (this.props.match.params.type !== 'delete') {
            return api.applicationUsers.saveApplicationUser(data)
                .then(res => this.props.history.push('/applicationUsers'));
        } else {
            return api.applicationUsers.deleteApplicationUser(this.props.match.params.id)
                .then(res => this.props.history.push('/applicationUsers'));
        }
    };

    render() {
        const {loading, success, errors, applicationUser, departments, positions} = this.state;
        let mode = '';
        let hasPerm = false;
        switch (this.props.match.params.type) {
            case "create":
                mode = 'Создание';
                hasPerm = this.props.permission.allowCreateUser;
                break;
            case "delete":
                mode = 'Удаление';
                hasPerm = this.props.permission.allowEditUser;
                break;
            case "edit":
                mode = 'Редактирование';
                hasPerm = this.props.permission.allowEditUser;
                break;
            default:
                mode = "";
                break;
        }
        return (
            <Grid className="middle aligned center aligned"
                  style={{height: '100%'}}>
                <div className="column"
                     style={{maxWidth: '450px'}}>

                    {!hasPerm &&
                    <PermissionDenied text={"редактированию справочников"}/>}

                    {hasPerm && (
                        <div>
                            {!loading && success && (
                                <Header className="image">
                                    <Header.Content>
                                        {mode}
                                    </Header.Content>
                                </Header>
                            )}

                            {loading && (<Message icon>
                                <Icon name="circle notched" loading/>
                                <Message.Header>Загрузка</Message.Header>
                            </Message>)}
                            {
                                !loading && !success && (
                                    <Message negative>
                                        <Message.Header>Ошибка</Message.Header>
                                        <p>{errors.global}</p>
                                    </Message>
                                )}
                            {
                                !loading && success && (
                                    <ApplicationUserForm
                                        applicationUser={applicationUser}
                                        departments={departments}
                                        positions={positions}
                                        submit={this.submit}
                                        readOnly={this.props.match.params.type === 'delete'}/>
                                )
                            }
                        </div>

                    )}
                </div>
            </Grid>
        );
    }

}

ApplicationUserPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(ApplicationUserPage);