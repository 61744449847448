import React, {Component} from 'react';
import api from "../../../api";
import {Button, Container, Icon, Modal, Table} from "semantic-ui-react";
import _ from "lodash";
import ClientForm from "../../forms/admin/ClientForm";
import ClientUsersPage from "./ClientUsersPage";

class ClientsPage extends Component {
    state = {
        data: [],
        isCreateClientModalOpen: false,
        login: '',
        email: '',
        password: '',
        isShowNewClientUser: false,
        isUsersShow: false,
        client: {}
    };

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        api.admin.getClients()
            .then(res => this.setState({data: res}))
    }

    openCreateClientModal = () => {
        this.setState({isCreateClientModalOpen: true});
    };

    closeCreateClientModal = () => {
        this.setState({isCreateClientModalOpen: false});
    };

    submit = (data) => {
        return api.admin.createClient(data)
            .then(res => {
                this.setState({login: res.login, email: res.email, password: res.password});
                this.setState({isCreateClientModalOpen: false, isShowNewClientUser: true});
                return res;
            });
    };

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {data, isCreateClientModalOpen, login, email, password, isShowNewClientUser,
            isUsersShow, client} = this.state;
        let rowCount = 0;
        return (
            <Container>
                <Table selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>Название</Table.HeaderCell>
                            <Table.HeaderCell>ТМЦ</Table.HeaderCell>
                            <Table.HeaderCell>Пользователей</Table.HeaderCell>
                            <Table.HeaderCell>Тариф</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(data, ({client, itemsCount, usersCount, tariffType}) => {
                            rowCount++;
                            return (
                                <Table.Row key={client.id}>
                                    <Table.Cell>{rowCount}</Table.Cell>
                                    <Table.Cell>{client.name}</Table.Cell>
                                    <Table.Cell>{itemsCount}</Table.Cell>
                                    <Table.Cell><a href={"#"} onClick={() =>
                                    this.setState({isUsersShow: true, client: client})} >{usersCount}</a></Table.Cell>
                                    <Table.Cell>{tariffType}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={'4'}>
                                <b>
                                    Всего: {rowCount}
                                </b>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Button
                                    onClick={this.openCreateClientModal}
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    positive
                                    size='small'>
                                    <Icon name='plus'/> Добавить
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>

                <Modal closeIcon
                       className="middle aligned center aligned"
                       open={isCreateClientModalOpen}
                       dimmer="blurring"
                       style={this.inlineStyle.modal}
                       onClose={this.closeCreateClientModal}>
                    <Modal.Header>Создание клиента</Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        <ClientForm submit={this.submit}/>
                    </Modal.Content>
                </Modal>

                <Modal closeIcon
                       className="middle aligned center aligned"
                       open={isShowNewClientUser}
                       dimmer="blurring"
                       style={this.inlineStyle.modal}
                       onClose={() => {
                           this.setState({isShowNewClientUser: false});
                           this.updateData();
                       }}>
                    <Modal.Header>Данные для подключения</Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        EMail: {email}<br/>
                        login: {login}<br/>
                        password: {password}
                    </Modal.Content>
                </Modal>

                <Modal closeOnDimmerClick={false}
                       closeIcon={true}
                       open={isUsersShow}
                       dimmer={"blurring"}
                       style={this.inlineStyle.modal}
                       onClose={() => {
                           this.setState({isUsersShow: false});
                           this.updateData();
                       }}>
                    <Modal.Header>Пользователи</Modal.Header>
                    <Modal.Content>
                        <ClientUsersPage users={client.users} clientId={client.id}/>
                    </Modal.Content>
                </Modal>
            </Container>
        );
    }
}


ClientsPage.propTypes = {};

export default ClientsPage;