import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Form, Message, Modal} from "semantic-ui-react";
import api from "../../../api";
import InlineError from "../../messages/InlineError";
import TextStore from "../../../stores/TextStore";

class ItemMultiChangesForm extends Component {
    state = {
        loading: true,
        data: {
            state: -1,
            mol: "-1",
            holder: "-1",
            location: "-1"
        },
        errors: {},
        applicationUsers: {},
        locationItems: {},
        states: [
        {
            key: -1,
            value: -1,
            text: 'Не менять'
        }, {
            key: 0,
            value: 0,
            text: 'Получено'
        }, {
            key: 1,
            value: 1,
            text: 'Оприходовано'
        }, {
                key: 2,
                value: 2,
                text: 'Списано'
        }, {
                key: 3,
                value: 3,
                text: 'Утрачено'
        }]
    };

    constructor(state){
        super(state);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        api.applicationUsers.getApplicationUsers()
            .then(users => {
                api.locationItems.getNotDeletedLocationItems()
                    .then(locationItems => {
                        let builds = locationItems.flatMap(b => b.children!==null?
                            b.children.flatMap(l=>l.children!==null?
                                l.children.flatMap( r =>
                                ({
                                    key: r.id,
                                    text: b.name+"/" + l.name + "/"+ r.name,
                                    value: r.id
                                })
                            ):[]):[]);

                        console.log(builds);
                        this.setState({
                            loading: false,
                            data: {
                                state: -1,
                                mol: "-1",
                                holder: "-1",
                                location: "-1",
                            },
                            applicationUsers: [{
                                key: "-1",
                                text: "Не менять",
                                value: "-1"
                            },].concat(users.data.applicationUsers.map(x => ({
                                key: x.id,
                                text: x.lastName + " " + x.firstName + " " + x.surName,
                                value: x.id
                            }))),
                            locationItems: [{
                                key: "-1",
                                text: "Не менять",
                                value: "-1"
                            },].concat(builds)
                        });
                    })
            })
    }

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onChangeMol = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, mol: data.value}
            });
        }
    };
    onChangeHolder = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, holder: data.value}
            });
        }
    };

    onChangeLocation = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, location: data.value}
            });
        }
    };

    onChangeState = (param, data) => {
        if (data.value!==undefined) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, state: data.value}
            });
        }
    };

    onSubmit (){
        this.props.submit(this.state.data)
    };

    validId() {
        const {state,mol,holder,location} = this.state.data;
        return (state!==-1)||(mol!=="-1")||(holder!=="-1")||(location!=="-1");
    };


    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {loading, errors, data, applicationUsers, locationItems, states} = this.state;
        let stateText = '';
        switch (data.state) {
            case -1:
                stateText = 'Не менять';
                break;
            case 0:
                stateText = 'Получено';
                break;
            case 1:
                stateText = 'Оприходовано';
                break;
            case 2:
                stateText = 'Списано';
                break;
            case 3:
                stateText = 'Утрачено';
                break;
            default:
                stateText = '';
                break;
        }
        return (
            <Modal
                closeIcon
                open={true}
                onClose={this.props.close}
                style={this.inlineStyle.modal}
                dimmer="blurring"
            >
                <Modal.Header>
                    Массовое изменение ({this.props.cnt}шт единиц)
                </Modal.Header>
                <Modal.Content size="medium">
                    <Form
                        loading={loading}
                        onSubmit={this.onSubmit}
                        className="large">

                        <Form.Field error={!!errors.mol}>
                            <label htmlFor="mol">Новый МОЛ</label>

                            <Dropdown
                                options={applicationUsers}
                                button
                                scrolling
                                fluid
                                id="mol"
                                name="mol"
                                value={data.mol}
                                onChange={this.onChangeMol}/>
                            {errors.mol && <InlineError text={errors.mol}/>}
                        </Form.Field>

                        <Form.Field error={!!errors.holder}>
                            <label htmlFor="holder">Новый держатель</label>
                            <Dropdown
                                options={applicationUsers}
                                button
                                scrolling
                                fluid
                                id="holder"
                                name="holder"
                                value={data.holder}
                                onChange={this.onChangeHolder}/>
                            {errors.holder && <InlineError text={errors.holder}/>}
                        </Form.Field>

                        <Form.Field error={!!errors.location}>
                            <label htmlFor="location">Новое местоположение</label>
                            <Dropdown
                                options={locationItems}
                                button
                                scrolling
                                fluid
                                id="location"
                                name="location"
                                value={data.location}
                                onChange={this.onChangeLocation}/>
                            {errors.location && <InlineError text={errors.location}/>}
                        </Form.Field>

                        <Form.Field error={!!errors.state}>
                            <label htmlFor="state">Новый статус</label>

                            <Dropdown
                                options={states}
                                button
                                scrolling
                                fluid
                                id="state"
                                name="state"
                                value={data.state}
                                onChange={this.onChangeState}/>
                            {errors.state && <InlineError text={errors.state}/>}
                        </Form.Field>
                        {
                            errors.global &&
                            <Message negative>
                                <Message.Header>Ошибка</Message.Header>
                                <p>{errors.global}</p>
                            </Message>
                        }
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button fluid primary disabled={!this.validId()} onClick={this.onSubmit}>Применить изменения</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}


ItemMultiChangesForm.propTypes = {
    submit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    cnt: PropTypes.number.isRequired,
};

export default ItemMultiChangesForm;