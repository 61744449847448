import React, {Component} from 'react';
import {Button, Form, Message, Segment} from 'semantic-ui-react';
import isEmail from 'validator/lib/isEmail';
import InlineError from '../messages/InlineError';
import TextStore from "../../stores/TextStore";
import {Link} from "react-router-dom";
import "./ForgotPasswordForm.css";

class ForgotPasswordForm extends Component {
    state = {
        data: {
            email: ''
        },
        loading: false,
        errors: {}
    };

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data, loading: false});
                        } else {
                            this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                        }
                    } else {
                        this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                    }
                });
        }
    };

    validate = data => {
        const errors = {};
        if (!isEmail(data.email)) errors.email = "Неверный email";
        return errors;
    };

    render() {
        const {errors, data, loading} = this.state;

        return (
            <Form onSubmit={this.onSubmit}
                  loading={loading}
                  id="fpform"
            >
                <Segment id = "fpsegment" stacked>
                    <Form.Field error={!!errors.email}  id = "fpformfield" >
                        <label htmlFor='email'/>
                        <input
                            type='email'
                            id='email'
                            name='email'
                            placeholder='Введите почту'
                            value={data.email}
                            onChange={this.onChange}
                        />
                        {errors.email && <InlineError text={errors.email}/>}
                    </Form.Field>
                    <Button primary fluid id = 'fpbutton'>Восстановить пароль</Button>
                    <Link to='/' id = 'fpgetback'  >Вернуться к авторизации</Link>
                </Segment>

                {
                    errors.global &&
                    <Message negative>
                        <Message.Header>Ошибка</Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                }
            </Form>
        );
    }
}

ForgotPasswordForm.propTypes = {};

export default ForgotPasswordForm;