import './HomePage.css';
import React from 'react';
import {connect} from 'react-redux';
import {login} from '../../actions/auth';
import {Button, Card, Divider, Grid, Header, Icon, Image, List, Menu, Message, Popup, Segment, Container} from "semantic-ui-react";
import PropTypes from 'prop-types';
import AuthStore from "../../stores/AuthStore";
import {Link} from "react-router-dom";
import LoginForm from "../forms/LoginForm";

class HomePage extends React.Component {

    submit = (data) => this.props.login(data).then(() => {
        if (AuthStore.getRoute() === '--logout') {
            AuthStore.setRoute('mtc');
        }
        window.location.replace(AuthStore.getRoute());
    });

    /*eslint-disable */
    render() {
        let isAuthenticated = !!AuthStore.getToken();
        return (

            <Segment id="responsive">

                <Image src='loginback.jpg'
                       className="graybackground"
                        />

                <Image src='bg2_1831х1420.jpg'
                       className="background"
                        />

                <Image src='waves with animation.svg'
                       className="waves"
                        />
                <Image src='tablet_in_hands.png'
                       className="hands"
                        />


                    {!isAuthenticated && <LoginForm
                        className="responsiveform"
                        submit={this.submit}/>}



            </Segment>


            /*<div id="home">
                <Segment inverted centered className="masthead centered">
                    <Grid className="page">
                        <Grid.Column>
                            <Menu secondary pointing fixed className={"menu-without-border-bottom"}>
                                <Menu.Item
                                    style={{fontSize: "11pt", fontWeight: "bold", color: "WHITE"}}>
                                    ID Logic
                                </Menu.Item>
                                <a
                                    className={"item"}
                                    href="#why_us">
                                    Почему мы?
                                </a>
                                <a
                                    className={"item"}
                                    href="#tariff">
                                    Тарифы
                                </a>
                                <Menu.Menu position="right">
                                    <Menu.Item
                                        as={Link}
                                        to="/login">
                                        Войти
                                    </Menu.Item>
                                </Menu.Menu>
                            </Menu>

                            <div className="ui transition information">
                                <h1 className="ui inverted centered header">
                                    Хороший заголовок
                                </h1>
                                <p className="ui centered lead">О проекте...</p>
                                <Image src={"1-inv.png"}/>
                                <a href="#" className="large basic inverted animated fade ui button">
                                    <div className="visible content">Хотите узнать больше?</div>
                                    <div className="hidden content">Закажите звонок!</div>
                                </a>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Segment>

                <Segment className={"feature"} id={"why_us"}>
                    <Grid className={"centered page"} style={{marginTop: '5em'}}>
                        <Grid.Column className={"fourteen wide"}>
                            <Header className={"aligned center"} as={"h1"}>
                                Почему мы
                            </Header>
                            <Divider horizontal><Icon name="question"/></Divider>
                            <Image src={"2-inv.png"} centered style={{marginTop: '5em'}}/>
                            <Grid divided stackable className={"five column center aligned"}
                                  style={{marginTop: '4em'}}>
                                <Grid.Column className={"column-feature"}>
                                    <Header icon><Icon name={"shipping fast"}/>Быстро</Header>
                                    <p>Даже с телефона</p>
                                </Grid.Column>
                                <Grid.Column className={"column-feature"}>
                                    <Header icon><Icon name={"heart"}/>Удобно</Header>
                                    <p>Но это не точно</p>
                                </Grid.Column>
                                <Grid.Column className={"column-feature"}>
                                    <Header icon><Icon name={"plus"}/>Качественно</Header>
                                    <p>Или нет</p>
                                </Grid.Column>
                                <Grid.Column className={"column-feature"}>
                                    <Header icon><Icon name={"fighter jet"}/>Не лагает</Header>
                                    <p>*по сравнения с старым решением</p>
                                </Grid.Column>
                                <Grid.Column className={"column-feature"}>
                                    <Header icon><Icon name={"bug"}/>Работает!</Header>
                                    <p>Он действительно работает!</p>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Segment>

                <Segment className={"feature"} id={"tariff"}>
                    <Grid className={"centered page very relaxed"} style={{marginTop: '5em'}}>
                        <Grid.Column className={"fourteen wide"}>

                            <Header className={"aligned center"} as={"h1"}>
                                Тарифы
                            </Header>
                            <Divider horizontal><Icon name="ruble sign"/></Divider>
                            <p style={{marginTop: '5em'}}>
                                Тут можно расписать, что мы можем предоставить дополнительные услуги (офлайн установка)
                            </p>
                            <Grid stackable className={"three column aligned"}
                                  style={{marginTop: '4em'}}>
                                <Grid.Column>
                                    <Card raised className={"card-feature"}>
                                        <Image src={"http://p-plaza.com/wp-content/uploads/2017/09/Pr-HHL2.jpg"}/>
                                        <Card.Content>
                                            <Card.Header>Базовый</Card.Header>
                                            <Card.Description>
                                                <List>
                                                    <List.Item icon={"checkmark"} content={"Доступ к облаку"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во пользователей"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во инвентаризаций"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченные дерево/структура организации"}/>
                                                    <List.Item>
                                                        <List.Icon name={"cancel"}/>
                                                        <List.Content>
                                                            Ограниченное кол-во МТЦ
                                                            <Popup
                                                                trigger={<Icon name={"question"}/>}
                                                                content={"Будет доступно 3500 МТЦ"}/>
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <List.Icon name={"cancel"}/>
                                                        <List.Content>
                                                            Ограниченное кол-во ТСД
                                                            <Popup
                                                                trigger={<Icon name={"question"}/>}
                                                                content={"Будет доступен только 1 ТСД"}/>
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item>
                                                        <List.Icon name={"cancel"}/>
                                                        <List.Content>
                                                            Гос. отчеты
                                                            <Popup
                                                                trigger={<Icon name={"question"}/>}
                                                                content={"Не будут доступны выгрузки гос. отчеты (ИНВ-1, ИНВ-18)"}/>
                                                        </List.Content>
                                                    </List.Item>
                                                    <List.Item icon={"cancel"} content={"Интеграция с внутренней ИС"}/>
                                                    <List.Item icon={"cancel"} content={"Прямая онлайн поддержка"}/>
                                                </List>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button fluid basic color={"yellow"}>Выбрать</Button>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column>
                                    <Card raised>
                                        <Image src={"http://p-plaza.com/wp-content/uploads/2017/09/Pr-HHL2.jpg"}/>
                                        <Card.Content>
                                            <Card.Header>Полный</Card.Header>
                                            <Card.Description>
                                                <List>
                                                    <List.Item icon={"checkmark"} content={"Доступ к облаку"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во пользователей"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во инвентаризаций"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченные дерево/структура организации"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во МТЦ"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во ТСД"}/>
                                                    <List.Item icon={"checkmark"} content={"Гос. отчеты"}/>
                                                    <List.Item icon={"cancel"} content={"Интеграция с внутренней ИС"}/>

                                                    <List.Item icon={"cancel"} content={"Прямая онлайн поддержка"}/>
                                                </List>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button fluid basic color={"green"}>Выбрать</Button>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                                <Grid.Column>
                                    <Card raised>
                                        <Image src={"http://p-plaza.com/wp-content/uploads/2017/09/Pr-HHL2.jpg"}/>
                                        <Card.Content>
                                            <Card.Header>Премиум</Card.Header>
                                            <Card.Description>

                                                <List>
                                                    <List.Item icon={"checkmark"} content={"Доступ к облаку"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во пользователей"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во инвентаризаций"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченные дерево/структура организации"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во МТЦ"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Неограниченное кол-во ТСД"}/>
                                                    <List.Item icon={"checkmark"} content={"Гос. отчеты"}/>
                                                    <List.Item icon={"checkmark"}
                                                               content={"Интеграция с внутренней ИС"}/>
                                                    <List.Item icon={"checkmark"} content={"Прямая онлайн поддержка"}/>
                                                </List>
                                            </Card.Description>
                                        </Card.Content>
                                        <Card.Content extra>
                                            <Button fluid basic color={"purple"}>Выбрать</Button>
                                        </Card.Content>
                                    </Card>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Segment>

                <Segment inverted className={"footer center"} vertical>
                    <Grid className={"page center aligned"} stackable>
                        <Grid.Row className={"four column"}>
                            <Grid.Column>
                                <Header as={"h5"} inverted>Компания</Header>

                                <List inverted link>
                                    <a
                                        className={"item"}
                                        href="https://id-logic.ru/corp/company"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        О нас
                                    </a>
                                    <a
                                        className={"item"}
                                        href="https://id-logic.ru/corp/contacts"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Контакты
                                    </a>
                                </List>
                            </Grid.Column>

                            <Grid.Column>
                                <Header as={"h5"} inverted>Проект</Header>

                                <List inverted link>
                                    <a
                                        className={"item"}
                                        href="#why_us">
                                        Почему мы?
                                    </a>
                                    <a
                                        className={"item"}
                                        href="#tariff">
                                        Тарифы
                                    </a>
                                </List>
                            </Grid.Column>

                            <Grid.Column>
                                <Header as={"h5"} inverted>Правила</Header>

                                <List inverted link>
                                    <a
                                        className={"item"}
                                        href="#">
                                        Условия использования
                                    </a>
                                    <a
                                        className={"item"}
                                        href="#">
                                        Политика конфиденциальности
                                    </a>
                                </List>
                            </Grid.Column>


                            <Grid.Column>
                                <p>© IDLogic — технологии для автоматизации вашего бизнеса.</p>
                                <p>Все права защищены. 2004 - 2018</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </div>*/
        );
    }

    /*eslint-enable */
}

HomePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};


export default connect(null, {login})(HomePage);