import React, {Component, createRef} from 'react';
import {Button, Container, Dropdown, Form, Icon, Message, Modal, Pagination, Table, Popup, Dimmer, Loader, Image, Segment} from "semantic-ui-react";
import api from "../../api";
import _ from "lodash";
import PropTypes from 'prop-types';
import ItemForm from "../forms/items/ItemForm";
import ItemChangesForm from '../forms/items/ItemChangesForm';
import ItemTagsForm from '../forms/items/ItemTagsForm';
import ReactTooltip from 'react-tooltip'
import TextStore from "../../stores/TextStore";
import {connect} from "react-redux";
import Pagger from "./Pagger";
import Select from "semantic-ui-react/dist/es/addons/Select/Select";
import ItemMultiChangesForm from "../forms/items/ItemMultiChangesForm";
import Checkbox from "semantic-ui-react/dist/es/modules/Checkbox/Checkbox";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";

class ItemsView extends Component {
    state = {
        loading: false,
        data: [],
        column: 'Name',
        direction: 'ascending',
        open: false,
        openItemChanges: false,
        readOnly: false,
        multiEdit: false,
        isShow: false,
        id: '',
        name:'',
        infoId: '',
        openTags: false,
        pageCurrent: 1,
        from:0,
        dataCount: 0,
        pageSize: api.paging.longTableRowsCount,
        filter: '',
        openMultiChanges: false,
        selectedItems: [],
        allPossibleSelected: [],
        bloburl: undefined,
        imgLoading: true
    };


    constructor(state) {
        super(state);
        this.submit = this.submit.bind(this);
        this.submitClose = this.submitClose.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.showMultiItemChange = this.showMultiItemChange.bind(this);
        this.submitMultipleEdit = this.submitMultipleEdit.bind(this);
        this.closeMultiChanges = this.closeMultiChanges.bind(this);
        this.startMultiEdit = this.startMultiEdit.bind(this);
        this.stopMultiEdit = this.stopMultiEdit.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.getPhoto = this.getPhoto.bind(this);
        this.clearPhoto = this.clearPhoto.bind(this);
        this.onExport = this.onExport.bind(this);

    }

    componentDidMount() {
        // this.setState({loading: true});
        this.setState({
            pageCurrent: 1
        });
        this.update();
        this.getPhoto(this.state.infoId)
    }

    update() {
        this.setState({loading: true, filter: ""});
        api.items.getCount(this.props.locationId, this.state.filter)
            .then(count => {
                this.setState({pageCurrent: 1, dataCount: count});
                this.updateItems(this.state.column, this.state.direction, 1, 0, this.state.pageSize, this.state.filter);
            })
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data.errors, loading: false});
                    } else {
                        this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                    }
                } else {
                    this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                }
            });

    }

    updateSoft(){
        api.items.getCount(this.props.locationId, this.state.filter)
            .then(count => {
                this.setState({pageCurrent: 1, dataCount: count});
                this.updateItems(this.state.column, this.state.direction, 1, 0, this.state.pageSize, this.state.filter);

            })
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data.errors, loading: false});
                    } else {
                        this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                    }
                } else {
                    this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                }
            });
    }

    updateItems(column, direction, page, from, pagesize, filter) {
        let t = setTimeout(()=>this.setState({loading: true}), 200);
        api.items.get(this.props.locationId, from, pagesize,
            column, direction, filter)
            .then(items => {
                clearTimeout(t);
                this.setState({
                    column: column,
                    direction: direction,
                    loading: false,
                    data: items,
                    pageCurrent: page,
                    from: from,
                    rowsPerPage: pagesize,
                    multiEdit: false
                })
            })
    }



    onPageChanged(page, from)
    {
        this.updateItems(this.state.column, this.state.direction, page, from, this.state.pageSize, this.state.filter);
    }

    onPageSizeChanged(e, { name, value })
    {
        console.log("Working")
        this.updateItems(this.state.column, this.state.direction, 1, 0, value, this.state.filter)
        this.setState({
            pageSize: value,

        })
    }

    onFilterChanged(e){
        if(this.timerId!==undefined)
            clearTimeout(this.timerId);
        this.setState({filter:e.target.value});
        let it = this;
        this.timerId = setTimeout(function (){
                it.updateSoft();
                it.timerId = undefined;
            },300)
    }

    clearFilter() {
        if(this.timerId!==undefined)
            clearTimeout(this.timerId);
        this.setState({filter:""});
        this.updateSoft();
    }

    submitMultipleEdit(data){
        api.items.applyItemMultiChange(this.state.selectedItems, data.state, data.location, data.mol, data.holder).then(() => {
            this.setState({openMultiChanges:false, selectedItems:[], multiEdit: false});
            this.updateItems(this.state.column, this.state.direction, this.state.pageCurrent, this.state.from, this.state.pageSize, this.state.filter);
        })
    }

    closeMultiChanges(){
        this.setState({openMultiChanges:false});
    }

    handleSort = clickedColumn => () => {
        const {column, direction} = this.state;

        if (column !== clickedColumn) {

            this.updateItems(clickedColumn, "ascending", this.state.pageCurrent, this.state.from, this.state.pageSize, this.state.filter);
            return
        }

        this.updateItems(column, direction === 'ascending' ? 'descending' : 'ascending', this.state.pageCurrent, this.state.from, this.state.pageSize, this.state.filter);
    };

    closeConfigShow = (name, readOnly, isShow, id) => () => {
        this.setState({
            id: id,
            open: true,
            caption: name,
            isShow: isShow,
            readOnly: readOnly
        })
    };

    showItemChange = (id) => () => {
        this.setState({
            id: id,
            openItemChanges: true
        })
    };
    showMultiItemChange = () => {
        this.setState({
            openMultiChanges: true
        })
    };

    startMultiEdit = () => {
        this.setState({
            selectedItems:[],
            multiEdit: true
        })
    };
    stopMultiEdit = () => {
        this.setState({
            selectedItems:[],
            multiEdit : false
        })
    };

    showTags = (id, name) => () => {
        this.setState({
            id: id,
            name: name,
            openTags: true
        })
    };

    componentWillUpdate(nextProps, nextState) {
        if (nextProps.locationId !== this.props.locationId) {
            this.props = nextProps;
            this.update();
        }
    }

    submitClose(data) {
        this.close();
    }
    selectMulti(id) {
        if(this.state.selectedItems.every(v=> v!==id ))
            this.setState({selectedItems: [...this.state.selectedItems, id]});
        else
            this.setState({selectedItems: this.state.selectedItems.filter((v)=>v!==id)});
    }
    selectAll() {
        this.setState({selectedItems: this.state.data.map(i => i.id)})
    }

    submit(data) {
        const {readOnly, pageCurrent} = this.state;
        console.log("submit with", pageCurrent);
        if (readOnly) {
            return api.items.deleteItem(data.id)
                .then(() => {
                    this.close();
                    this.updateItems(this.state.column, this.state.direction, this.state.pageCurrent, this.state.from, this.state.pageSize, this.state.filter);
                })
        } else {
            return api.items.saveItem(data).then(() => {
                this.close();
                this.updateItems(this.state.column, this.state.direction, this.state.pageCurrent, this.state.from, this.state.pageSize, this.state.filter);
            })

        }
    };

    close = () => this.setState({open: false});
    closeItemChanges = () => this.setState({openItemChanges: false});
    closeItemTags = () => {
        this.updateItems(this.state.column, this.state.direction, this.state.pageCurrent, this.state.from, this.state.pageSize, this.state.filter);
        this.update();
        this.setState({openTags: false});

    };

    handleContextRef = contextRef => this.setState({contextRef});

    onExport(){
        let t=setTimeout(()=>this.setState({loading:true}),200);
        api.items.exportItems(this.props.locationId, this.state.filter)
            .then(res=>{
                const url=window.URL.createObjectURL(new Blob([res.data]));
                const link=document.createElement('a');
                link.href=url;
                link.setAttribute('download','export_'+this.props.locationId+'.xlsx');
                document.body.appendChild(link);
                link.click();
                clearTimeout(t);
                this.setState({loading:false})
            });
    }
    getPhoto(infoId) {
                    api.items.downloadItemPhoto(infoId,(url)=>{
                        this.setState({
                            imgLoading: false,
                            bloburl: url,
                        });
                    })
            };

    clearPhoto() {
           this.setState({
                imgLoading: true,
                bloburl: null,
            });
        };


    shortFio(user) {
        if (!user) {
            return "";
        }
        let result = "";
        if (user.lastName) {
            result += user.lastName;
        }
        if (user.firstName && user.firstName.length > 0) {
            if (result.length > 0)
                result += " ";
            result += user.firstName[0] + "."
        }
        if (user.surName && user.surName.length > 0) {
            if (result.length > 0)
                result += " ";
            result += user.surName[0] + "."
        }
        return result;
    }

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: '10px',
            marginRight: '10px',

        }
    };

    render() {
        const {
            loading, data, column, direction, open, caption, id, readOnly, isShow, openItemChanges, openTags,
            pageCurrent, dataCount, pageSize, multiEdit,openMultiChanges, selectedItems, imgLoading, infoId, bloburl
        } = this.state;
        const selDict = selectedItems.reduce((map,v)=>{map[v]=v;return map;},{});
        const pagesizes = [
            {
                text: api.paging.longTableRowsCount,
                value: api.paging.longTableRowsCount,
            },
            {
                text: 20,
                value:20,
            },
            {
                text: 50,
                value:50,
            },
            {
                text: 100,
                value:100,
            },
        ];
        return (
            <div ref={this.handleContextRef}>
                <Container >
                    {loading &&
                    <Message icon><Icon name="circle notched"
                                        loading/><Message.Header>Загрузка</Message.Header></Message>}

                    {!loading && <div>
                        <Button
                            onClick={this.onExport}
                            floated='right'
                            icon
                            labelPosition='left'
                            positive
                            disabled={data.length<=0}
                            size='small'>
                            <Icon name='download'/>Экспорт
                        </Button>
                        <Input
                            type="text"
                            id="filter"
                            name="filter"
                            placeholder="Поиск единиц"
                            fluid
                            icon='search'
                            iconPosition='left'
                            value={this.state.filter}
                            onChange={this.onFilterChanged}
                            action={<Button icon= 'delete' onClick={ this.clearFilter }/>}
                        />
                        <Table sortable celled selectable compact>
                            <Table.Header>
                                <Table.Row>
                                    <Pagger colSpan={'10'}
                                            currentItems={data.length}
                                            rowsPerPage={pageSize}
                                            totalItems={dataCount}
                                            pageCurrent={pageCurrent}
                                            onPageChanged={this.onPageChanged}
                                    />

                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell sorted={column === 'HasTags' ? direction : null }
                                                      onClick={this.handleSort('HasTags')}>
                                        <Icon name={'wifi'}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'HasImage' ? direction : null}
                                                      onClick={this.handleSort('HasImage')}>
                                        <Icon name={'file image'}/>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'InventoryNumber' ? direction : null}
                                                      onClick={this.handleSort('InventoryNumber')}>
                                        Инв. №
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'Name' ? direction : null}
                                                      onClick={this.handleSort('Name')}>
                                        Название
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'State' ? direction : null}
                                                      onClick={this.handleSort('State')}>
                                        Статус
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'Price' ? direction : null}
                                                      onClick={this.handleSort('Price')}>
                                        Цена
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'Location' ? direction : null}
                                                      onClick={this.handleSort('Location')}>
                                        Мест
                                    </Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'Mol' ? direction : null}
                                                      onClick={this.handleSort('Mol')}>
                                        МОЛ
                                    </Table.HeaderCell>
                                    <Table.HeaderCell textAlign='right' colSpan={'2'}>
                                        {!multiEdit && this.props.permission.allowCreateItem && (
                                            <Button
                                                onClick={this.closeConfigShow("Создание", false, false, '')}
                                                floated='right'
                                                icon
                                                labelPosition='left'
                                                positive
                                                size='small'>
                                                <Icon name='plus'/> Добавить
                                            </Button>
                                        )}
                                        {multiEdit && <Button
                                            onClick={this.showMultiItemChange}
                                            floated='right'
                                            icon
                                            labelPosition='left'
                                            positive
                                            disabled={selectedItems.length<=0}
                                            size='small'>
                                            <Icon name='edit'/> Изменить
                                        </Button>
                                        }

                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {_.map(data, ({id, isMarked, inventoryNumber, price, name, location, holder, mol, state, hasImage, infoId}) => {
                                    let stateText = '';
                                    switch (state) {
                                        case 0:
                                            stateText = 'Получено';
                                            break;
                                        case 1:
                                            stateText = 'Оприходовано';
                                            break;
                                        case 2:
                                            stateText = 'Списано';
                                            break;
                                        case 3:
                                            stateText = 'Утрачено';
                                            break;
                                        default:
                                            break;
                                    }
                                    return (
                                        <Table.Row key={id} positive={(multiEdit && id in selDict)}>
                                            <Table.Cell>{isMarked ? <Icon name={'checkmark'}/> :
                                                <Icon name={'cancel'}/>}
                                            </Table.Cell>
                                            <Table.Cell>{hasImage ? <Popup
                                                trigger={
                                                    <Icon name={'file image'}/>
                                                }
                                                content={ (
                                                        <Segment>
                                                            <Dimmer active={imgLoading}>
                                                                <Loader size='large'>Loading</Loader>
                                                            </Dimmer>
                                                            <Image id='itemPhoto' centered src={imgLoading?"/noimage.png":this.state.bloburl}/>
                                                        </Segment>)}
                                                on='hover'
                                                onOpen={this.getPhoto.bind(this, infoId)}
                                                onClose={this.clearPhoto.bind(this)}
                                                position='top right'
                                            />:<div></div>}

                                            </Table.Cell>
                                            <Table.Cell>{inventoryNumber}</Table.Cell>
                                            <Table.Cell>{name}</Table.Cell>
                                            <Table.Cell>{stateText}</Table.Cell>
                                            <Table.Cell>{parseInt(price, 10)}</Table.Cell>
                                            <Table.Cell>{location.name}</Table.Cell>
                                            <Table.Cell>{this.shortFio(mol)}</Table.Cell>
                                            <Table.Cell>  <Button.Group>

                                                <Popup
                                                    content={'Информация'}
                                                    trigger={<Button icon={"info"} color='yellow' size='mini' onClick={this.closeConfigShow("Просмотр", true, true, id)}/>}
                                                />

                                                {this.props.permission.allowEditItem && (
                                                    <Popup
                                                        content={'Редактирование'}
                                                        trigger={<Button icon={"edit"} color='blue' size='mini' onClick={this.closeConfigShow("Редактирование", false, false, id)}/>}
                                                    />
                                                )}
                                                <Popup
                                                    content={'RFID Метки'}
                                                    trigger={<Button icon={"wifi"} color='purple' size='mini'  onClick={this.showTags(id, name)}/>}
                                                />
                                            </Button.Group>
                                            </Table.Cell>
                                            <Table.Cell singleLine textAlign='left'>
                                                {!multiEdit && (
                                                    <div>
                                                        <Dropdown text='Еще...' icon={null}>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item
                                                                    icon={"history"}
                                                                    text='История изменений'
                                                                    onClick={this.showItemChange(id)}
                                                                />

                                                                <Dropdown.Divider/>
                                                                {this.props.permission.allowEditItem && (
                                                                    <Dropdown.Item
                                                                        icon={"delete"}
                                                                        text='Удаление'
                                                                        onClick={this.closeConfigShow("Удаление", true, false, id)}
                                                                    />
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <ReactTooltip effect='solid'/>
                                                    </div>
                                                )}
                                                {multiEdit && (<Checkbox checked={id in selDict} onClick={()=>this.selectMulti(id)} />)}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>

                            <Table.Footer>
                                <Table.HeaderCell colSpan={4}>
                                    На странице <Select upward compact onChange={this.onPageSizeChanged} value={pageSize}  options={pagesizes}/>шт
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={5} floated='right'>
                                    {this.props.permission.allowEditItem && !multiEdit && (
                                        <Button
                                            onClick={this.startMultiEdit}
                                            floated='right'
                                            icon
                                            labelPosition='left'
                                            positive
                                            size='small'>
                                            <Icon name='edit'/> Изменить несколько
                                        </Button>)}
                                    {multiEdit && (
                                        <Button.Group floated='right'>
                                        <Button
                                            onClick={this.stopMultiEdit}
                                            floated='right'
                                            icon
                                            labelPosition='left'
                                            negative
                                            size='small'>
                                            <Icon name='cancel'/> Отменить выбор
                                        </Button>
                                        <Button
                                            disabled={this.state.allPossibleSelected === this.state.selectedItems}
                                            onClick={()=>this.selectAll()}
                                            floated='right'
                                            icon
                                            labelPosition='left'
                                            positive
                                            size='small'>
                                            <Icon name='edit'/> Выбрать все
                                        </Button>
                                        {multiEdit && <Button
                                            onClick={this.showMultiItemChange}
                                            floated='right'
                                            icon
                                            labelPosition='left'
                                            positive
                                            disabled={selectedItems.length<=0}
                                            size='small'>
                                            <Icon name='edit'/> Изменить
                                        </Button>}
                                        </Button.Group>)}
                                </Table.HeaderCell>
                            </Table.Footer>
                        </Table>


                    </div>}
                </Container>

                <Modal
                    closeIcon
                    /* className="middle aligned center aligned" */
                    open={open}
                    closeOnDimmerClick={false}
                    dimmer="blurring"
                    style={this.inlineStyle.modal}
                    onClose={this.close}>
                    <Modal.Header>
                        {caption}
                    </Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        <ItemForm id={id} readOnly={this.props.permission.allowEditItem && readOnly}
                                  submit={this.submit} isShow={isShow} infoId={''}/>
                    </Modal.Content>
                </Modal>

                <Modal
                    closeIcon
                    /* className="middle aligned center aligned" */
                    open={openItemChanges}
                    closeOnDimmerClick={false}
                    dimmer="blurring"
                    style={this.inlineStyle.modal}
                    onClose={this.closeItemChanges}>
                    <Modal.Header>
                        История изменений
                    </Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        <ItemChangesForm id={id}
                                         readOnly={this.props.permission.allowEditItem}
                                         reload={() => this.updateItems(this.state.column, this.state.direction, this.state.pageCurrent, this.state.from, this.state.pageSize)}/>
                    </Modal.Content>
                </Modal>
                {openMultiChanges && (<ItemMultiChangesForm cnt={selectedItems.length} close={this.closeMultiChanges} submit={this.submitMultipleEdit}/>)}


                <Modal
                    closeIcon
                    /* className="middle aligned center aligned" */
                    open={openTags}
                    dimmer="blurring"
                    style={this.inlineStyle.modal}
                    onClose={this.closeItemTags}>
                    <Modal.Header>
                        RFID метки для - {this.state.name}
                    </Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        <ItemTagsForm id={id} readOnly={this.props.permission.allowProgrammingTags}/>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

ItemsView.propTypes = {
    locationId: PropTypes.string.isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(ItemsView);