import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Form, Message, Segment} from "semantic-ui-react";
import InlineError from "../../messages/InlineError";
import TextStore from "../../../stores/TextStore";
import {Redirect} from "react-router";

class ApplicationUserForm extends Component {
    state = {
        data: {},
        loading: false,
        errors: {},
        success: false,
        readOnly: false,
        positions: {},
        departments: {}
    };

    constructor(state) {
        super(state);
        this.state.data = state.applicationUser;
        this.state.data.password = '';
        this.state.readOnly = state.readOnly;
        this.state.positions = state.positions.map(x => ({key: x.id, text: x.name, value: x.id}));
        this.state.departments = state.departments.map(x => ({key: x.id, text: x.name, value: x.id}));
    }

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onChangeDepartment = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, department: data.value}
            });
        }
    };
    onChangePosition = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, position: data.value}
            });
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data.errors, loading: false});
                        } else {
                            this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                        }
                    } else {
                        this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                    }
                });
        }
    };

    validate = data => {
        const errors = {};
        if (!data.email && !data.userName) {
            errors.email = 'Почта или имя пользователя не должны быть пустыми';
            errors.userName = 'Почта или имя пользователя не должны быть пустыми';
        }
        if (!data.firstName && !data.lastName && !data.surName) {
            errors.firstName = 'Фамилия, имя или отчество не должны быть пустыми';
            errors.lastName = 'Фамилия, имя или отчество не должны быть пустыми';
            errors.surName = 'Фамилия, имя или отчество не должны быть пустыми';
        }
        return errors;
    };

    render() {
        const {data, loading, errors, success, readOnly, departments, positions} = this.state;
        return (
            <Form
                loading={loading}
                onSubmit={this.onSubmit}
                className="large">
                <Segment stacked>

                    <Form.Field error={!!errors.email}  style={{position: "relative"}}  >
                        <label htmlFor="email">Email</label>
                        <input

                            style={{position: "relative", border: "1px solid rgba(34,36,38,.15)"}}

                            readOnly={readOnly}
                            autoComplete='off'
                            type="email"
                            id="email"
                            name="email"
                            placeholder="example@example.com"
                            value={data.email}
                            onChange={this.onChange}
                        />
                        {errors.email && <InlineError text={errors.email}/>}
                    </Form.Field>

                    <Form.Field error={!!errors.userName}>
                        <label htmlFor="userName">Имя пользователя</label>
                        <input
                            readOnly={readOnly}
                            autoComplete='off'
                            type="text"
                            id="userName"
                            name="userName"
                            placeholder="Сотрудник-007"
                            value={data.userName}
                            onChange={this.onChange}
                        />
                        {errors.userName && <InlineError text={errors.userName}/>}
                    </Form.Field>

                    <Form.Field error={!!errors.password}>
                        <label htmlFor="password">Пароль</label>
                        <input
                            readOnly={readOnly}
                            autoComplete='off'
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Пароль"
                            value={data.password}
                            onChange={this.onChange}
                        />
                        {errors.password && <InlineError text={errors.password}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.lastName}>
                        <label htmlFor="lastName">Фамилия</label>
                        <input
                            readOnly={readOnly}
                            autoComplete='off'
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Иванов"
                            value={data.lastName}
                            onChange={this.onChange}
                        />
                        {errors.lastName && <InlineError text={errors.lastName}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.firstName}>
                        <label htmlFor="firstName">Имя</label>
                        <input
                            readOnly={readOnly}
                            autoComplete='off'
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Иван"
                            value={data.firstName}
                            onChange={this.onChange}
                        />
                        {errors.firstName && <InlineError text={errors.firstName}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.surName}>
                        <label htmlFor="surName">Отчество</label>
                        <input
                            readOnly={readOnly}
                            autoComplete='off'
                            type="text"
                            id="surName"
                            name="surName"
                            placeholder="Иванович"
                            value={data.surName}
                            onChange={this.onChange}
                        />
                        {errors.surName && <InlineError text={errors.surName}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.department}>
                        <label htmlFor="department">Подразделение</label>
                        <Dropdown
                            readOnly={readOnly}
                            options={departments}
                            button
                            basic
                            fluid
                            floating
                            id="department"
                            name="department"
                            value={data.department}
                            onChange={this.onChangeDepartment}/>
                        {errors.department && <InlineError text={errors.department}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.position}>
                        <label htmlFor="department">Должность</label>
                        <Dropdown
                            readOnly={readOnly}
                            options={positions}
                            button
                            basic
                            fluid
                            floating
                            id="position"
                            name="position"
                            value={data.position}
                            onChange={this.onChangePosition}/>
                        {errors.position && <InlineError text={errors.position}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.comment}>
                        <label htmlFor="comment">Комментарий</label>
                        <input
                            readOnly={readOnly}
                            autoComplete='off'
                            type="text"
                            id="comment"
                            name="comment"
                            placeholder="Хороший сотрудник"
                            value={data.comment}
                            onChange={this.onChange}
                        />
                        {errors.comment && <InlineError text={errors.comment}/>}
                    </Form.Field>
                    <Button fluid primary>{readOnly ? "Подтвердить" : "Сохранить"}</Button>
                </Segment>
                {
                    errors.global &&
                    <Message negative>
                        <Message.Header>Ошибка</Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                }
                {success && <Redirect to="/applicationUsers"/>}
            </Form>
        );
    }
}


ApplicationUserForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default ApplicationUserForm;