import React, {Component} from 'react';
import {Button, Container, Dropdown, Icon, Message, Modal, Pagination, Table} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";

class Pagger extends Component {
    constructor(state) {
        super(state);
        this.handlePaginationChange = this.handlePaginationChange.bind(this)
    }

    componentDidMount() {
        this.setState({});
    }

    handlePaginationChange = (e, {activePage}) => {
        let from = (activePage-1) * this.props.rowsPerPage;
        this.props.onPageChanged(activePage, from, this.props.rowsPerPage)
    };

    calcPostfix(){
        let lastsym = (this.props.currentItems%10);
        let presym = ((this.props.currentItems%100)-lastsym)/10;
        //5,6,7,8,9,0
        if(lastsym==0||(lastsym>=5 && lastsym<=9))
            return 'ов';
        //11,12,13,14,15
        if(presym==1)
            return 'ов'
        //1
        if(lastsym==1)
            return '';
        else //2,3,4
            return 'а';
    }

    calcRange(){
        let px = (this.props.pageCurrent -1 ) * this.props.rowsPerPage;
        return "("+(px + 1).toString()+" - "+(px + this.props.currentItems).toString()+") из "+this.props.totalItems.toString();
    }

    render() {
        let pageCount = Math.ceil(this.props.totalItems/this.props.rowsPerPage);
        return (
            <Table.HeaderCell colSpan={this.props.colSpan}>
                    <Pagination
                        stackable
                        firstItem={{content: <Icon name='angle double left'/>, icon: true}}
                        lastItem={{content: <Icon name='angle double right'/>, icon: true}}
                        prevItem={{content: <Icon name='angle left'/>, icon: true}}
                        nextItem={{content: <Icon name='angle right'/>, icon: true}}
                        activePage={this.props.pageCurrent}
                        onPageChange={this.handlePaginationChange}
                        totalPages={pageCount}
                        secondary
                        disabled={pageCount<=1}
                    />
                {(this.props.totalItems<=this.props.currentItems)?
                    <b>Показано всё ({this.props.currentItems} элемент{this.calcPostfix()})</b>
                    :<b>Показано {this.props.currentItems} {this.calcRange()}</b>}
            </Table.HeaderCell>
        )
    }

}

Pagger.propTypes = {
    rowsPerPage: PropTypes.number.isRequired,
    currentItems: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    pageCurrent: PropTypes.number.isRequired,
    colSpan: PropTypes.string.isRequired,
    onPageChanged: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(Pagger);