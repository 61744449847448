import React from 'react';
import PropTypes from 'prop-types';
import {VelocityComponent} from 'velocity-react';
import {Icon} from "semantic-ui-react";

const Loading = ({style}) => {
    return <div style={style}>loading...</div>;
};
Loading.propTypes = {
    style: PropTypes.object
};

const Toggle = ({style, onClick}) => {
    const {height, width} = style;
    const midHeight = height * 0.5;
    const points = `0,0 0,${height} ${width},${midHeight}`;

    return (
        <div style={style.base} onClick={onClick}>
            <div style={style.wrapper}>
                <svg height={height} width={width}>
                    <polygon points={points}
                             style={style.arrow}/>
                </svg>
            </div>
        </div>
    );
};
Toggle.propTypes = {
    style: PropTypes.object
};

const Header = ({node, style}) => {
    return (
        <div style={style.base}>
            <div style={style.title}>
                {node.type === 0 && <Icon name="building outline"/>}
                {node.type === 1 && <Icon name="cubes"/>}
                {node.type === 2 && <Icon name="cube"/>}
                {node.name}
            </div>
        </div>
    );
};
Header.propTypes = {
    style: PropTypes.object,
    node: PropTypes.object.isRequired
};

class Container extends React.Component {
    merge_options(obj1, obj2) {
        let attrName;
        const obj3 = {};
        for (attrName in obj1) {
            obj3[attrName] = obj1[attrName];
        }
        for (attrName in obj2) {
            obj3[attrName] = obj2[attrName];
        }
        return obj3;
    }

    render() {
        const {style, decorators, terminal, onClick, onClickActive, node} = this.props;
        let st = this.merge_options(style.container[0], style.container[1]);
        return (
            <div onClick={onClickActive}
                 ref={ref => this.clickableRef = ref}
                 style={st}>
                {!terminal ? this.renderToggle(onClick) : null}

                <decorators.Header node={node}
                                   style={style.header}/>
            </div>
        );
    }

    renderToggle(onClick) {
        const {animations} = this.props;

        if (!animations) {
            return this.renderToggleDecorator(onClick);
        }

        return (
            <VelocityComponent animation={animations.toggle.animation}
                               duration={animations.toggle.duration}
                               ref={ref => this.velocityRef = ref}>
                {this.renderToggleDecorator(onClick)}
            </VelocityComponent>
        );
    }

    renderToggleDecorator(onClick) {
        const {style, decorators} = this.props;

        return <decorators.Toggle style={style.toggle} onClick={onClick}/>;
    }
}

Container.propTypes = {
    style: PropTypes.object.isRequired,
    decorators: PropTypes.object.isRequired,
    terminal: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickActive: PropTypes.func.isRequired,
    animations: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ]).isRequired,
    node: PropTypes.object.isRequired
};

export default {
    Loading,
    Toggle,
    Header,
    Container
};