export default class AuthStore {
    static STORAGE_KEY = "JWT";
    static prevRoute = "mtc";

    static getToken() {
        return window.localStorage.getItem(this.STORAGE_KEY);
    }

    static setToken(token) {
        window.localStorage.setItem(this.STORAGE_KEY, token);
    }

    static removeToken() {
        window.localStorage.removeItem(this.STORAGE_KEY);
    }

    static setRoute(route) {
        this.prevRoute = route;
    }

    static getRoute() {
        return this.prevRoute;
    }
}
