import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "../../../api";
import _ from "lodash";
import {Button, Divider, Header, Icon, Message, Segment, Table, Popup, Select, Grid, GridRow} from "semantic-ui-react";

import {connect} from "react-redux";
import Pagger from "../../controls/Pagger";

class ImportPage extends Component {
    state = {
        loading: true,
        importstate: {},
        column: '',
        direction: '',
        isReadOnly: false,
        caption: '',
        pageCurrent: 0,
        from: 1,
        importCount:0,
        rowsPerPage: api.paging.longTableRowsCount,
    };


    constructor(state) {
        super(state);

        this.update = this.update.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.updateImport = this.updateImport.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    update() {
        this.setState({
            loading: true
        });
        api.imports.getFastImportRecCount(this.props.match.params.id)
            .then(count => {
                let res = api.paging.calcLongTablePageCount(count);
                this.setState({pageCount: res, importCount: count, pageCurrent:1,});
                this.updateImport(1, 0, this.state.rowsPerPage);
            })
    }
    updateImport(pageCurrent, from, value) {
        this.setState({
            loading: true
        });
        api.imports.getSingleFast(this.props.match.params.id, from, value)
            .then(importstate => this.setState({
                importstate: importstate,
                loading: false,
                pageCurrent: pageCurrent,
                rowsPerPage: value,
                from: this.state.from
            }))
    }

    onPageSizeChanged(e, { name, value })
    {
        this.updateImport(this.state.pageCurrent, this.state.from, value)
    }

    open = () => {
        this.setState({open: true});
    };

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };
    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {loading, importstate, pageCurrent, importCount, rowsPerPage} = this.state;



        const pagesizes = [
            {
                text: api.paging.longTableRowsCount,
                value: api.paging.longTableRowsCount,
            },
            {
                text: 20,
                value:20,
            },
            {
                text: 50,
                value:50,
            },
            {
                text: 100,
                value:100,

            },
        ];
        return (
            <Grid floated='center'>
                <GridRow>
                    <Grid.Column mobile={2} tablet={2} computer={2} />
                    <Grid.Column  mobile={12} tablet={12} computer={12}>
                        {loading &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}

                        {!loading &&

                        <Table selectable fluid>
                            <Table.Header>
                                <Table.Row>
                                    <Pagger colSpan={'6'}
                                            onPageChanged={this.updateImport}
                                            pageCurrent={pageCurrent}
                                            totalItems={importCount}
                                            rowsPerPage={this.state.rowsPerPage}
                                            currentItems={importCount}
                                    />
                                    <Table.HeaderCell colSpan={4}>
                                        На странице <Select downward compact onChange={this.onPageSizeChanged} value={rowsPerPage}  options={pagesizes}/>шт
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        №
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Местоположение
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                            в БД
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Наименование
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Инвентарный №
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Цена
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                     ТМЦ есть в БД
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Индикаторы
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Действия
                                    </Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {_.map(importstate, ({importRecordId, importGuid, recordLineNumber, importLocationStr, importLocationValid, importTmcName, importTmcNameValid,
                                                         importInventoryNumber, importInventoryNumberValid, importPrice, importPriceValid, importRecordIsPresent,
                                                         importRecordIsPresentAtLoc}) => {

                                    return (
                                        <Table.Row key={importRecordId} warning ={(!importLocationValid || !importTmcNameValid || !importInventoryNumberValid || !importPriceValid)} >
                                            <Table.Cell>{recordLineNumber}</Table.Cell>
                                            <Table.Cell>{importLocationStr}</Table.Cell>
                                            <Table.Cell>{importLocationValid ? <Icon name={'checkmark'} color='green'/> :
                                                <Icon name={'cancel'} color='red' />}</Table.Cell>
                                            <Table.Cell>{importTmcName}</Table.Cell>

                                            <Table.Cell>{importInventoryNumber}</Table.Cell>

                                            <Table.Cell>{importPrice}</Table.Cell>

                                            <Table.Cell>{importRecordIsPresent ? <Icon name={'checkmark'} color='green' size='large'/> :
                                                <Icon name={'cancel'} color='red' size='large' />} </Table.Cell>
                                            <Table.Cell>  <Button.Group>
                                                {importLocationValid ?
                                                <Popup
                                                    content={'Помещение есть в БД'}
                                                    trigger={<Button icon={"building"} color='green' size='mini' />}
                                                /> :
                                                <Popup
                                                    content={'Помещения нет в БД'}
                                                    trigger={<Button icon={"building"} color='red' size='mini' />}
                                                />
                                                }
                                                {(importRecordIsPresent) ?
                                                    <Popup
                                                        content={'Данное ТМЦ есть в БД'}
                                                        trigger={<Button icon={"box"} color='green' size='mini' />}
                                                    /> :
                                                    <Popup
                                                        content={'Данного ТМЦ нет в БД'}
                                                        trigger={<Button icon={"box"} color='red' size='mini' />}
                                                    />
                                                }

                                            </Button.Group>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!importLocationValid ?
                                                    <Popup
                                                        content={'Помещения нет в БД - Добавьте его'}
                                                        trigger={<Button icon={"building"} color='red' size='mini' />}
                                                    /> :
                                                        (!importRecordIsPresent) ?
                                                            <Popup
                                                                content={'Данного ТМЦ нет в БД - его нужно создать'}
                                                                trigger={<Button icon={"box"} color='yellow' size='mini' />}
                                                            /> :
                                                                (importRecordIsPresentAtLoc) ?
                                                                    <Popup
                                                                        content={'Данное ТМЦ есть в БД и в том же Помещении - Действия не нужны'}
                                                                        trigger={<Button icon={"check"} color='green' size='mini' />}
                                                                    /> :
                                                                    <Popup
                                                                        content={'Данного ТМЦ нет в БД в этом Помещении- Перемещение ТМЦ'}
                                                                        trigger={<Button icon={"box"} color='blue' size='mini' />}
                                                                    />
                                                                     }
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        }
                    </Grid.Column>
                </GridRow>
            </Grid>
        );
    }

}

ImportPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(ImportPage);