import React from 'react';
import {Button, Form, Message, Segment, Container,Divider, Image, Header} from "semantic-ui-react";
import PropTypes from 'prop-types';
import InlineError from '../messages/InlineError';
import {Link} from "react-router-dom";
import TextStore from "../../stores/TextStore";
import 'semantic-ui-less/semantic.less';
import "./loginform.css";
import AuthStore from "../../stores/AuthStore";

class LoginForm extends React.Component {
    state = {
        data: {
            email: '',
            password: ''
        },
        loading: false,
        errors: {}
    };

    onChange = e =>
        this.setState({
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onSubmit = () => {
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data, loading: false});
                        } else {
                            this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                        }
                    } else {
                        this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                    }
                });
        }
    };

    validate = (data) => {
        const errors = {};
        if (!data.email) errors.email = "Неверный email";
        if (!data.password) errors.password = "Неверный пароль";
        return errors;
    };

    render() {
        const {data, errors, loading} = this.state;
        let isAuthenticated = !!AuthStore.getToken();
        return (
            <Form id = "form"
                onSubmit={this.onSubmit}
                  loading={loading}
                  >


                    <Image className="image"
                           src='logopic.svg'
                        />
                    <Divider hidden
                             className="divider1"

                    />
                    <text
                        className="text1"
                    >
                        Ваш проводник в мире <br/> инвентаризаций
                    </text>

                    <Divider hidden className="divider2"/>

                    {!isAuthenticated && (
                        <Header className="formheader">

                            <Header.Content>
                                ВХОД В СИСТЕМУ
                            </Header.Content>
                        </Header>)}

                        <Divider hidden className="divider3"/>


                    <Form.Field error={!!errors.email} className="field" >

                        <input  className="input1"
                                type="text"
                                id="email"
                                name="email"
                                placeholder="Введите почту или имя пользователя"
                                value={data.email}
                                onChange={this.onChange}/>
                        {errors.email && <InlineError text={errors.email}/>}
                    </Form.Field>
                    <Form.Field error={!!errors.password}>

                        <input
                            className="input2"
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Введите пароль"
                            value={data.password}
                            onChange={this.onChange}/>
                        {errors.password && <InlineError text={errors.password}/>}
                    </Form.Field>


                    <Button className="button">Войти в систему</Button>


                    <Divider Hidden horizontal className="divider4"></Divider>

                    <Link to='/forgot_password' className="forgotpass">Забыли пароль?{errors.global}</Link>


                {
                    errors.global &&
                    <Message negative>
                        <Message.Header>Ошибка</Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                }
            </Form>
        );
    }
}

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default LoginForm;