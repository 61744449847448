import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "../../../api";
import {Button, Icon, Message, Modal, Table, Popup } from "semantic-ui-react";
import _ from "lodash";
import ItemForm from "./ItemForm";
//import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";

class ItemChangesForm extends Component {
    state = {
        loading: false,
        data: {},
        openItemChange: false,
        itemChangeId: '',
        caption: '',
        isShow: true
    };

    constructor(state) {
        super(state);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.updateItemChanges();
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
            return
        }
        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };


    showItemChange = (caption, itemChangeId, isShow) => () => {
        this.setState({
            itemChangeId: itemChangeId,
            openItemChange: true,
            caption: caption,
            isShow: isShow
        })
    };

    closeItemChange = () => this.setState({openItemChange: false});


    submit(data) {
        const {isShow, itemChangeId} = this.state;
        if (!isShow) {
            return api.items.applyItemChange(this.props.id, itemChangeId)
                .then(() => this.closeItemChange())
                .then(() => this.updateItemChanges())
                .then(() => this.props.reload())
        } else {
            this.closeItemChange();
        }
    }


    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'fixed !important'
        }
    };

    render() {
        const {loading, column, direction, data, openItemChange, itemChangeId, caption, isShow} = this.state;
        return (
            <div>
                {loading &&
                <Message icon><Icon name="circle notched"
                                    loading/><Message.Header>Загрузка</Message.Header></Message>}
                {!loading &&
                <Table sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={column === 'createdBy' ? direction : null}
                                              onClick={this.handleSort('createdBy')}>
                                Кто
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'createdTime' ? direction : null}
                                              onClick={this.handleSort('createdTime')}>
                                Когда
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'restoredFrom' ? direction : null}
                                              onClick={this.handleSort('restoredFrom')}>
                                Восстановлено из
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Действия
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(data, ({id, createdBy, createdTime, restoredFrom, location }) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{createdBy.lastName + " " + createdBy.firstName + " " + createdBy.surName}</Table.Cell>
                                    <Table.Cell>{api.formatDate(createdTime) + location.isDeleted.toString()}</Table.Cell>
                                    <Table.Cell>{restoredFrom ? restoredFrom.createdTime : ''}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <Button
                                            onClick={this.showItemChange('Архив изменений от ' + createdTime, id, true)}
                                            color="blue"
                                            size="mini"
                                            icon>
                                            <Icon name="info" />
                                        </Button>

                                        {this.props.readOnly && (location.isDeleted.toString() === "false" ) && (
                                            <Button
                                                onClick={this.showItemChange('Применить изменения от ' + createdTime, id, false)}
                                                size="mini"
                                                color="green"
                                                icon>
                                                <Icon name="checkmark"/>
                                            </Button>
                                        )}
                                        {this.props.readOnly && (location.isDeleted.toString() === "true" ) && (
                                            <Popup content='Нельзя восстановить - помещение удалено' trigger={

                                                <Button icon='add'

                                                size="mini"
                                                color="grey"
                                                        icon>
                                                <Icon name="checkmark"/>
                                            </Button>}  />

                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
                }

                <Modal
                    closeIcon
                    className="middle aligned center aligned"
                    open={openItemChange}
                    dimmer="blurring"
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                    style={this.inlineStyle.modal}
                    onClose={this.closeItemChange}>
                    <Modal.Header>
                        {caption}
                    </Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        <ItemForm id={this.props.id} readOnly={true} infoId={itemChangeId} isShow={isShow}
                                  submit={this.submit}/>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }

    updateItemChanges() {
        this.setState({loading: true});
        api.items.getItemChanges(this.props.id)
            .then(itemChanges => {
                this.setState({
                    loading: false,
                    data: itemChanges
                })
            })
    }
}


ItemChangesForm.propTypes = {
    id: PropTypes.string.isRequired,
    reload: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default ItemChangesForm;