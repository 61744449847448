import React, {Component} from 'react';
import {Button, Checkbox, Dropdown, Form, Icon, Message, Modal, Pagination, Table} from "semantic-ui-react";
import api from "../../../api";
import TextStore from "../../../stores/TextStore";

class LicenseForm extends Component  {


    state = {
        loading: true,
        clientHash:"", usedCount: 0, maxCount: 0, licenseText: undefined, licok: false,
        errors: {}};



    constructor(state){
        super(state);
        this.onChange = this.onChange.bind(this);
        this.onLicUpdate = this.onLicUpdate.bind(this);
        this.reload =this.reload.bind(this);
    }

    onChange = e =>
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        });
    onLicUpdate(){
        this.setState({
            ...this.state,
            loading:true
        })
        api.license.changeLicenseInfo(this.state.licenseText)
            .then(()=>{
                this.setState({...this.state, errors: {}, loading: false, licok: true});
                this.reload();
            })
            .catch((err)=>{
                if (!!err.response.data) {
                    this.setState({...this.state, errors: err.response.data.errors, loading: false, licok: false});
                }
                else{
                    this.setState({...this.state, errors: {global: TextStore.getMethodNotFoundError()}, loading: false, licok: false});
                }
            })
    }

    componentDidMount() {
        this.reload();
    }

    reload(){
        api.license.getLicenseInfo()
            .then(({data}) => {
                    this.setState({
                        ...this.state,
                        loading: false,
                        clientHash: data.clientHash,
                        usedCount: data.usedCount,
                        maxCount: data.maxCount,
                        licenseText: ""
                    })
                }
            )
            .catch((err) => {
                if (!!err.response.data) {
                    this.setState({...this.state, errors: err.response.data.errors, loading: false});
                } else {
                    this.setState({
                        ...this.state,
                        errors: {global: TextStore.getMethodNotFoundError()},
                        loading: false
                    });
                }
            });
    }

    render(){
        const {loading, errors, clientHash, usedCount, maxCount, licenseText, licok} = this.state;

        return (
            <Form
                loading={loading}
                className="large">
                <Message visible success>
                    <Message.Header>Ваш ключ клиента</Message.Header>
                    <small>{clientHash}</small>
                </Message>
                <Form.Field>
                    <label htmlFor="maxCount">Лимит единиц</label>
                    <input
                        readOnly
                        type="number"
                        id="maxCount"
                        name="maxCount"
                        placeholder="Разрешено единиц"
                        value={maxCount}
                    />
                </Form.Field>
                <Form.Field>
                    <label htmlFor="usedCount">Использовано единиц</label>
                    <input
                        readOnly
                        type="number"
                        id="usedCount"
                        name="usedCount"
                        placeholder="Использовано единиц"
                        value={usedCount}
                    />
                </Form.Field>
                {this.renderLimits(usedCount,maxCount)}
                <Form.Field>
                    <label htmlFor="licenseText">Новый ключ лицензии</label>
                    <textarea
                        type="number"
                        id="licenseText"
                        name="licenseText"
                        placeholder="Вставьте новый ключ"
                        rows="4"
                        onChange={this.onChange}
                        value={licenseText}
                    />
                </Form.Field>
                <Button disabled={licenseText===""} onClick={this.onLicUpdate}>Сохранить</Button>
                {errors.global &&
                    <Message visible negative>
                        <Message.Header>Ошибка</Message.Header>
                        <Message.Content>{errors.global}</Message.Content>
                    </Message>
                }
                {licok &&
                    <Message visible success>
                        <Message.Header>Лицензия успешно обновлена</Message.Header>
                        <Message.Content>Новые разрешения вступили в силу</Message.Content>
                    </Message>
                }
            </Form>
        );
    }

    renderLimits(usedCount,maxCount){
        if(maxCount<=usedCount)
            return <Message visible error header="Лимит исчерпан" content="Обратитесь к ответственному менеджеру для расширения лицензии" />
        let persUsed = (maxCount>0)?Math.floor((usedCount*100.0)/maxCount):100;
        if(persUsed>90)
            return <Message visible error header="Использовано более 90% лимита единиц." content="Рекомендуем обратиться к ответственному менеджеру для расширения лицензии" />
        return <Message visible success header={"Использовано ~"+persUsed+"% от числа разрешенных единиц"}/>
    }
}
export default LicenseForm;