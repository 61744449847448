import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "../../../api";
import TextStore from "../../../stores/TextStore";
import {Header, Icon, Message, Pagination, Table} from "semantic-ui-react";
import _ from "lodash";
import Select from "semantic-ui-react/dist/es/addons/Select/Select";

class UserTasksView extends Component {
    state = {
        tasks: {},
        loading: false,
        errors: {},
        column: '',
        direction: '',
        pageCount: 0,
        pageCurrent: 1,
        tasksCount: 0
    };

    componentDidMount() {
        this.setState({loading: true});
        this.update();
    }

    update() {
        this.setState({loading: true});
        api.profile.getUserTasksCount()
            .then(count => {
                let res = api.paging.calcLongTablePageCount(count);
                console.log("tasks count: ", count);
                console.log("page count: ", res);
                this.setState({pageCount: res, tasksCount: count});
                this.updateTasks(1);
            })
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data.errors, loading: false});
                    } else {
                        this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                    }
                } else {
                    this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                }
            });
    }

    updateTasks(page) {
        api.profile.getUserTasksFast((page - 1) * api.paging.longTableRowsCount, api.paging.longTableRowsCount)
            .then(tasks => this.setState({tasks: tasks, loading: false}))
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data.errors, loading: false});
                    } else {
                        this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                    }
                } else {
                    this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                }
            });
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    handlePaginationChange = (e, {activePage}) => {
        this.setState({pageCurrent: activePage});
        this.updateTasks(activePage);
    };


    rowClick = (id) => () => {
        this.props.history.push('/inventory/' + id);
    };

    render() {
        const {tasks, loading, errors, column, direction, pageCurrent, pageCount, tasksCount, pageSize} = this.state;
        const pagesizes = [
            {
                text: api.paging.longTableRowsCount,
                value: api.paging.longTableRowsCount,
            },
            {
                text: 20,
                value:20,
            },
            {
                text: 50,
                value:50,
            },
            {
                text: 100,
                value:100,
            },
        ];
        return (
            <div>
                <Header as="h3">Ваши задачи инвентаризации:</Header>
                {loading &&
                <Message icon><Icon name="circle notched"
                                    loading/><Message.Header>Загрузка</Message.Header></Message>}

                {!loading && <div>
                    <Table selectable>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={'5'}>
                                    <Pagination
                                        stackable
                                        firstItem={{content: <Icon name='angle double left'/>, icon: true}}
                                        lastItem={{content: <Icon name='angle double right'/>, icon: true}}
                                        prevItem={{content: <Icon name='angle left'/>, icon: true}}
                                        nextItem={{content: <Icon name='angle right'/>, icon: true}}
                                        activePage={pageCurrent}
                                        onPageChange={this.handlePaginationChange}
                                        totalPages={pageCount}
                                        disabled={pageCount<=1}
                                        pointing
                                        secondary
                                    />
                                    Показано <b>{tasks.length}</b> ({(pageCurrent -1 ) *
                                api.paging.longTableRowsCount + 1} - {(pageCurrent - 1) *
                                api.paging.longTableRowsCount + tasks.length}) из <b>{tasksCount}</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={4}>
                                    На странице <Select downward compact onChange={this.onPageSizeChanged} value={pageSize}  options={pagesizes}/>шт
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell>
                                    Инвентаризация
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'number' ? direction : null}
                                                  onClick={this.handleSort('number')}>
                                    № задачи
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'state' ? direction : null}
                                                  onClick={this.handleSort('state')}>
                                    Состояние
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'text' ? direction : null}
                                                  onClick={this.handleSort('text')}>
                                    Инв. объекты
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'responsibleUser' ? direction : null}
                                                  onClick={this.handleSort('responsibleUser')}>
                                    Ответственный
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Процесс поиска
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {_.map(tasks, ({id, inventoryHeadId, invNo, taskNumber, taskState, taskText, responsibleUserFio, itemInfoFound, itemInfoAll}) => {
                                return (
                                    <Table.Row key={id} onClick={this.rowClick(inventoryHeadId)}>
                                        <Table.Cell>Инвентаризация №{invNo}</Table.Cell>
                                        <Table.Cell>{taskNumber}</Table.Cell>
                                        <Table.Cell>{api.inventories.parseTaskState(taskState)}</Table.Cell>
                                        <Table.Cell>{taskText}</Table.Cell>
                                        <Table.Cell>{responsibleUserFio}</Table.Cell>
                                        <Table.Cell>{itemInfoFound.toString() + " / " + itemInfoAll.toString()}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                        <Table.Footer>

                        </Table.Footer>
                    </Table>

                </div>}

                {errors.global && <Message negative>
                    <Message.Header>Ошибка</Message.Header>
                    <p>{errors.global}</p>
                </Message>}
            </div>
        );
    }
}


UserTasksView.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

export default UserTasksView;