import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from "../../actions/auth";
import {Button, Container, Grid, GridRow, Sidebar} from "semantic-ui-react";
import LocationItemsTree from '../controls/LocationItemsTree';
import ItemsView from "../controls/ItemsView";

class MtcPage extends React.Component {
    state = {
        visible: false,
        selectedLocation: {
            id: ''
        }
    };

    constructor(state) {
        super(state);
        this.onSelectedLocationChanged = this.onSelectedLocationChanged.bind(this);
    }

    toggleVisibility = () => this.setState({visible: !this.state.visible});


    onSelectedLocationChanged(node) {
        this.setState({
            selectedLocation: node
        })
    }

    render() {
        const {visible, selectedLocation} = this.state;
        console.log('render with', selectedLocation);
        return (
            <div style={{height: 'calc(100% - 57px)'}}>
                <Grid>
                    <GridRow>
                        <Grid.Column mobile={4} tablet={4} computer={4}>
                            <LocationItemsTree visible={visible} selectedLocationChanged={this.onSelectedLocationChanged}/>
                        </Grid.Column>
                        <Grid.Column mobile={11} tablet={11} computer={11}>
                            <Container>
                                <ItemsView locationId={selectedLocation.id}/>
                            </Container>
                        </Grid.Column>
                    </GridRow>
                    <GridRow>

                    </GridRow>
                </Grid>
            </div>
        );
    }
}

MtcPage.propTypes = {
    isConfirmed: PropTypes.bool.isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        isConfirmed: !!state.user.confirmed,
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {logout: actions.logout})(MtcPage);