import React from 'react';
import {connect} from 'react-redux';
import {login} from '../../actions/auth';
import LoginForm from "../forms/LoginForm";
import {Grid, Header, Icon, Message} from "semantic-ui-react";
import PropTypes from 'prop-types';
import AuthStore from "../../stores/AuthStore";

class LoginPage extends React.Component {

    submit = (data) => this.props.login(data).then(() => {
        if (AuthStore.getRoute() === '--logout') {
            AuthStore.setRoute('mtc');
        }
        window.location.replace(AuthStore.getRoute());
    });

    render() {
        let isAuthenticated = !!AuthStore.getToken();
        return (

            <Grid className="middle aligned center aligned"
                  style={{height: '100%'}}>

                <div className="column"
                     style={{maxWidth: '450px'}}>
                    {!isAuthenticated && (
                        <Header className="image">
                            <Header.Content>
                                Авторизация
                            </Header.Content>
                        </Header>
                    )}
                    {!isAuthenticated && <LoginForm submit={this.submit}/>}
                    {isAuthenticated && (
                        <Message icon>
                            <Icon name="circle notched" loading/>
                            <Message.Header>Загрузка</Message.Header>
                        </Message>
                    )}
                </div>
            </Grid>
        );
    }

}

LoginPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    login: PropTypes.func.isRequired
};


export default connect(null, {login})(LoginPage);