import React from 'react';
import {connect} from 'react-redux';
import {Grid, Header, Icon, Message} from "semantic-ui-react";
import PropTypes from 'prop-types';
import api from "../../../api";
import TextStore from "../../../stores/TextStore";
import PositionForm from "../../forms/positions/PositionForm";
import PermissionDenied from "../../messages/PermissionDenied";

class PositionPage extends React.Component {

    state = {
        loading: true,
        position: {},
        success: true,
        errors: {}
    };

    componentDidMount() {
        if (this.props.match.params.type === 'edit' ||
            this.props.match.params.type === 'delete') {
            api.positions.getPosition(this.props.match.params.id)
                .then(res => {
                    this.setState({
                        loading: false,
                        success: true,
                        position: res.data.position
                    });
                })
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data.errors, loading: false, success: false});
                        } else {
                            this.setState({
                                errors: {global: TextStore.getUnknownNetworkError()},
                                loading: false,
                                success: false
                            });
                        }
                    } else {
                        this.setState({
                            errors: {global: TextStore.getMethodNotFoundError()},
                            loading: false,
                            success: false
                        });
                    }
                });
            this.setState({...this.state})
        } else if (this.props.match.params.type === 'create') {
            setTimeout(() => {
                this.setState({
                    loading: false,
                    success: true,
                    position: {
                        id: '',
                        name: ''
                    }
                });
            }, 0);
        }
    }

    submit = (data) => {
        if (this.props.match.params.type !== 'delete') {
            return api.positions.savePosition(data)
                .then(res => this.props.history.push('/positions'));
        } else {
            return api.positions.deletePosition(this.props.match.params.id)
                .then(res => this.props.history.push('/positions'));
        }
    };

    render() {
        const {loading, success, errors, position} = this.state;
        let mode = '';
        switch (this.props.match.params.type) {
            case "create":
                mode = 'Создание';
                break;
            case "delete":
                mode = 'Удаление';
                break;
            case "edit":
                mode = 'Редактирование';
                break;
            default:
                mode = "";
                break;
        }
        return (
            <Grid className="middle aligned center aligned"
                  style={{height: '100%'}}>
                <div className="column"
                     style={{maxWidth: '450px'}}>
                    {!this.props.permission.allowEditCatalogs &&
                    <PermissionDenied text={"редактированию справочников"}/>}


                    {this.props.permission.allowEditCatalogs && (
                        <div>
                            {!loading && success && (
                                <Header className="image">
                                    <Header.Content>
                                        {mode}
                                    </Header.Content>
                                </Header>
                            )}

                            {loading && (<Message icon>
                                <Icon name="circle notched" loading/>
                                <Message.Header>Загрузка</Message.Header>
                            </Message>)}
                            {
                                !loading && !success && (
                                    <Message negative>
                                        <Message.Header>Ошибка</Message.Header>
                                        <p>{errors.global}</p>
                                    </Message>
                                )}
                            {
                                !loading && success && (
                                    <PositionForm
                                        position={position}
                                        submit={this.submit}
                                        readOnly={this.props.match.params.type === 'delete'}/>
                                )
                            }
                        </div>
                    )}
                </div>
            </Grid>
        );
    }

}

PositionPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(PositionPage);