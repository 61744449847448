import React, {Component} from 'react';
import api from "../../../api";
import TextStore from "../../../stores/TextStore";
import {Icon, Message, Pagination, Table} from "semantic-ui-react";
import _ from "lodash";
import Select from "semantic-ui-react/dist/es/addons/Select/Select";

class UserInfoView extends Component {
    state = {
        items: {},
        loading: false,
        errors: {},
        column: '',
        direction: '',
        pageCount: 0,
        pageCurrent: 1,
        pageLoading: false,
        itemsCount: 0
    };

    componentDidMount() {
        this.setState({loading: true});
        this.update();
    }

    update() {
        api.profile.getUserItemsCount()
            .then(count => {
                let res = api.paging.calcLongTablePageCount(count);
                console.log("items count: ", count);
                console.log("page count: ", res);
                this.setState({pageCount: res, itemsCount: count});
                this.updateItems(1);
            })
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data.errors, loading: false});
                    } else {
                        this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                    }
                } else {
                    this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                }
            });
    }

    updateItems(page) {
        api.profile.getUserItemsFast((page - 1) * api.paging.longTableRowsCount, api.paging.longTableRowsCount)
            .then(items => this.setState({items: items, loading: false}))
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data.errors, loading: false});
                    } else {
                        this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                    }
                } else {
                    this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                }
            });
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    handlePaginationChange = (e, {activePage}) => {
        this.setState({pageCurrent: activePage});
        this.updateItems(activePage);
    };

    render() {
        const {items, loading, errors, column, direction, pageCurrent, pageCount, itemsCount,  pageSize} = this.state;
        const pagesizes = [
            {
                text: api.paging.longTableRowsCount,
                value: api.paging.longTableRowsCount,
            },
            {
                text: 20,
                value:20,
            },
            {
                text: 50,
                value:50,
            },
            {
                text: 100,
                value:100,
            },
        ];
        return (
            <div>
                {loading &&
                <Message icon><Icon name="circle notched"
                                    loading/><Message.Header>Загрузка</Message.Header></Message>}

                {!loading && !errors.global && <div>

                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan={'5'}>
                                    <Pagination
                                        stackable
                                        firstItem={{content: <Icon name='angle double left'/>, icon: true}}
                                        lastItem={{content: <Icon name='angle double right'/>, icon: true}}
                                        prevItem={{content: <Icon name='angle left'/>, icon: true}}
                                        nextItem={{content: <Icon name='angle right'/>, icon: true}}
                                        activePage={pageCurrent}
                                        onPageChange={this.handlePaginationChange}
                                        totalPages={pageCount}
                                        disabled={pageCount<=1}
                                        pointing
                                        secondary
                                    />
                                        Показано <b>{items.length}</b> ({(pageCurrent -1 ) *
                                    api.paging.longTableRowsCount + 1} - {(pageCurrent - 1) *
                                    api.paging.longTableRowsCount + items.length}) из <b>{itemsCount}</b>
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan={4}>
                                    На странице <Select downward compact onChange={this.onPageSizeChanged} value={pageSize}  options={pagesizes}/>шт
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell sorted={column === 'name' ? direction : null}
                                                  onClick={this.handleSort('name')}>
                                    Название
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'state' ? direction : null}
                                                  onClick={this.handleSort('state')}>
                                    Статус
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'location' ? direction : null}
                                                  onClick={this.handleSort('location')}>
                                    Местоположение
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'holder' ? direction : null}
                                                  onClick={this.handleSort('holder')}>
                                    Держатель
                                </Table.HeaderCell>
                                <Table.HeaderCell sorted={column === 'mol' ? direction : null}
                                                  onClick={this.handleSort('mol')}>
                                    МОЛ
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {_.map(items, ({id, name, location, holder, mol, state}) => {
                                let stateText = '';
                                switch (state) {
                                    case 0:
                                        stateText = 'Получено';
                                        break;
                                    case 1:
                                        stateText = 'Оприходовано';
                                        break;
                                    case 2:
                                        stateText = 'Списано';
                                        break;
                                    case 3:
                                        stateText = 'Утрачено';
                                        break;
                                    default:
                                        break;
                                }
                                return (
                                    <Table.Row key={id}>
                                        <Table.Cell>{name}</Table.Cell>
                                        <Table.Cell>{stateText}</Table.Cell>
                                        <Table.Cell>{location}</Table.Cell>
                                        <Table.Cell>{holder}</Table.Cell>
                                        <Table.Cell>{mol}</Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>

                        <Table.Footer>

                        </Table.Footer>
                    </Table>


                </div>}

                {errors.global && <Message negative>
                    <Message.Header>Ошибка</Message.Header>
                    <p>{errors.global}</p>
                </Message>}
            </div>
        );
    }
}


UserInfoView.propTypes = {};

export default UserInfoView;