import React from 'react';
import {connect} from 'react-redux';
import {Route} from 'react-router-dom';
import HomePage from './components/pages/HomePage';
import MtcPage from './components/pages/MtcPage';
import ImportsPage from './components/pages/imports/ImportsPage';
import ImportPage from './components/pages/imports/ImportPage';
import UserRoute from './components/routes/UserRoute';
import GuestRoute from './components/routes/GuestRoute';
import ConfirmationPage from './components/pages/ConfirmationPage';
import ForgotPasswordPage from './components/pages/ForgotPasswordPage';
import ResetPasswordPage from './components/pages/ResetPasswordPage';
import PropTypes from 'prop-types';
import TopNavigation from './components/navigation/TopNavigation';
import DepartmentsPage from "./components/pages/departments/DepartmentsPage";
import DepartmentPage from "./components/pages/departments/DepartmentPage";
import PositionsPage from "./components/pages/positions/PositionsPage";
import PositionPage from "./components/pages/positions/PositionPage";
import ApplicationUsersPage from "./components/pages/applicationUsers/ApplicationUsersPage";
import ApplicationUserPage from "./components/pages/applicationUsers/ApplicationUserPage";
import InventoriesPage from './components/pages/inventories/InventoriesPage';
import InventoryPage from './components/pages/inventories/InventoryPage';
import {Container} from "semantic-ui-react";
import ProfilePage from "./components/pages/profile/ProfilePage";
import LoginPage from "./components/pages/LoginPage";
import AdminLoginPage from "./components/pages/admin/AdminLoginPage"
import ClientsPage from './components/pages/admin/ClientsPage';
import 'semantic-ui-less/semantic.less'

import LicensePage from './components/pages/LicensePage'

const App = ({location, isAuthenticated, history}) => {

    const adminPathName = '/emagb5ag';

    // if (!location.pathname.startsWith(adminPathName)) {
    //     history.listen((location) => {
    //         ym('hit', location.pathname);
    //     });
    // }

    return (
        <Container
            fluid
            style={{height: '100%'}}>
            {isAuthenticated && <TopNavigation/>}

            {/*{!location.pathname.startsWith(adminPathName) && <YMInitializer accounts={[49769719]}/>}*/}

            {/*Админка*/}
            <Route location={location} path="/emagb5ag" exact component={AdminLoginPage}/>
            <UserRoute location={location} path="/emagb5ag/clients" exact component={ClientsPage}/>
            {/*<Route location={location} path="/emagb5ag/clients/:id" exact component={ClientPage}/>*/}


            <GuestRoute location={location} path="/" exact component={HomePage}/>
            <GuestRoute location={location} path="/login" exact component={LoginPage}/>

            <Route location={location} path="/confirmation/:token" exact component={ConfirmationPage}/>
            <GuestRoute location={location} path="/reset_password/:token" exact component={ResetPasswordPage}/>
            <GuestRoute location={location} path="/forgot_password" exact component={ForgotPasswordPage}/>

            {/*Работа с МТЦ*/}
            <UserRoute location={location} path="/mtc" exact component={MtcPage}/>

            {/*Работа с Импортом*/}
            <UserRoute location={location} path="/import" exact component={ImportsPage}/>
            <UserRoute location={location} path="/import/:id" exact component={ImportPage}/>

            {/*Работа с инвентаризациями*/}
            <UserRoute location={location} path="/inventory" exact component={InventoriesPage}/>
            <UserRoute location={location} path="/inventory/:id" exact component={InventoryPage}/>

            {/*Справочник департаментов*/}
            <UserRoute location={location} path="/departments" exact component={DepartmentsPage}/>
            <UserRoute location={location} path="/department/:type/:id" exact component={DepartmentPage}/>

            {/*Справочник должностей*/}
            <UserRoute location={location} path="/positions" exact component={PositionsPage}/>
            <UserRoute location={location} path="/position/:type/:id" exact component={PositionPage}/>

            {/*Справочник пользователей*/}
            <UserRoute location={location} path="/applicationUsers" exact component={ApplicationUsersPage}/>
            <UserRoute location={location} path="/applicationUser/:type/:id" exact component={ApplicationUserPage}/>

            <UserRoute location={location} path="/profile" exact component={ProfilePage}/>

            <UserRoute location={location} path="/license" exact component={LicensePage}/>

        </Container>
    );
};

App.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }).isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    history: PropTypes.shape({}).isRequired
};

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.token
    }
}

export default connect(mapStateToProps)(App);