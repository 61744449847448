import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {Button, Checkbox, Divider, Dropdown, Icon, Item, List, Message, Modal, Table} from "semantic-ui-react";
import TextStore from "../../../stores/TextStore";
import api from "../../../api";

class InventoryTasksView extends Component {
    state = {
        column: '',
        direction: '',
        openAddTask: false,
        selectedUser: '',
        errorsAddTask: {},
        loadingAddTask: false,
        users: [],
        selectedObjects: []
    };

    constructor(state){
        super(state);
        this.onSelectAll = this.onSelectAll.bind(this)
    }

    componentDidMount() {
        api.applicationUsers.getApplicationUsers()
            .then(res => {
                this.setState({
                    loading: false,
                    users: res.data.applicationUsers.map(x => ({
                        key: x.id,
                        text: x.lastName + " " + x.firstName + " " + x.surName,
                        value: x.id
                    })),
                    selectedUser: res.data.applicationUsers[0].id
                })
            })
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    onChangeUser = (param, data) => {
        if (data.value) {
            return this.setState({
                selectedUser: data.value
            });
        }
    };

    showAddTask = () => {
        this.setState({openAddTask: true});
    };

    closeAddTask = (id) => () => {
        this.setState({errorsAddTask: {}});
        if (id && id.length > 0) {
            this.setState({loadingAddTask: true});

            this.props.addTask(id, this.state.selectedObjects)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errorsAddTask: err.response.data.errors, loadingAddTask: false});
                        } else {
                            this.setState({
                                errorsAddTask: {global: TextStore.getUnknownNetworkError()},
                                loadingAddTask: false
                            });
                        }
                    } else {
                        this.setState({
                            errorsAddTask: {global: TextStore.getMethodNotFoundError()},
                            loadingAddTask: false
                        });
                    }
                });
        } else {
            this.setState({openAddTask: false});
        }
    };

    onChangeObjects = (id) => (param, data) => {
        let selectedObjects = this.state.selectedObjects;
        if (data.checked) {
            if (!selectedObjects.includes(id)) {
                selectedObjects.push(id);
            }
        } else {
            if (selectedObjects.includes(id)) {
                selectedObjects.remove(id);
            }
        }
        this.setState({
            selectedObjects: selectedObjects
        });
        console.log("selectedObjects", selectedObjects);
    };

    onSelectAll(){
        let selectedObjects = this.props.inventoryObjects.map(i=> i.id);
        console.log("selectedObjects", selectedObjects);
        this.setState({
            selectedObjects: selectedObjects
        });
    }

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {column, direction, openAddTask, loadingAddTask, errorsAddTask, selectedUser, users} = this.state;
        console.log(this.props.inventoryObjects);
        return (
            <div>
                <Table sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={column === 'number' ? direction : null}
                                              onClick={this.handleSort('number')}>
                                №
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'state' ? direction : null}
                                              onClick={this.handleSort('state')}>
                                Состояние
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'text' ? direction : null}
                                              onClick={this.handleSort('text')}>
                                Инв. объекты
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'responsibleUser' ? direction : null}
                                              onClick={this.handleSort('responsibleUser')}>
                                Ответственный
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Процесс поиска
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>
                                Действия
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(this.props.tasks, ({id, number, state, responsibleUser, text, itemInfoFound, itemInfoAll}) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{number}</Table.Cell>
                                    <Table.Cell>{api.inventories.parseTaskState(state)}</Table.Cell>
                                    <Table.Cell>{text}</Table.Cell>
                                    <Table.Cell>{responsibleUser}</Table.Cell>
                                    <Table.Cell>{itemInfoFound.toString() + " / " + itemInfoAll.toString()}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <Button onClick={()=>api.mobile.closeTask(id)}
                                                floated='right'
                                                icon
                                                labelPosition='left'
                                                negative
                                                size='small'>
                                            <Icon name='cancel'/>Завершить
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell>
                                {!this.props.readOnly && <Button
                                    onClick={this.showAddTask}
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    positive
                                    size='small'>
                                    <Icon name='plus'/>Добавить
                                    </Button>}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>

                {users && <Modal open={openAddTask}
                                 style={this.inlineStyle.modal}
                >
                    <Modal.Header>
                        Добавление задачи
                    </Modal.Header>
                    <Modal.Content>
                        {!loadingAddTask && <div>
                            <Item>
                                <Item.Content>
                                    <Item.Header>
                                        Исполнитель задачи
                                    </Item.Header>
                                    <Item.Description>
                                        <Dropdown
                                            options={users}
                                            button
                                            basic
                                            fluid
                                            floating
                                            id="user"
                                            name="user"
                                            value={selectedUser}
                                            onChange={this.onChangeUser}/>
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                            <Divider horizontal>Объекты инвентаризации</Divider>

                            <List celled>
                                {_.map(this.props.inventoryObjects.filter(io=>!io.taskid), ({id, name}) => {
                                    return (
                                        <List.Item key={id}>
                                            <List.Icon><Checkbox onChange={this.onChangeObjects(id)}
                                                                 readOnly={this.props.readOnly}
                                                                 checked={this.state.selectedObjects.indexOf(id)>=0}
                                            /></List.Icon>
                                            <List.Content>
                                                <List.Header>
                                                    {name}
                                                </List.Header>
                                            </List.Content>
                                        </List.Item>
                                    );
                                })}
                            </List>
                            <Button
                                onClick={this.onSelectAll}
                                icon
                                labelPosition='left'
                                positive
                                size='small'>
                                <Icon name='edit'/>Выбрать все
                            </Button>
                        </div>}
                        {loadingAddTask &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}
                        {errorsAddTask.global &&
                        <Message negative><Message.Header>Ошибка</Message.Header><p>{errorsAddTask.global}</p>
                        </Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Нет"
                            onClick={this.closeAddTask('')}
                        />
                        <Button
                            disabled ={this.state.selectedObjects.length === 0}
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Да'
                            onClick={this.closeAddTask(selectedUser)}
                        />
                    </Modal.Actions>
                </Modal>}
            </div>
        );
    }
}


InventoryTasksView.propTypes = {
    readOnly: PropTypes.bool.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        number: PropTypes.number.isRequired,
        state: PropTypes.number.isRequired,
        responsibleUser: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            surName: PropTypes.string.isRequired
        }).isRequired
    })).isRequired,
    inventoryObjects: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        taskid: PropTypes.string.isRequired
    })).isRequired,
    addTask: PropTypes.func.isRequired
};

export default InventoryTasksView;