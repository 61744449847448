import React from 'react';
import PropTypes from 'prop-types';
import {Redirect, Route} from "react-router-dom";
import {connect} from 'react-redux';
import AuthStore from "../../stores/AuthStore";

const GuestRoute = ({isAuthenticated, component: Component, ...rest}) => (
    <Route {...rest}
           render={props => !isAuthenticated ? <Component {...props}/> : <Redirect to={"/" + AuthStore.getRoute()}/>}/>
);

GuestRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        isAuthenticated: !!state.user.token
    }
}

export default connect(mapStateToProps)(GuestRoute);