import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "../../../api";
import {Button, Dropdown, Form} from "semantic-ui-react";
import TextStore from "../../../stores/TextStore";
import InlineError from "../../messages/InlineError";

class InventoryForm extends Component {
    state = {
        errors: {},
        data: {
            type: 0
        },
        inventoryTypes: [{
            key: 0,
            value: 0,
            text: api.inventories.parseType(0)
        }, {
            key: 1,
            value: 1,
            text: api.inventories.parseType(1)
        }, {
            key: 2,
            value: 2,
            text: api.inventories.parseType(2)
        }]
    };

    onChangeType = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, type: data.value}
            });
        }
    };

    validate = data => {
        const errors = {};

        return errors;
    };

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data.errors, loading: false});
                        } else {
                            this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                        }
                    } else {
                        this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                    }
                });
        }
    };

    render() {
        const {data, errors, inventoryTypes} = this.state;
        return (
            <Form
                onSubmit={this.onSubmit}
                className="large"
                style={{height: '170px'}}>
                <Form.Field error={!!errors.type}>
                    <label htmlFor="type">Тип инвентаризации</label>
                    <Dropdown
                        options={inventoryTypes}
                        button
                        basic
                        fluid
                        floating
                        id="type"
                        name="type"
                        value={data.type}
                        onChange={this.onChangeType}/>
                    {errors.type && <InlineError text={errors.type}/>}
                </Form.Field>

                <Button fluid primary>Создать</Button>
            </Form>
        );
    }
}


InventoryForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default InventoryForm;