import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import rootReducer from './rootReducer';
import {userLoggedIn, userLoggedOut} from "./actions/auth";
import axios from 'axios';
import AuthStore from "./stores/AuthStore";
import api from "./api";


const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))

);

// eslint-disable-next-line
Array.prototype.remove = function (value) {
    const idx = this.indexOf(value);
    if (idx !== -1) {
        return this.splice(idx, 1);
    }
    return false;
};

axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
    // Do something with response error
    if (error.response.status === 401) {
        console.log('unauthorized error');
        AuthStore.removeToken();
        axios.defaults.headers.common['Authorization'] = 'Bearer  ' + AuthStore.getToken();
        store.dispatch(userLoggedOut());
        window.location.replace('/');
    }
    return Promise.reject(error);
});

if (localStorage.JWT) {
    axios.defaults.headers.common['Authorization'] = 'Bearer  ' + AuthStore.getToken();
    api.user.userInfo()
        .then(res => {
            const payload = res.data.value.user;
            const user = {
                token: localStorage.JWT,
                email: payload.email,
                permission: payload.permission
            };
            console.log(user);
            store.dispatch(userLoggedIn(user));
        })
        .catch(() => {
            AuthStore.removeToken();
            axios.defaults.headers.common['Authorization'] = 'Bearer  ' + AuthStore.getToken();
            store.dispatch(userLoggedOut());
        })
}

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Route component={App}/>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'));
registerServiceWorker();
