import React, {Component} from 'react';
import PropTypes from 'prop-types';
import api from "../../../api";
import {Button, Divider, Header, Icon, Message, Modal, Segment, Step} from "semantic-ui-react";
import InventoryLocationsView from "./InventoryLocationsView";
import InventoryMolsView from './InventoryMolsView';
import InventoryHoldersView from './InventoryHoldersView';
import InventoryTasksView from './InventoryTasksView';
import InventoryItemsInfos from "./InventoryItemsInfos";

import FileDownload from "react-file-download";
import {connect} from "react-redux";

class InventoryPage extends Component {
    state = {
        loading: true,
        inventory: {},
        open: false
    };

    inventoryItemsInfosRef;

    constructor(state) {
        super(state);
        this.onDeleteLocation = this.onDeleteLocation.bind(this);
        this.onAddLocation = this.onAddLocation.bind(this);
        this.onAddLevel = this.onAddLevel.bind(this);

        this.onDeleteMol = this.onDeleteMol.bind(this);
        this.onAddMol = this.onAddMol.bind(this);

        this.onDeleteHolder = this.onDeleteHolder.bind(this);
        this.onAddHolder = this.onAddHolder.bind(this);

        this.onAddTask = this.onAddTask.bind(this);

        this.updateInventory = this.updateInventory.bind(this);
    }

    componentDidMount() {
        this.updateInventory();
    }

    updateInventory() {
        this.setState({
            loading: true
        });
        api.inventories.getSingleFast(this.props.match.params.id)
            .then(inventory => this.setState({
                inventory: inventory,
                loading: false
            }))
    }

    onDeleteLocation(id) {
        return api.inventories.deleteLocation(this.props.match.params.id, id)
            .then(() => this.updateInventory());
    }

    onAddLocation(id) {
        return api.inventories.addLocation(this.props.match.params.id, id)
            .then(() => this.updateInventory());
    }

    onAddLevel(id){
        return api.inventories.addLocationLevel(this.props.match.params.id, id)
            .then(() => this.updateInventory());
    }

    onDeleteMol(id) {
        return api.inventories.deleteMol(this.props.match.params.id, id)
            .then(() => this.updateInventory());
    }

    onAddMol(id) {
        return api.inventories.addMol(this.props.match.params.id, id)
            .then(() => this.updateInventory());
    }

    onDeleteHolder(id) {
        return api.inventories.deleteHolder(this.props.match.params.id, id)
            .then(() => this.updateInventory());
    }

    onAddHolder(id) {
        return api.inventories.addHolder(this.props.match.params.id, id)
            .then(() => this.updateInventory());
    }

    onAddTask(id, selectedObjects) {
        return api.inventories.addTask(this.props.match.params.id, id, selectedObjects)
            .then(() => this.updateInventory());
    }

    static mapInventoryObjects(inventory) {
        switch (inventory.type) {
            case 0:
                return inventory.locationsFull;
            case 1:
                return inventory.holdersFull;
            case 2:
                return inventory.molsFull;
            default:
                return [];
        }
    }

    static isInventoryPrepared(inventory) {
        switch (inventory.type) {
            case 0:
                return inventory.locationsFull.length > 0 &&
                    inventory.locationsFull.filter(x => !x.taskid).length === 0;
            case 1:
                return inventory.holdersFull.length > 0 &&
                    inventory.holdersFull.filter(x => !x.taskid).length === 0;
            case 2:
                return inventory.molsFull.length > 0 &&
                    inventory.molsFull.filter(x => !x.taskid).length === 0;

            default:
                return false;
        }
    }

    static isAllItemsFound(inventory) {
        if (!inventory.tasks)
            return false;
        for (let index = 0; index < inventory.tasks.length; index++) {
            if (inventory.tasks[index].itemInfoFound!==inventory.tasks[index].itemInfoAll)
                return false;
        }
        return true;
    }

    static isAllTasksCompleted(inventory) {
        if (!inventory.tasks)
            return false;
        return inventory.tasks.filter(x => x.state !== 2).length === 0;
    }

    close = (ok) => () => {
        if (ok) {
            api.inventories.nextStep(this.props.match.params.id)
                .then(() => this.setState({open: false}))
                .then(() => this.updateInventory())
        } else {
            this.setState({open: false});
        }
    };

    open = () => {
        this.setState({open: true});
    };



    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {loading, inventory, open} = this.state;
        const inventoryObjects = InventoryPage.mapInventoryObjects(inventory);
        const inventoryPrepared = InventoryPage.isInventoryPrepared(inventory);
        const allTasksCompleted = InventoryPage.isAllTasksCompleted(inventory);
        return (
            <div className="ui container">
                {loading &&
                <Message icon><Icon name="circle notched"
                                    loading/><Message.Header>Загрузка</Message.Header></Message>}
                {!loading && inventory && (
                    <Segment>
                        <div style={{clear: 'bo', overflow: 'auto'}}>
                            <Header as="h2" style={{float: 'left'}}>
                                Инвентаризация №{inventory.number} [{api.inventories.parseType(inventory.type)}]
                            </Header>
                            {this.props.permission.allowIssueInventoryTask && inventory.state === 0 &&
                            inventoryPrepared && <Button
                                onClick={this.open}
                                positive
                                style={{float: 'right'}}
                                icon>
                                <Icon name="checkmark"/>
                                В работу
                            </Button>}
                            {inventory.state === 3 && <Button
                                onClick={() => api.inventories.getReportInv1(this.props.match.params.id)
                                    .then(res => {
                                        const url = window.URL.createObjectURL(new Blob([res.data]));
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'inv_' + inventory.number + '.xlsx');
                                        document.body.appendChild(link);
                                        link.click();
                                    })}
                                positive
                                style={{float: 'right'}}
                                icon>
                                <Icon name="checkmark"/>
                                Инв. 1
                            </Button>}
                        {inventory.state === 3 && <Button
                            onClick={() => api.inventories.getReportInv2(this.props.match.params.id)
                                .then(res => {
                                    const url = window.URL.createObjectURL(new Blob([res.data]));
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute('download', 'inv2_' + inventory.number + '.xlsx');
                                    document.body.appendChild(link);
                                    link.click();
                                })}
                            positive
                            style={{float: 'right'}}
                            icon>
                            <Icon name="checkmark"/>
                            Инв. 2
                        </Button>}
                        </div>
                        <Divider/>
                        {this.renderStep(inventory, inventoryPrepared, allTasksCompleted)}
                        <Divider/>
                        {inventory.state < 2 && this.renderInvObjectList(inventory)}
                        {inventory.state < 2 && <Header as="h3">
                            Задачи
                        </Header>}
                        {inventory.state === 1 && InventoryPage.isAllItemsFound(inventory) && <Header as="h5">
                            Все ТМЦ найдны, ждем завершения задач
                        </Header>}
                        {inventory.state < 2 && <InventoryTasksView readOnly={
                            (!this.props.permission.allowIssueInventoryTask) ||
                            inventory.state === 1 ||
                            (inventory.state === 0 && inventoryPrepared) ||
                            (inventory.state === 0 && inventoryObjects.length === 0)
                        }
                                                                    tasks={inventory.tasks}
                                                                    inventoryObjects={inventoryObjects}
                                                                    addTask={this.onAddTask}/>}
                        {inventory.state === 2 && <Header as="h3">Конфликты</Header>}
                        {inventory.state === 2 && <InventoryItemsInfos tasks={inventory.tasks}
                                                                       onlyNotFound={true}
                                                                       selectable={true}
                                                                       ref={(n) => this.inventoryItemsInfosRef = n}
                                                                       inv={this.props.match.params.id}
                                                                       onUpdate={this.updateInventory}
                                                                       action={true}/>}
                        {inventory.state === 3 && <InventoryItemsInfos tasks={inventory.tasks}
                                                                       onlyNotFound={false}
                                                                       selectable={false}
                                                                       inv={this.props.match.params.id}
                                                                       onUpdate={this.updateInventory}
                                                                       action={false}/>}
                    </Segment>
                )}

                <Modal size="mini" open={open} style={this.inlineStyle.modal}>
                    <Modal.Header>
                        Подтверждение
                    </Modal.Header>
                    <Modal.Content>
                        Вы уверены, что хотите перейти на следующий этап проведения инвентаризации?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Нет"
                            onClick={this.close(false)}
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Да'
                            onClick={this.close(true)}
                        />
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }

    renderInvObjectList(inventory) {
        return <div>
            <Header as="h3" content="Объекты инвентаризации"/>
            {inventory.type === 0 &&
            <InventoryLocationsView locations={inventory.locationsFull}
                                    readOnly={(!this.props.permission.allowIssueInventoryTask) ||
                                    inventory.state === 1}
                                    addLevel={this.onAddLevel}
                                    deleteLocation={this.onDeleteLocation}
                                    addLocation={this.onAddLocation}/>}
            {inventory.type === 1 &&
            <InventoryHoldersView holders={inventory.holdersFull}
                                  readOnly={(!this.props.permission.allowIssueInventoryTask) ||
                                  inventory.state === 1}
                                  deleteHolder={this.onDeleteHolder}
                                  addHolder={this.onAddHolder}/>}
            {inventory.type === 2 &&
            <InventoryMolsView mols={inventory.molsFull}
                               readOnly={(!this.props.permission.allowIssueInventoryTask) ||
                               inventory.state === 1}
                               deleteMol={this.onDeleteMol}
                               addMol={this.onAddMol}/>}
        </div>
    }

    renderStep(inventory, inventoryPrepared, allTasksCompleted) {
        return <Step.Group ordered>
            <Step completed={inventory.state > 0} active={inventory.state === 0}>
                <Step.Content>
                    Подготовка
                </Step.Content>
            </Step>
            <Step completed={inventory.state > 1}
                  active={inventory.state === 1}
                  disabled={!inventoryPrepared}>
                <Step.Content>
                    В работе
                </Step.Content>
            </Step>
            <Step completed={inventory.state > 2}
                  active={inventory.state === 2}
                  disabled={!allTasksCompleted}>
                <Step.Content>
                    Завершена
                </Step.Content>
            </Step>
            <Step completed={inventory.state === 3}
                  active={inventory.state === 3}
                  disabled={!(inventory.state === 3 && InventoryPage.isAllItemsFound(inventory))}>
                <Step.Content>
                    Закрыта
                </Step.Content>
            </Step>
        </Step.Group>;
    }
}

InventoryPage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(InventoryPage);