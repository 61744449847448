import React, {Component} from 'react';
import {Icon, Menu, Message, Modal, Sidebar} from "semantic-ui-react";
import api from "../../api";
import TreeBeard from "./FancyTreeView/components/treebeard";
import LocationItemForm from "../forms/locationItems/LocationItemForm";
import PropTypes from 'prop-types';

class LocationItemsTree extends Component {
    state = {
        loading: true,
        data: {},
        selectedType: -1,
        open: false,
        readOnly: false,
        isRoot: true,
        isNew: false,
        visible: false
    };

    constructor(props) {
        super(props);
        this.onToggle = this.onToggle.bind(this);
        this.submit = this.submit.bind(this);
        this.updateTree = this.updateTree.bind(this);
    }

    componentDidMount() {
        this.updateTree();
    }

    updateTree() {
        this.setState({
            loading: true
        });
        api.locationItems.getLocationItems()
            .then(items => {
                for (let i = 0; i < items.length; i++) {
                    items[i].toggled = true;
                    if (items[i].children)
                        for (let j = 0; j < items[i].children.length; j++) {
                            items[i].children[j].toggled = false;
                        }
                }
                if (items.length > 0) {
                    items[0].active = true;

                    this.props.selectedLocationChanged(items[0]);
                    this.setState({
                        loading: false,
                        data: items,
                        selectedType: items[0].type,
                        cursor: items[0]
                    });
                } else {
                    this.setState({
                        loading: false,
                        data: items
                    })
                }
                return items;
            });
    }

    onToggle(node, toggled) {
        this.props.selectedLocationChanged(node);
        if (this.state.cursor) {
            // eslint-disable-next-line
            this.state.cursor.active = false;
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }
        this.setState({cursor: node, selectedType: node.type});
    }

    submit(data) {
        const {isRoot, cursor, readOnly} = this.state;
        if (readOnly) {
            return api.locationItems.deleteLocationItem(data.id)
                .then(() => {
                    this.close();
                    this.updateTree();
                })
        } else {
            if (isRoot)
                return api.locationItems.saveLocationItem({
                    id: data.id,
                    name: data.name,
                    parentId: '0'
                }).then(() => {
                    this.close();
                    this.updateTree();
                });
            else
                return api.locationItems.saveLocationItem({
                    id: data.id,
                    name: data.name,
                    parentId: cursor.id
                }).then(() => {
                    this.close();
                    this.updateTree();
                });
        }
    }

    closeConfigShow = (name, readOnly, isRoot, isNew) => () => {
        this.setState({
            open: true,
            caption: name,
            readOnly: readOnly,
            isRoot: isRoot,
            isNew: isNew
        })
    };

    close = () => this.setState({open: false});
    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };
    render() {
        const {
            loading, data, selectedType, cursor, readOnly, open, caption, isNew
        } = this.state;
        return (

            <div
                style={{
                    height: '100%'
                }}>
                <Menu compact icon='labeled' size="mini">
                    {(selectedType === 0 || selectedType === -1)  &&
                    <Menu.Item onClick={this.closeConfigShow("Добавление нового здания", false, true, true)}>
                        <Icon name="outline building"/>
                        Добавить<br/>здание
                    </Menu.Item>}
                    {selectedType === 0 &&
                    <Menu.Item onClick={this.closeConfigShow("Добавление нового этажа", false, false, true)}>
                        <Icon name="cubes"/>
                        Добавить<br/>этаж
                    </Menu.Item>}
                    {selectedType === 1 &&
                    <Menu.Item
                        onClick={this.closeConfigShow("Добавление нового помещения", false, false, true)}>
                        <Icon name="cube"/>
                        Добавить<br/>помещение
                    </Menu.Item>}
                    {selectedType !== -1 &&
                    <Menu.Item color="blue"
                               onClick={this.closeConfigShow("Редактирование", false, true, false)}>
                        <Icon name="edit"/>
                        Редактировать
                    </Menu.Item>}
                    {selectedType !== -1 &&
                    <Menu.Item active={true} color="red"
                               onClick={this.closeConfigShow("Удаление", true, true, false)}>
                        <Icon name="remove"/>
                        Удалить
                    </Menu.Item>}
                </Menu>
                {!loading && (
                    <TreeBeard data={data} onToggle={this.onToggle}/>
                )}
                {loading &&
                <Message icon><Icon name="circle notched"
                                    loading/><Message.Header>Загрузка</Message.Header></Message>}

                <Modal
                    className="middle aligned center aligned"
                    open={open}
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                    style={this.inlineStyle.modal}
                    onClose={this.close}>
                    <Modal.Header>
                        {caption}
                    </Modal.Header>
                    <Modal.Content>
                        <LocationItemForm readOnly={readOnly} submit={this.submit}
                                          locationItem={isNew ? null : cursor}/>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

LocationItemsTree.propTypes = {
    visible: PropTypes.bool.isRequired,
    selectedLocationChanged: PropTypes.func.isRequired
};

export default LocationItemsTree;