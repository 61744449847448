import {USER_LOGGED_IN, USER_LOGGED_OUT} from '../types';
import api from '../api';
import AuthStore from "../stores/AuthStore";
import axios from "axios/index";

export const userLoggedIn = user => ({
    type: USER_LOGGED_IN,
    user
});
export const userLoggedOut = () => ({
    type: USER_LOGGED_OUT
});

export const login = credentials => dispatch =>
    api.user.login(credentials).then(token => {
        AuthStore.setToken(token.token);
        axios.defaults.headers.common['Authorization'] = 'Bearer  ' + token.token;
        api.user.userInfo().then(user => {
            dispatch(userLoggedIn(user));
        });
    });

export const logout = () => dispatch => {
    AuthStore.setRoute('--logout');
    AuthStore.removeToken();
    axios.defaults.headers.common['Authorization'] = '';
    dispatch(userLoggedOut());
};

export const confirm = token => (dispatch) =>
    api.user.confirm(token).then(user => {
        localStorage.JWT = user.token;
        dispatch(userLoggedIn(user));
    });

export const resetPasswordRequest = ({email}) => () =>
    api.user.resetPasswordRequest(email);

export const validateToken = (token) => () =>
    api.user.validateToken(token);

export const resetPassword = (data) => () =>
    api.user.resetPassword(data);