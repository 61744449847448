import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Icon, Message, Modal, Table} from "semantic-ui-react";
import _ from "lodash";
import api from "../../../api";
import TextStore from "../../../stores/TextStore";

class InventoryLocationsView extends Component {
    state = {
        column: '',
        direction: '',
        locationId: '',
        locationName: '',
        open: false,

        openAddLocation: false,
        loadingAddLocation: false,
        selectedLocation: '',
        loadingItems: true,
        locations: [],


        openAddLevel: false,
        loadingAddLevel: false,
        selectedLocationLevel: '',
        loadingLevels: true,
        locationLevels: [],

        errorsAddLocation: {},

        loadingDeleteLocation: false,
        errorsDeleteLocation: {},
        loading: true,

    };

    constructor(state) {
        super(state);
        this.showAddLocationLevel = this.showAddLocationLevel.bind(this);
    }

    componentDidMount() {
        api.locationItems.getNotDeletedLocationItems()
            .then(locationItems => {
                let builds = locationItems.flatMap(b => b.children!==null?
                    b.children.flatMap(l=>l.children!==null?
                        l.children.flatMap( r =>
                            ({
                                key: r.id,
                                text: b.name+"/" + l.name + "/"+ r.name,
                                value: r.id
                            })
                        ):[]):[]);

                this.setState({
                    loading: this.state.loadingLevels,
                    loadingItems: false,
                    locations: builds,
                    selectedLocation: builds[0].id
                })
            });
        api.locationItems.getLocationLevelsInline()
            .then(locationItems => {
                this.setState({
                    loading: this.state.loadingItems,
                    loadingLevels: false,
                    locationLevels: locationItems.map(x => ({
                        key: x.id,
                        text: x.name,
                        value: x.id
                    })),
                    selectedLocationLevel: locationItems[0].id
                })
            });
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending'
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    deleteLocationInternal = (id, name) => () => {
        this.setState({
            locationId: id,
            open: true,
            locationName: name
        })
    };

    close = (id) => () => {
        this.setState({errorsDeleteLocation: {}});
        if (id && id.length > 0) {
            this.setState({loadingDeleteLocation: true});
            this.props.deleteLocation(id)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({
                                errorsDeleteLocation: err.response.data.errors,
                                loadingDeleteLocation: false
                            });
                        } else {
                            this.setState({
                                errorsDeleteLocation: {global: TextStore.getUnknownNetworkError()},
                                loadingDeleteLocation: false
                            });
                        }
                    } else {
                        this.setState({
                            errorsDeleteLocation: {global: TextStore.getMethodNotFoundError()},
                            loadingDeleteLocation: false
                        });
                    }
                });
        } else {
            this.setState({open: false});
        }
    };

    showAddLocationItem = () => {
        this.setState({
            openAddLocation: true
        });
    };

    showAddLocationLevel(){
        this.setState({errorsAddLocation: {}, openAddLevel: true });
    }

    closeAddLocationLevel(id){
        if (!(id && id.length > 0)) {
            this.setState({errorsAddLocation: {}, openAddLevel: false });
            return
        }
        this.setState({errorsAddLocation: {}, loadingAddLevel: true});
        this.props.addLevel(id).catch(err => {
            if (!!err.response) {
                if (err.response.data) {
                    this.setState({errorsAddLocation: err.response.data.errors, loadingAddLevel: false});
                } else {
                    this.setState({
                        errorsAddLocation: {global: TextStore.getUnknownNetworkError()},
                        loadingAddLevel: false
                    });
                }
            } else {
                this.setState({
                    errorsAddLocation: {global: TextStore.getMethodNotFoundError()},
                    loadingAddLevel: false
                });
            }
        });
    }

    onChangeLocationLevel = (param, data) => {
        if (data.value) {
            return this.setState({
                selectedLocationLevel: data.value
            });
        }
    };

    closeAddLocationItem = (id) => () => {
        this.setState({errorsAddLocation: {}});
        if (id && id.length > 0) {
            this.setState({loadingAddLocation: true});
            if (this.props.locations.some(item => item.id === id)) {
                this.setState({
                    errorsAddLocation: {global: 'Помещение уже привязано к инвентаризации'},
                    loadingAddLocation: false
                });
            } else {
                this.props.addLocation(id)
                    .catch(err => {
                        if (!!err.response) {
                            if (err.response.data) {
                                this.setState({errorsAddLocation: err.response.data.errors, loadingAddLocation: false});
                            } else {
                                this.setState({
                                    errorsAddLocation: {global: TextStore.getUnknownNetworkError()},
                                    loadingAddLocation: false
                                });
                            }
                        } else {
                            this.setState({
                                errorsAddLocation: {global: TextStore.getMethodNotFoundError()},
                                loadingAddLocation: false
                            });
                        }
                    });
            }
        } else {
            this.setState({openAddLocation: false});
        }
    };

    onChangeLocation = (param, data) => {
        if (data.value) {
            return this.setState({
                selectedLocation: data.value
            });
        }
    };

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        let {
            column, direction, locationId, locationName, open, openAddLocation, locations,
            selectedLocation, loadingAddLocation, errorsAddLocation, loadingDeleteLocation,
            errorsDeleteLocation, loadingAddLevel, openAddLevel, selectedLocationLevel,locationLevels
        } = this.state;
        const filterlocations = locations.filter((v)=>Object.values(this.props.locations).every(l=>l.id!=v.key));
        if(filterlocations.length>0 && !filterlocations.find(m=>m.key==selectedLocation))
            selectedLocation = filterlocations[0].key;
        return (
            <div>
                <Table sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={column === 'name' ? direction : null}
                                              onClick={this.handleSort('name')}>
                                Название
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>
                                Действия
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(this.props.locations, ({id, name}) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{name}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {!this.props.readOnly && <Button
                                            onClick={this.deleteLocationInternal(id, name)}
                                            size="mini"
                                            color="red"
                                            icon>
                                            <Icon name="delete"/>
                                        </Button>}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    {!this.props.readOnly && filterlocations.length>0 && <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell>
                                <Button
                                    onClick={this.showAddLocationItem}
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    positive
                                    size='small'>
                                    <Icon name='plus'/>Добавить помещение
                                </Button>
                                <Button
                                    onClick={this.showAddLocationLevel}
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    positive
                                    size='small'>
                                    <Icon name='plus'/>Добавить этаж
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>}
                </Table>
                <Modal size="mini" open={open} style={this.inlineStyle.modal}>
                    <Modal.Header>
                        Удаление помещения
                    </Modal.Header>
                    <Modal.Content>
                        {!loadingDeleteLocation &&
                        <p>Вы уверены, что хотите удалить помещение [{locationName}] из инвентаризации?</p>}
                        {loadingDeleteLocation &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}
                        {errorsDeleteLocation.global &&
                        <Message negative><Message.Header>Ошибка</Message.Header><p>{errorsDeleteLocation.global}</p>
                        </Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Нет"
                            onClick={this.close('')}
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Да'
                            onClick={this.close(locationId)}
                        />
                    </Modal.Actions>
                </Modal>

                {locations && <Modal open={openAddLocation} style={this.inlineStyle.modal}>
                    <Modal.Header>
                        Добавление помещения
                    </Modal.Header>
                    <Modal.Content>
                        {!loadingAddLocation && <Dropdown
                            options={filterlocations}
                            button
                            scrolling
                            basic
                            fluid
                            id="location"
                            name="location"
                            value={selectedLocation}
                            onChange={this.onChangeLocation}/>}
                        {loadingAddLocation &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}
                        {errorsAddLocation.global &&
                        <Message negative><Message.Header>Ошибка</Message.Header><p>{errorsAddLocation.global}</p>
                        </Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Нет"
                            onClick={this.closeAddLocationItem('')}
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Да'
                            onClick={this.closeAddLocationItem(selectedLocation)}
                        />
                    </Modal.Actions>
                </Modal>}
                {locations && <Modal open={openAddLevel} style={this.inlineStyle.modal}>
                    <Modal.Header>
                        Добавление этажа
                    </Modal.Header>
                    <Modal.Content>
                        {!loadingAddLevel && <Dropdown
                            options={locationLevels}
                            button
                            scrolling
                            basic
                            fluid
                            id="location"
                            name="location"
                            value={selectedLocationLevel}
                            onChange={this.onChangeLocationLevel}/>}
                        {loadingAddLevel &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}
                        {errorsAddLocation.global &&
                        <Message negative><Message.Header>Ошибка</Message.Header><p>{errorsAddLocation.global}</p>
                        </Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Нет"
                            onClick={()=>this.closeAddLocationLevel('')}
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Да'
                            onClick={()=>this.closeAddLocationLevel(selectedLocationLevel)}
                        />
                    </Modal.Actions>
                </Modal>}
            </div>
        );
    }
}


InventoryLocationsView.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
    readOnly: PropTypes.bool.isRequired,
    deleteLocation: PropTypes.func.isRequired,
    addLocation: PropTypes.func.isRequired,
    addLevel: PropTypes.func.isRequired,
}
;

export default InventoryLocationsView;