import React from 'react';
import PropTypes from 'prop-types';
import {Message} from "semantic-ui-react";

const PermissionDenied = ({text}) => (
    <Message negative>
        <Message.Header>У Вас нет доступа к {text}</Message.Header>
        <p>Обратитесь к администратору, чтобы повысить привилегии</p>
    </Message>
);

PermissionDenied.propTypes = {
    text: PropTypes.string.isRequired
};

export default PermissionDenied;