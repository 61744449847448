import React, {Component} from 'react';
import api from "../../../api";
import _ from "lodash";
import {Button, Container, Icon, Message, Modal, Pagination, Table, Grid, GridRow} from "semantic-ui-react";
import InventoryForm from '../../forms/inventories/InventoryForm'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Pagger from "../../controls/Pagger";
import Select from "semantic-ui-react/dist/es/addons/Select/Select";

class InventoriesPage extends Component {
    state = {
        loading: false,
        data: {},
        column: '',
        direction: '',
        openInventoryModal: false,
        isReadOnly: false,
        inventoryId: '',
        caption: '',
        pageCurrent: 1,
        from: 0,
        invsCount:0,
        rowsPerPage: api.paging.longTableRowsCount,
    };

    constructor(state) {
        super(state);
        this.update = this.update.bind(this);
        this.updateInventories = this.updateInventories.bind(this);
        this.submit = this.submit.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    update() {
        this.setState({
            loading: true
        });
        api.inventories.getFastCount()
            .then(count => {
                let res = api.paging.calcLongTablePageCount(count);
                this.setState({pageCount: res, invsCount: count, pageCurrent:1,});
                this.updateInventories(1, 0, this.state.rowsPerPage);
            })
    }

    updateInventories(page, from, rowsPerPage) {
        api.inventories.getFast(from, rowsPerPage)
            .then(inventories => {
                this.setState({
                    data: inventories,
                    loading: false,
                    pageCurrent: page,
                    rowsPerPage: rowsPerPage,
                    from: from
                })
            })
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    closeInventoryModal = () => this.setState({openInventoryModal: false});

    openInventoryModal = () => {
        this.setState({
            openInventoryModal: true
        })
    };

    onPageSizeChanged(e, { name, value })
    {
        this.updateInventories(this.state.pageCurrent, this.state.from, value)
    }

    submit(data) {
        return api.inventories.create(data.type)
            .then(() => this.closeInventoryModal())
            .then(() => this.updateInventories(1, 0, this.state.rowsPerPage));
    };

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {loading, data, openInventoryModal, pageCurrent, invsCount, rowsPerPage} = this.state;
        const pagesizes = [
            {
                text: api.paging.longTableRowsCount,
                value: api.paging.longTableRowsCount,
            },
            {
                text: 20,
                value:20,
            },
            {
                text: 50,
                value:50,
            },
            {
                text: 100,
                value:100,
            },
        ];
        return (
            <Grid floated='center'>
                <GridRow>
                    <Grid.Column mobile={3} tablet={3} computer={3} />
                    <Grid.Column  mobile={12} tablet={12} computer={12}>
                    {loading &&
                    <Message icon><Icon name="circle notched"
                                        loading/><Message.Header>Загрузка</Message.Header></Message>}

                    {!loading &&

                            <Table selectable fluid>
                                <Table.Header>
                                    <Table.Row>
                                        <Pagger colSpan={'6'}
                                                onPageChanged={this.updateInventories}
                                                pageCurrent={pageCurrent}
                                                totalItems={invsCount}
                                                rowsPerPage={this.state.rowsPerPage}
                                                currentItems={data.length}
                                                />
                                        <Table.HeaderCell colSpan={4}>
                                            На странице <Select downward compact onChange={this.onPageSizeChanged} value={rowsPerPage}  options={pagesizes}/>шт
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {this.props.permission.allowCreateInventory && (
                                                <Button
                                                    onClick={this.openInventoryModal}
                                                    floated='right'
                                                    icon
                                                    labelPosition='left'
                                                    positive
                                                    size='small'>
                                                    <Icon name='plus'/>Добавить
                                                </Button>
                                            )}
                                        </Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            №
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Дата создания
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Дата закрытия
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Состояние
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Тип
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Инв. объекты
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            Ответственный
                                        </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {_.map(data, ({id, number, dateCreated, dateClosed, state, type, responsibleUser, text}) => {
                                        let stateText = api.inventories.parseState(state);
                                        let typeText = api.inventories.parseType(type);
                                        return (
                                            <Table.Row key={id} onDoubleClick={this.rowClick(id)}>
                                                <Table.Cell>{number}</Table.Cell>
                                                <Table.Cell>{api.formatDate(dateCreated)}</Table.Cell>
                                                <Table.Cell>{dateClosed ? api.formatDate(dateClosed) : ''}</Table.Cell>
                                                <Table.Cell>{stateText}</Table.Cell>
                                                <Table.Cell>{typeText}</Table.Cell>
                                                <Table.Cell>{text}</Table.Cell>
                                                <Table.Cell>{responsibleUser ? responsibleUser: "Не задан"}</Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        }
                    <Modal
                        closeIcon
                        className="middle aligned center aligned"
                        open={openInventoryModal}
                        dimmer="blurring"
                        closeOnEscape={true}
                        closeOnRootNodeClick={true}
                        style={this.inlineStyle.modal}
                        onClose={this.closeInventoryModal}>
                        <Modal.Header>
                            Создание инвентаризации
                        </Modal.Header>
                        <Modal.Content size="medium" scrolling>
                            <InventoryForm submit={this.submit}/>
                        </Modal.Content>
                    </Modal>
                    </Grid.Column>
                </GridRow>
            </Grid>

        );
    }


    rowClick = (id) => () => {
        this.props.history.push('/inventory/' + id);
    }
}


InventoriesPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(InventoriesPage);