import React, {Component} from 'react';
import api from "../../../api";
import _ from "lodash";
import {Button, Container, Icon, Message, Modal, Pagination, Table, Grid, GridRow,Popup, Menu, Header, Image, Divider} from "semantic-ui-react";
/*import InventoryForm from '../../forms/inventories/InventoryForm'*/
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Pagger from "../../controls/Pagger";
import {Select} from 'semantic-ui-react';

class ImportsPage extends Component {
    state = {
        loading: false,
        data: {},
        dataproc: {},
        column: '',
        direction: '',
        isReadOnly: false,
        caption: '',
        pageCurrent: 1,
        from: 0,
        importsCount:0,
        rowsPerPage: api.paging.longTableRowsCount,
        modalOpen: false,
        upload: false,
        loadingimport: false
    };
    fileInput = null;

    constructor(state) {
        super(state);
        this.update = this.update.bind(this);
        this.updateImports = this.updateImports.bind(this);
        /*this.submit = this.submit.bind(this);*/

        this.openFileDialog = this.openFileDialog.bind(this);
        this.closeFileDialog = this.closeFileDialog.bind(this);
        this.uploadXlsx = this.uploadXlsx.bind(this);

        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.applyImport = this.applyImport.bind(this);

    }

    handleOpen = () => {this.setState({modalOpen: true})};

    handleClose = () => {this.setState({modalOpen: false}); this.clearDataProc()};

    handleUploadOpen = () => {this.setState({upload: true})};

    handleUploadClose = () => {this.setState({upload: false}); this.clearDataProc()};

    openFileDialog() {

        this.fileInput.click();
    }
    closeFileDialog() {

        this.fileInput.click();
    }
    uploadXlsx(fileInput) {

        if (this.fileInput.files.length === 0)
            return;
        api.imports.uploadXlsx(this.fileInput.files[0]);
        this.update();

    }

    componentDidMount() {
        if (!this.state.upload || !this.state.modalOpen) {
            this.update();
        }
    }

    update() {
        this.setState({
            loading: true
        });
        api.imports.getFastCount()
            .then(count => {
                let res = api.paging.calcLongTablePageCount(count);
                this.setState({pageCount: res, importsCount: count, pageCurrent:1,});
                this.updateImports(1, 0, this.state.rowsPerPage);
            })
    }

    updateImports(page, from, rowsPerPage) {
        api.imports.getFast(from, rowsPerPage)
            .then(imports => {
                this.setState({
                    data: imports,
                    loading: false,
                    pageCurrent: page,
                    rowsPerPage: rowsPerPage,
                    from: from
                })
            })
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    onPageSizeChanged(e, { name, value })
    {
        this.updateImports(this.state.pageCurrent, this.state.from, value)
    }

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {loading, loadingimport, data, dataproc, /*openInventoryModal,*/ pageCurrent, importsCount, rowsPerPage} = this.state;
        const pagesizes = [
            {
                text: api.paging.longTableRowsCount,
                value: api.paging.longTableRowsCount,
            },
            {
                text: 20,
                value:20,
            },
            {
                text: 50,
                value:50,
            },
            {
                text: 100,
                value:100,
            },
        ];
        return (
            <div>
                <input
                    style={{ marginTop: "20px", marginLeft : "50%", marginBottom: "30px"}}
                    ref={(input)=>{this.fileInput=input;}}
                    className="FileInput"
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    multiple={false}
                    onChange={this.uploadXlsx}
                />
            <Grid floated='center'>
                <GridRow>
                    <Grid.Column mobile={3} tablet={3} computer={3} />
                    <Grid.Column  mobile={12} tablet={12} computer={12}>
                        {loading &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Подождите. Идет предварительная обработка файлов импорта</Message.Header></Message>}

                        {!loading &&

                        <Table selectable fluid>
                            <Table.Header>
                                <Table.Row>
                                    <Pagger colSpan={'6'}
                                            onPageChanged={this.updateImports}
                                            pageCurrent={pageCurrent}
                                            totalItems={importsCount}
                                            rowsPerPage={this.state.rowsPerPage}
                                            currentItems={data.length}
                                    />
                                    <Table.HeaderCell colSpan={4}>
                                        На странице <Select downward compact onChange={this.onPageSizeChanged} value={rowsPerPage}  options={pagesizes}/>шт
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        Файл(байт)
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Дата создания
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Создано
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Позиций
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Клиент
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Тип файла
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Управление
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {_.map(data, ({id, fileLength, dateCreated, createdBy, numberOfPositions, clientId, client, fileType, importUnpackedValid, importDataValid}) => {

                                    let filetypeText = api.imports.parseFiletype(fileType);
                                    return (
                                        <Table.Row key={id} onDoubleClick={this.rowClick(id)}>
                                            <Table.Cell>{fileLength}</Table.Cell>
                                            <Table.Cell>{api.formatDate(dateCreated)}</Table.Cell>
                                            <Table.Cell>{createdBy}</Table.Cell>
                                            <Table.Cell>{numberOfPositions}</Table.Cell>
                                            <Table.Cell>{client}</Table.Cell>
                                            <Table.Cell>{filetypeText}</Table.Cell>
                                            <Table.Cell>  <Button.Group>

                                                <Popup
                                                    content={'Информация об Импорте'}
                                                    trigger={<Button icon={"info"} color='blue' size='mini' onClick={this.rowClick(id)} />}
                                                />
                                                <Popup trigger={<Button icon={"trash alternate"} color='red' size='mini'/>} on='click'
                                                       content={
                                                           <Button onDoubleClick={this.deleteById(id)} color='red'>Удалить импорт</Button>

                                                                }
                                                />
                                                {(!importUnpackedValid) ?
                                                    <Popup
                                                        content={'Распаковка импорта'}
                                                        trigger={<Button icon={"external alternate"} color='green'
                                                                         size='mini'
                                                                         onDoubleClick={this.unpackById(id)}/>}
                                                    /> :
                                                    <Popup
                                                        content={'Импорт распакован'}
                                                        trigger={<Button icon={"external alternate"} color='gray'
                                                                         size='mini' disabled
                                                                         />}
                                                    />
                                                }
                                                <Popup

                                                    content={'Обработка Импорта'}
                                                    trigger={  <Modal trigger={<Button icon={"exchange"}
                                                                                       color='yellow'
                                                                                       size='mini'
                                                                                       onClick={this.handleOpen}
                                                                                        />}

                                                                      onClose={this.handleClose}
                                                    >
                                                        <Modal.Header>Обработка импорта</Modal.Header>
                                                        <Modal.Content table>
                                                            <Table>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>
                                                                            Записей
                                                                        </Table.HeaderCell>
                                                                        <Table.HeaderCell>
                                                                            Количество
                                                                        </Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell>Всего</Table.Cell>
                                                                                <Table.Cell>{this.state.dataproc.totalRecords}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell>Могут быть добавлены</Table.Cell>
                                                                                <Table.Cell>{this.state.dataproc.addedRecords}</Table.Cell>
                                                                            </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Могут быть перемещены</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.relocatedItems}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Запись с отсутствующим в БД Местоположением</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.invalidLocationRecords}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Запись полностью совпадающая с имеющеюся записью в БД</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.sameDBRecords}</Table.Cell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>
                                                            <Divider/>
                                                            <Button icon={"exchange"}
                                                                    color='yellow'
                                                                    size='Large'
                                                                    onClick={()=>this.applyImport(id)}
                                                            > Обработать </Button>
                                                            {loadingimport &&
                                                            <Message icon><Icon name="circle notched"
                                                                                loading/><Message.Header>Подождите. Идет обработка. Скорее всего, записей очень много.</Message.Header></Message>}
                                                        </Modal.Content>
                                                    </Modal>
                                                    }
                                                />
                                                {(importDataValid) ?
                                                    <Popup
                                                        content={'Загрузка  Импорта'}
                                                        trigger={ <Modal trigger={<Button icon={"cloud upload"}
                                                                                                   color='green'
                                                                                                   size='mini'
                                                                                                   onClick={this.handleUploadOpen}

                                                    />}

                                                                                  onClose={this.handleUploadClose}

                                                    >
                                                        <Modal.Header>Обработка импорта</Modal.Header>
                                                        <Modal.Content table>
                                                            <Table>
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>
                                                                            Записей
                                                                        </Table.HeaderCell>
                                                                        <Table.HeaderCell>
                                                                            Количество
                                                                        </Table.HeaderCell>
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.Cell>Всего</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.totalRecords}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Могут быть добавлены</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.addedRecords}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Могут быть перемещены</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.relocatedItems}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Запись с отсутствующим в БД Местоположением</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.invalidLocationRecords}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell>Запись полностью совпадающая с имеющеюся записью в БД</Table.Cell>
                                                                        <Table.Cell>{this.state.dataproc.sameDBRecords}</Table.Cell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>
                                                            <Divider/>
                                                            <Button onClick={()=>this.applyUploadImport(id) }
                                                                    icon={"cloud upload"}
                                                                    color='green'
                                                                    size='Large'
                                                            > Загрузить в облако</Button>
                                                            {loadingimport &&
                                                            <Message icon><Icon name="circle notched"
                                                                                loading/><Message.Header>Подождите. Идет загрузка. Скорее всего, записей очень много.</Message.Header></Message>}
                                                        </Modal.Content>
                                                    </Modal>
                                                        }
                                                    /> :
                                                    <Popup
                                                        content={'Исправьте несоответсвия перед Загрузкой  Импорта'}
                                                        trigger={<Button icon={"cloud upload"} color='grey'
                                                                         size='mini'/>}
                                                    />
                                                }
                                            </Button.Group>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        }
                    </Grid.Column>
                </GridRow>
            </Grid>
            </div>
        );
    }


    rowClick = (id) => () => {
        this.props.history.push('/import/' + id);
    }

    deleteById = (id) => () => {
        api.imports.deleteImport(id);
        this.update();
    }
    unpackById = (id) => () => {
        api.imports.unpackImport(id);
        this.update();
    }
    applyImport(id) {
        this.setState({
            loadingimport: true,
        });

        if (this.state.modalOpen) {
            api.imports.applyImport(id)
                .then(dataproc => {
                    this.setState({
                        dataproc: dataproc,
                        modalOpen: false,
                        loadingimport: false
                    })
                })
        }
        else return
    }
    clearDataProc()
    {
        this.setState({
            dataproc: {},
        })
    }
    applyUploadImport(id) {

        this.setState({
            loadingimport: true,
        });

        if (this.state.upload) {
            api.imports.applyUploadImport(id)
                .then(dataproc => {
                    this.setState({
                        dataproc: dataproc,
                        upload: false,
                        loadingimport: false
                    })
                })
        }
        else return
    }
}


ImportsPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {})(ImportsPage);