import React, {Component} from 'react';
import api from "../../../api";
import TextStore from "../../../stores/TextStore";
import {Header, Icon, Message} from "semantic-ui-react";

class UserInfoView extends Component {
    state = {
        user: {},
        loading: false,
        errors: {}
    };

    componentDidMount() {
        this.setState({loading: true});
        api.profile.getUserInfo()
            .then(user => this.setState({user: user, loading: false}))
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data.errors, loading: false});
                    } else {
                        this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                    }
                } else {
                    this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                }
            });
    }

    render() {
        const {user, loading, errors} = this.state;
        return (
            <div>
                {loading &&
                <Message icon><Icon name="circle notched"
                                    loading/><Message.Header>Загрузка</Message.Header></Message>}

                {!loading && <div>
                    <Header as="h2">{api.applicationUsers.getFio(user)}</Header>
                    <Header as="h4">{api.applicationUsers.getSubInfo(user)}</Header>
                </div>}

                {errors.global && <Message negative>
                    <Message.Header>Ошибка</Message.Header>
                    <p>{errors.global}</p>
                </Message>}
            </div>
        );
    }
}


UserInfoView.propTypes = {};

export default UserInfoView;