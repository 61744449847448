import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Grid, Header, Icon, Message} from "semantic-ui-react";
import {connect} from 'react-redux';
import {resetPassword, validateToken} from '../../actions/auth';
import ResetPasswordForm from '../../components/forms/ResetPasswordForm';
import TextStore from "../../stores/TextStore";



class ResetPasswordPage extends Component {
    state = {
        loading: true,
        success: false
    };

    componentDidMount() {
        this.props.validateToken(this.props.match.params.token)
            .then(() => this.setState({loading: false, success: true}))
            .catch(err => {
                if (!!err.response) {
                    if (err.response.data) {
                        this.setState({errors: err.response.data, loading: false, success: false});
                    } else {
                        this.setState({
                            errors: {global: TextStore.getUnknownNetworkError()},
                            loading: false,
                            success: false
                        });
                    }
                } else {
                    this.setState({
                        errors: {global: TextStore.getMethodNotFoundError()},
                        loading: false,
                        success: false
                    });
                }
            });
    }

    submit = data => this.props.resetPassword(data).then(() => this.props.history.push('/login'));

    render() {
        const {loading, success, errors} = this.state;
        const token = this.props.match.params.token;
        return (
            <Grid className="middle aligned center aligned"
                  style={{height: '100%'}}>
                <div className="column"
                     style={{maxWidth: '450px'}}>
                    <Header className="image">
                        <Header.Content>
                            {!loading && success && "Восстановление пароля"}
                        </Header.Content>
                    </Header>
                    {loading && (
                        <Message icon>
                            <Icon name="circle notched" loading/>
                            <Message.Header>Загрузка</Message.Header>
                        </Message>
                    )}
                    {!loading && success && <ResetPasswordForm submit={this.submit} token={token}/>}
                    {!loading && !success && (
                        <Message negative icon>
                            <Icon name="warning"/>
                            {
                                errors.global
                            }
                        </Message>
                    )}
                </div>
            </Grid>
        );
    }
}

ResetPasswordPage.propTypes = {
    validateToken: PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired,
    match: PropTypes.shape({
        token: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

export default connect(null, {validateToken, resetPassword})(ResetPasswordPage);