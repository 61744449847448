import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Grid, Header, Icon, Message, Segment} from "semantic-ui-react";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import {resetPasswordRequest} from "../../actions/auth";
import "./ForgotPasswordPage.css";

class ForgotPasswordPage extends React.Component {
    state = {
        success: false,
        emailDomain: ''
    };

    submit = data =>
        this.props
            .resetPasswordRequest(data)
            .then(res => {
                let emailDomain = '';
                if (res) {
                    emailDomain = res.replace(/.*@/, "");
                }
                this.setState({success: true, emailDomain: emailDomain});
            });

    render() {
        const {success, emailDomain} = this.state;
        return (
            <Segment id="page">

                        <Header id="image">
                            <Header.Content className ="text">
                                {!success && "Восстановление пароля"}
                            </Header.Content>
                        </Header>
                        {
                            success ? (
                                <Message success icon>
                                    <Icon name="checkmark"/>
                                    На Вашу почту отправлено письмо с инструкциями по восстановлению пароля.
                                    {emailDomain && <a href={"http://" + emailDomain}>Перейти</a>}
                                </Message>
                            ) : (
                                <ForgotPasswordForm id = "form"  submit={this.submit}/>
                            )
                        }

            </Segment>
        )
    }
}

ForgotPasswordPage.propTypes = {
    resetPasswordRequest: PropTypes.func.isRequired
};

export default connect(null, {resetPasswordRequest})(ForgotPasswordPage);