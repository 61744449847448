import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Icon, Message, Modal, Table} from "semantic-ui-react";
import _ from "lodash";
import api from "../../../api";
import InlineError from "../../messages/InlineError";
import TextStore from "../../../stores/TextStore";

class ClientUsersPage extends Component {
    state = {
        isDropConfirmationOpen: false,
        dropEmail: '',
        dropInformation: '',
        dropLoading: false,
        dropSuccess: false,
        dlogin: '',
        demail: '',
        dpassword: '',


        isCreateOpen: false,
        data: {},
        errors: {},
        createLoading: false,
        createSuccess: false,
        createInformation: '',
        clogin: '',
        cemail: '',
        cpassword: ''
    };

    openDropConfirmation = (email) => () => {
        this.setState({dropEmail: email, isDropConfirmationOpen: true});
    };

    closeDropConfirmation = () => {
        this.setState({dropEmail: '', dropInformation: '', dropLoading: false, isDropConfirmationOpen: false});
    };

    openCreateUser = () => {
        this.setState({isCreateOpen: true});
    };
    closeCreateUser = () => {
        this.setState({
            isCreateOpen: false,
            data: {},
            errors: {},
            createLoading: false,
            createSuccess: false,
            createInformation: '',
            clogin: '',
            cemail: '',
            cpassword: ''
        });
    };

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    validate = data => {
        const errors = {};
        if (!data.login) errors.login = "Не может быть пустым";
        if (!data.password) errors.password = "Не может быть пустым";
        return errors;
    };

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({createLoading: true});
            api.admin.addNewUser(this.props.clientId, this.state.data.login, this.state.data.password)
                .then(res => this.setState({
                    createLoading: false, createSuccess: true,
                    clogin: res.login, cemail: res.email, cpassword: res.password
                }))
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({createInformation: err.response.data, createLoading: false});
                        } else {
                            this.setState({
                                createInformation: TextStore.getUnknownNetworkError(),
                                createLoading: false
                            });
                        }
                    } else {
                        this.setState({createInformation: TextStore.getMethodNotFoundError(), createLoading: false});
                    }
                });
        }
    };

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    render() {
        const {
            isDropConfirmationOpen, dropEmail, dropInformation, dropLoading, dropSuccess,
            dlogin, demail, dpassword,
            isCreateOpen, data, errors, createLoading, createSuccess, clogin, cemail, cpassword, createInformation
        } = this.state;
        let rowCount = 0;

        return (
            <div>
                <Table sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>№</Table.HeaderCell>
                            <Table.HeaderCell>ФИО</Table.HeaderCell>
                            <Table.HeaderCell>EMail</Table.HeaderCell>
                            <Table.HeaderCell>Логин</Table.HeaderCell>
                            <Table.HeaderCell/>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(this.props.users, ({id, firstName, lastName, surName, userName, email}) => {
                            rowCount++;
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{rowCount}</Table.Cell>
                                    <Table.Cell>{lastName + " " + firstName + " " + surName}</Table.Cell>
                                    <Table.Cell>{email}</Table.Cell>
                                    <Table.Cell>{userName}</Table.Cell>
                                    <Table.Cell><Button onClick={this.openDropConfirmation(email)}>Сбросить
                                        пароль</Button></Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={'4'}>
                                <b>
                                    Всего: {rowCount}
                                </b>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Button
                                    onClick={this.openCreateUser}
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    positive
                                    size='small'>
                                    <Icon name='plus'/> Добавить
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>

                <Modal closeIcon
                       className="middle aligned center aligned"
                       closeOnDimmerClick={false}
                       open={isDropConfirmationOpen}
                       dimmer="blurring"
                       style={this.inlineStyle.modal}
                       onClose={this.closeDropConfirmation}>
                    <Modal.Header>Сброс пароля</Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        {!dropLoading && !dropInformation && !demail && !dlogin && !dpassword &&
                        <span>Вы уверены, что хотите сбросить пароль для {dropEmail}?</span>}
                        {dropLoading &&
                        <Message icon><Icon name={'circle notched'} loading/> Сбрасываем пароль... </Message>}
                        {!dropLoading && !dropSuccess && <span>{dropInformation}. <br/>Попробовать еще раз?</span>}
                        {!dropLoading && dropSuccess &&
                        <span>EMail: {demail}<br/>login: {dlogin}<br/>password: {dpassword}</span>}
                    </Modal.Content>
                    <Modal.Actions>
                        {!dropLoading && dropSuccess && (
                            <Button positive onClick={() => this.closeDropConfirmation()}>ОК</Button>
                        )}
                        {!dropLoading && !dropSuccess && (
                            <div>
                                <Button negative onClick={() => this.closeDropConfirmation()}>
                                    Нет
                                </Button>
                                <Button positive onClick={() => {
                                    this.setState({dropLoading: true});
                                    api.admin.dropUserPassword(dropEmail)
                                        .then(res => this.setState({
                                            dropLoading: false, dropSuccess: true,
                                            dlogin: res.login, demail: res.email, dpassword: res.password
                                        }))
                                        .catch(err => this.setState({
                                            dropLoading: false,
                                            dropSuccess: false,
                                            dropInformation: err.response.data
                                        }))
                                }}>
                                    Да
                                </Button>
                            </div>
                        )}
                    </Modal.Actions>
                </Modal>

                <Modal closeIcon
                       className="middle aligned center aligned"
                       closeOnDimmerClick={false}
                       open={isCreateOpen}
                       dimmer="blurring"
                       style={this.inlineStyle.modal}
                       onClose={this.closeCreateUser}>
                    <Modal.Header>Создание пользователя</Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        {!createLoading && !createSuccess && <span>{createInformation}</span>}
                        {!createLoading && createSuccess &&
                        <span>EMail: {cemail}<br/>login: {clogin}<br/>password: {cpassword}</span>}
                        {!createSuccess && (
                            <div>
                                <Form loading={createLoading} onSubmit={this.onSubmit}>
                                    <Form.Field error={!!errors.login}>
                                        <label htmlFor="login">Логин</label>
                                        <input
                                            type="text"
                                            id="login"
                                            name="login"
                                            placeholder="user_1"
                                            value={data.login}
                                            onChange={this.onChange}
                                        />
                                        {errors.login && <InlineError text={errors.login}/>}
                                    </Form.Field>
                                    <Form.Field error={!!errors.password}>
                                        <label htmlFor="password">Пароль</label>
                                        <input
                                            type="password"
                                            id="password"
                                            name="password"
                                            placeholder="152623456"
                                            value={data.password}
                                            onChange={this.onChange}
                                        />
                                        {errors.password && <InlineError text={errors.password}/>}
                                    </Form.Field>

                                    <Button primary>Сохранить</Button>
                                </Form>
                            </div>
                        )}
                    </Modal.Content>
                    <Modal.Actions>
                        {!createLoading && createSuccess && (
                            <Button positive onClick={() => this.closeCreateUser()}>ОК</Button>
                        )}
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}


ClientUsersPage.propTypes = {
    users: PropTypes.arrayOf(PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        surName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        userName: PropTypes.string.isRequired
    }).isRequired).isRequired,
    clientId: PropTypes.string.isRequired
};

export default ClientUsersPage;