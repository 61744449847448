import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Header, Icon, Message} from "semantic-ui-react";
import LoginForm from "../../forms/LoginForm";
import AuthStore from "../../../stores/AuthStore";
import {login} from "../../../actions/auth";
import {connect} from "react-redux";

class AdminLoginPage extends React.Component {

    submit = (data) => this.props.login(data).then(() => {
        if (AuthStore.getRoute() === '--logout') {
            AuthStore.setRoute('mtc');
        }
        this.props.history.push("/emagb5ag/clients");
    });

    render() {
        let isAuthenticated = !!AuthStore.getToken();
        return (
            <Grid className="middle aligned center aligned"
                  style={{height: '100%'}}>

                <div className="column"
                     style={{maxWidth: '450px'}}>
                    {!isAuthenticated && (
                        <Header className="image">
                            <Header.Content>
                                Авторизация
                            </Header.Content>
                        </Header>
                    )}
                    {!isAuthenticated && <LoginForm submit={this.submit}/>}
                    {isAuthenticated && (
                        <Message icon>
                            <Icon name="circle notched" loading/>
                            <Message.Header>Загрузка</Message.Header>
                        </Message>
                    )}
                </div>
            </Grid>
        );
    }
}

AdminLoginPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    login: PropTypes.func.isRequired
};

export default connect(null, {login})(AdminLoginPage);