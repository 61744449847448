import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Message, Segment} from "semantic-ui-react";
import InlineError from "../../messages/InlineError";
import TextStore from "../../../stores/TextStore";
import {Redirect} from "react-router";

class PositionForm extends Component {
    state = {
        data: {},
        loading: false,
        errors: {},
        success: false,
        readOnly: false
    };

    constructor(state) {
        super(state);
        this.state.data = state.position;
        this.state.readOnly = state.readOnly;
    }

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data.errors, loading: false});
                        } else {
                            this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                        }
                    } else {
                        this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                    }
                });
        }
    };

    validate = data => {
        const errors = {};

        if (!data.name) errors.name = TextStore.getCantBeBlankError();

        return errors;
    };

    render() {
        const {data, loading, errors, success, readOnly} = this.state;
        return (
            <Form
                loading={loading}
                onSubmit={this.onSubmit}
                className="large">
                <Segment stacked>
                    <Form.Field error={!!errors.name}>
                        <label htmlFor="name">Название</label>
                        <input
                            readOnly={readOnly}
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Название"
                            value={data.name}
                            onChange={this.onChange}
                        />
                        {errors.name && <InlineError text={errors.name}/>}
                    </Form.Field>
                    <Button fluid primary>{readOnly ? "Подтвердить" : "Сохранить"}</Button>
                </Segment>
                {
                    errors.global &&
                    <Message negative>
                        <Message.Header>Ошибка</Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                }
                {success && <Redirect to="/positions"/>}
            </Form>
        );
    }
}


PositionForm.propTypes = {
    submit: PropTypes.func.isRequired
};

export default PositionForm;