import _ from 'lodash'
import React, {Component} from 'react'
import {Button, Dropdown, Icon, Table} from 'semantic-ui-react'
import api from '../../../api';
import {Link} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import * as actions from "../../../actions/auth";
import PropTypes from "prop-types";


class DepartmentsPage extends Component {
    state = {
        column: null,
        data: [],
        direction: null
    };

    componentDidMount() {
        this.updateDrivers();
    }

    updateDrivers() {
        api.departments.getDepartments()
            .then(n => {
                this.setState({
                    data: n.data.departments
                });
            });
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    render() {
        const {column, data, direction} = this.state;

        return (
            <div className="ui container">
                <Table sortable singleLine>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={column === 'name' ? direction : null}
                                              onClick={this.handleSort('name')}>
                                Название
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={column === 'comment' ? direction : null}
                                              onClick={this.handleSort('comment')}>
                                Комментарий
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>
                                Действия
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(data, ({id, name, comment}) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{name}</Table.Cell>
                                    <Table.Cell>{comment}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {this.props.permission.allowEditCatalogs && (
                                            <Dropdown text='Действия'>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        as={Link}
                                                        to={"/department/edit/" + id}
                                                        target={"_blank"}
                                                        icon={"edit"}
                                                        text='Изменить'
                                                    />
                                                    <Dropdown.Item
                                                        as={Link}
                                                        to={"/department/delete/" + id}
                                                        target={"_blank"}
                                                        icon={"delete"}
                                                        text='Удалить'
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>

                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell/>
                            <Table.HeaderCell>
                                {this.props.permission.allowEditCatalogs && (
                                    <Button
                                        as={Link}
                                        to="/department/create/0"
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        positive
                                        size='small'
                                    >
                                        <Icon name='group'/> Добавить
                                    </Button>
                                )}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        )
    }
}


DepartmentsPage.propTypes = {
    permission: PropTypes.shape({
        id: PropTypes.string.isRequired,
        allowCreateItem: PropTypes.bool.isRequired,
        allowEditItem: PropTypes.bool.isRequired,
        allowProgrammingTags: PropTypes.bool.isRequired,
        allowCreateInventory: PropTypes.bool.isRequired,
        allowIssueInventoryTask: PropTypes.bool.isRequired,
        allowCloseInventory: PropTypes.bool.isRequired,
        allowEditCatalogs: PropTypes.bool.isRequired,
        allowCreateUser: PropTypes.bool.isRequired,
        allowEditUser: PropTypes.bool.isRequired,
        allowPrint: PropTypes.bool.isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        permission: state.user.permission
    }
}

export default connect(mapStateToProps, {logout: actions.logout})(DepartmentsPage);