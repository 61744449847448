import axios from 'axios';
import 'moment/locale/ru';
import moment from "moment";

//const apiUrl = 'http://localhost:';
//const apiUrl = 'http://localhost:59728';
const apiUrl = '';


export default {
    formatDate: function(date){
        return  moment(date).locale('ru').format('LL');
    },
    user: {
        login: credentials =>
            axios
                .post(apiUrl + '/api/Auth/Login', {credentials})
                .then(res => {
                    return res.data.user;
                }),
        userInfo: () =>
            axios.post(apiUrl + '/api/auth/userInfo'),
        resetPasswordRequest: email =>
            axios.post(apiUrl + '/api/Auth/ResetPasswordRequest', {email})
                 .then(res => res.data.email),
        validateToken: token =>
            axios.post(apiUrl + '/api/Auth/ValidateToken', {token}),
        resetPassword: data =>
            axios.post(apiUrl + '/api/Auth/ResetPassword', {data}),
    },
    admin: {
        getClients: () =>
            axios.post(apiUrl + '/api/admin/GetClients')
                 .then(res => res.data.clients),
        createClient: (data) =>
            axios.post(apiUrl + '/api/admin/CreateClient', {data})
                 .then(res => res.data),
        dropUserPassword: (userEmail) =>
            axios.post(apiUrl + '/api/admin/DropUserPassword', {userEmail})
                 .then(res => res.data),
        addNewUser: (clientId, userLogin, userPassword) =>
            axios.post(apiUrl + '/api/admin/AddNewUser', {clientId, userLogin, userPassword})
                 .then(res => res.data),
    },
    departments: {
        getDepartments: () =>
            axios.post(apiUrl + '/api/departments/GetDepartments'),
        getDepartment: id =>
            axios.post(apiUrl + '/api/departments/GetDepartment', {id}),
        saveDepartment: department =>
            axios.post(apiUrl + '/api/departments/SaveDepartment', {department}),
        deleteDepartment: id =>
            axios.post(apiUrl + '/api/departments/DeleteDepartment', {id}),
    },
    positions: {
        getPositions: () =>
            axios.post(apiUrl + '/api/positions/GetPositions'),
        getPosition: id =>
            axios.post(apiUrl + '/api/positions/GetPosition', {id}),
        savePosition: position =>
            axios.post(apiUrl + '/api/positions/SavePosition', {position}),
        deletePosition: id =>
            axios.post(apiUrl + '/api/positions/DeletePosition', {id}),
    },
    applicationUsers: {
        getApplicationUsers: () =>
            axios.post(apiUrl + '/api/applicationUsers/GetApplicationUsers'),
        getApplicationUser: id =>
            axios.post(apiUrl + '/api/applicationUsers/GetApplicationUser', {id}),
        saveApplicationUser: applicationUser =>
            axios.post(apiUrl + '/api/applicationUsers/SaveApplicationUser', {applicationUser}),
        deleteApplicationUser: id =>
            axios.post(apiUrl + '/api/applicationUsers/DeleteApplicationUser', {id}),
        getPermission: id =>
            axios.post(apiUrl + '/api/applicationUsers/GetApplicationUserPermission', {id}),
        savePermission: data =>
            axios.post(apiUrl + '/api/applicationUsers/SaveApplicationUserPermission', {data}),

        getFio(applicationUser) {
            let result = '';
            if (!applicationUser)
                return result;
            if (applicationUser.lastName)
                result = result + " " + applicationUser.lastName;
            if (applicationUser.firstName)
                result = result + " " + applicationUser.firstName;
            if (applicationUser.surName)
                result = result + " " + applicationUser.surName;
            result = result.trim();
            return result;
        },
        getSubInfo(applicationUser) {
            let result = '';
            if (!applicationUser)
                return result;
            if (applicationUser.department)
                result = result + ", " + applicationUser.department.name;
            if (applicationUser.position)
                result = result + ", " + (result.length === 0 ? applicationUser.position.name : applicationUser.position.name.toLowerCase());
            result = this.trim(result, ",");
            result = result.trim();
            return result;
        },

        trim(s, c) {
            if (c === "]") c = "\\]";
            if (c === "\\") c = "\\\\";
            return s.replace(new RegExp(
                "^[" + c + "]+|[" + c + "]+$", "g"
            ), "");
        }
    },
    locationItems: {
        getLocationItems: () =>
            axios.post(apiUrl + '/api/locationItems/GetLocationItems')
                .then(res => res.data.locationItems),
        getNotDeletedLocationItems: () =>
            axios.post(apiUrl + '/api/locationItems/GetNotDeletedLocationItems')
                .then(res => res.data.locationItems),
        getLocationItemsInline: () =>
            axios.post(apiUrl + '/api/locationItems/GetLocationItemsInline')
                .then(res => res.data.locationItems),
        getLocationLevelsInline: () =>
            axios.post(apiUrl + '/api/locationItems/GetLocationLevelsInline')
                .then(res => res.data.locationItems),
        saveLocationItem: data =>
            axios.post(apiUrl + '/api/locationItems/SaveLocationItem', {data}),
        deleteLocationItem: id =>
            axios.post(apiUrl + '/api/locationItems/DeleteLocationItem', {id})
    },
    items: {
        get: (locationId, offset, count, column, direction,filter) =>
            axios.post(apiUrl + '/api/items/GetItems', {locationId, offset, count, column, direction,filter})
                .then(res => res.data.items),
        getCount: (locationId,filter) =>
            axios.post(apiUrl + '/api/items/GetItemsCount', {locationId,filter})
                .then(res => res.data.count),
        getSingle: (id, infoId) =>
            axios.post(apiUrl + '/api/items/GetItem', {id, infoId})
                .then(res => res.data.item),
        saveItem: data =>
            axios.post(apiUrl + '/api/items/SaveItem', {data}),
        getItemChanges: id =>
            axios.post(apiUrl + '/api/items/GetItemChanges', {id})
                .then(res => res.data.itemChanges),
        deleteItem: id =>
            axios.post(apiUrl + '/api/items/DeleteItem', {id}),
        applyItemChange: (itemId, infoId) =>
            axios.post(apiUrl + '/api/items/ApplyItemChange', {itemId, infoId}),
        getItemTags: id =>
            axios.post(apiUrl + '/api/items/GetItemTags', {id, infoId: ''})
                .then(res => res.data.tags),
        deleteItemTag: (itemId, tagId) =>
            axios.post(apiUrl + '/api/items/DeleteItemTag', {itemId, tagId}),
        saveItemTag: (itemId, data) =>
            axios.post(apiUrl + '/api/items/SaveItemTag', {itemId, data}),
        applyItemMultiChange:(itemsIds,state,locationId,molId,holderId)=>
            axios.post(apiUrl + '/api/items/ApplyItemMultiChange', {itemsIds,locationId,molId,holderId,state:Number(state)}),
        uploadItemPhoto: (itemId, file) =>
            axios.post(apiUrl + '/api/items/UploadItemPhoto', file, {
                headers: {
                    'ItemId': itemId,
                },
            }),
        downloadItemPhoto: (itemInfoId,onFinish) =>
            axios.post(apiUrl + '/api/items/DownloadItemPhoto', {infoId:itemInfoId}, {responseType:'blob'})
                .then((response)=> {
                    onFinish(URL.createObjectURL(response.data));
                }),
        exportItems:(locationId,filter)=>
            axios.get(apiUrl+'/api/items/ExportItems?locationId='+encodeURIComponent(locationId)+'&filter='+encodeURIComponent(filter),{responseType:'blob'}),
    },
    profile: {
        getUserInfo: () =>
            axios.post(apiUrl + '/api/profile/GetUserInfo')
                .then(res => res.data.user),

        getUserItems: (offset, count) =>
            axios.post(apiUrl + '/api/profile/GetUserItems', {offset, count})
                .then(res => res.data.items),
        getUserItemsFast: (offset, count) =>
            axios.post(apiUrl + '/api/profile/GetUserItemsFast', {offset, count})
                .then(res => res.data.items),
        getUserItemsCount: () =>
            axios.post(apiUrl + '/api/profile/GetUserItemsCount')
                .then(res => res.data.count),

        getUserTasks: (offset, count) =>
            axios.post(apiUrl + '/api/profile/GetUserTasks', {offset, count})
                .then(res => res.data.tasks),

        getUserTasksFast: (offset, count) =>
            axios.post(apiUrl + '/api/profile/GetUserTasksFast', {offset, count})
                .then(res => res.data.tasks),

        getUserTasksCount: () =>
            axios.post(apiUrl + '/api/profile/GetUserTasksCount')
                .then(res => res.data.count)
    },
    mobile:{
        closeTask: (taskid) =>
            axios.post(apiUrl + '/api/mobile/CloseTask', {TaskId:taskid})
                .then(res => res.data.count)
    },
    inventories: {
        get: () =>
            axios.post(apiUrl + '/api/inventories/GetInventories')
                .then(res => res.data.inventories),
        getFast: (offset, count) =>
            axios.post(apiUrl + '/api/inventories/GetInventoriesFast', {offset, count})
                .then(res => res.data.inventories),
        getFastCount: () =>
            axios.post(apiUrl + '/api/inventories/GetInventoriesFastCount')
                .then(res => res.data.count),
        GetInventoryTaskItemsFast: (taskid, allItems, offset, count) =>
            axios.post(apiUrl + '/api/inventories/GetInventoryTaskItemsFast', {taskid, allItems, offset, count})
                .then(res => res.data.items),
        getSingle: id =>
            axios.post(apiUrl + '/api/inventories/GetInventory', {id})
                .then(res => res.data.inventory),
        getSingleFast: id =>
            axios.post(apiUrl + '/api/inventories/GetInventoryFast', {id})
                .then(res => res.data.inventory),
        create: type =>
            axios.post(apiUrl + '/api/inventories/CreateInventory', {type}),
        addLocation: (inventoryId, objectId) =>
            axios.post(apiUrl + '/api/inventories/AddLocation', {inventoryId, objectId}),
        addLocationLevel: (inventoryId, objectId) =>
            axios.post(apiUrl + '/api/inventories/AddLocationLevel', {inventoryId, objectId}),
        deleteLocation: (inventoryId, objectId) =>
            axios.post(apiUrl + '/api/inventories/DeleteLocation', {inventoryId, objectId}),
        addMol: (inventoryId, objectId) =>
            axios.post(apiUrl + '/api/inventories/AddMol', {inventoryId, objectId}),
        deleteMol: (inventoryId, objectId) =>
            axios.post(apiUrl + '/api/inventories/DeleteMol', {inventoryId, objectId}),
        addHolder: (inventoryId, objectId) =>
            axios.post(apiUrl + '/api/inventories/AddHolder', {inventoryId, objectId}),
        deleteHolder: (inventoryId, objectId) =>
            axios.post(apiUrl + '/api/inventories/DeleteHolder', {inventoryId, objectId}),
        addTask: (inventoryId, userId, inventoryObjects) =>
            axios.post(apiUrl + '/api/inventories/AddTask', {inventoryId, userId, inventoryObjects}),
        nextStep: (id) =>
            axios.post(apiUrl + '/api/inventories/NextStep', {id}),
        writeOff: (inventoryId, ids) =>
            axios.post(apiUrl + '/api/inventories/WriteOff', {inventoryId, ids}),
        setItemFound: (inventoryId, ids) =>
            axios.post(apiUrl + '/api/inventories/SetItemFound', {inventoryId, ids}),

        setItemLost: (inventoryId, ids) =>
            axios.post(apiUrl + '/api/inventories/SetItemLost', {inventoryId, ids}),
        setItemLeftNotFound: (inventoryId, ids) =>
            axios.post(apiUrl + '/api/inventories/SetItemLeftNotFound', {inventoryId, ids}),

        getReportInv1: (id) =>
            axios.get(apiUrl + '/api/inventories/GetReportInv1?id=' + id, {responseType: 'blob'}),

        getReportInv2: (id) =>
            axios.get(apiUrl + '/api/inventories/GetReportInv2?id=' + id, {responseType: 'blob'}),



        parseState(state) {
            switch (state) {
                case 0:
                    return 'Подготовка';
                case 1:
                    return 'В работе';
                case 2:
                    return 'Завершена';
                case 3:
                    return 'Закрыта';
                default:
                    return 'Неизвестно';
            }
        },
        parseTaskState(state) {
            switch (state) {
                case 0:
                    return 'Подготовка';
                case 1:
                    return 'В работе';
                case 2:
                    return 'Закрыта';
                default:
                    return 'Неизвестно';
            }
        },
        parseType(type) {
            switch (type) {
                case 0:
                    return 'Местоположения';
                case 1:
                    return 'Держатели';
                case 2:
                    return 'МОЛы';
                default:
                    return 'Неизвестно';
            }
        }
    ,},
    imports: {
        uploadXlsx: (file) =>
            axios.post(apiUrl + '/api/imports/uploadXlsx', file, {responseType:'blob'
            }),
        getFast: (offset, count) => axios.post(apiUrl + '/api/imports/GetImportsFast', {offset, count})
            .then(res => res.data.imports),
        getFastCount: () =>
            axios.post(apiUrl + '/api/imports/GetImportsFastCount')
                .then(res => res.data.count),
        parseFiletype(filetype) {
            switch (filetype) {
                case 0:
                    return 'Excel';
                case 1:
                    return 'CSV';
                case 2:
                    return 'Другое';
                default:
                    return 'Неизвестно';
            }
        },
        deleteImport: id =>
            axios.post(apiUrl + '/api/imports/DeleteImport', {id}),
        unpackImport: id =>
            axios.post(apiUrl + '/api/imports/UnpackImport', {id}),
        getSingleFast:(id, offset, count) =>
            axios.post(apiUrl + '/api/imports/GetProcImportFast', {id, offset, count})
                .then(res => res.data.importstate),
        getFastImportRecCount: (id) =>
            axios.post(apiUrl + '/api/imports/GetFastImportRecCount',{id})
                .then(res => res.data.count),
        applyImport:(id) =>
            axios.post(apiUrl + '/api/imports/applyImport',{id})
                .then(res => res.data.dataproc),
        applyUploadImport:(id) =>
            axios.post(apiUrl + '/api/imports/applyUploadImport',{id})
                .then(res => res.data.dataproc),
    },
    license: {
        getLicenseInfo: () =>
            axios.post(apiUrl + '/api/license/GetLicenseInfo'),
        changeLicenseInfo: (lic) =>
            axios.post(apiUrl + '/api/license/ChangeLicenseInfo',{lic}),
    },

    paging: {
        shortTableRowsCount: 5,
        longTableRowsCount: 30,
        calcShortTablePageCount(rowCount) {
            return Math.ceil(rowCount / this.shortTableRowsCount);
        },
        calcLongTablePageCount(rowCount) {
            return Math.ceil(rowCount / this.longTableRowsCount);
        }
    },};
