import React, {Component} from 'react';
import {Container, Segment, Tab} from "semantic-ui-react";
import UserInfoView from './UserInfoView';
import UserItemsView from './UserItemsView';
import UserTasksView from './UserTasksView';
import PropTypes from "prop-types";

class ProfilePage extends Component {
    state = {};
    panes = [
        { menuItem: 'ТМЦ', render: () => <Tab.Pane><UserItemsView/></Tab.Pane> },
        { menuItem: 'Задачи', render: () => <Tab.Pane><UserTasksView history={this.props.history}/></Tab.Pane> }
    ];
    render() {
        return (
            <Container>
                <Segment>
                    <UserInfoView/>
                    <Tab panes={this.panes} style={{marginTop: '5px'}}/>
                </Segment>
            </Container>
        );
    }
}


ProfilePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

export default ProfilePage;