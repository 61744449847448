import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {Checkbox, Icon, Message, Pagination, Table, Segment} from "semantic-ui-react";
import api from "../../../api";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Pagger from "../../controls/Pagger";
import Select from "semantic-ui-react/dist/es/addons/Select/Select";

class InventoryTaskItemsInfos extends Component {
    state = {
        loading: false,
        task: {},
        selectedIds: [],
        pageCurrent: 1,
        from: 0,
        rowsPerPage: api.paging.longTableRowsCount,
        data: [],
        selectall: false
    };

    constructor(state) {
        super(state);
        this.update = this.update.bind(this);
        this.updateItems = this.updateItems.bind(this);
        this.itemsInfosAction = this.itemsInfosAction.bind(this);
        this.itemsInfosActionFound = this.itemsInfosActionFound.bind(this);
        this.onPageSizeChanged = this.onPageSizeChanged.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
    }

    componentDidMount() {
        this.setState({task: this.props.task, loading: true});
        this.update()
    }

    itemsInfosAction = () => {
        api.inventories.writeOff(this.props.inv, this.state.selectedIds)
            .then(() => this.props.onUpdate())
            .catch(() => console.log("err"));
    };

    itemsInfosActionFound = () => {
        api.inventories.setItemFound(this.props.inv, this.state.selectedIds)
            .then(() => this.props.onUpdate())
            .catch(() => console.log("err"));
    };

    itemsInfosActionLost = () => {
        api.inventories.setItemLost(this.props.inv, this.state.selectedIds)
            .then(() => this.props.onUpdate())
            .catch(() => console.log("err"));
    };
    itemsInfosActionLeftNotFound = () => {
        api.inventories.setItemLeftNotFound(this.props.inv, this.state.selectedIds)
            .then(() => this.props.onUpdate())
            .catch(() => console.log("err"));
    };

    update(){
        this.setState({
            loading: true
        });
        this.updateItems(1, 0, this.state.rowsPerPage)
    }

    updateItems(page, from, rowsPerPage){
        api.inventories.GetInventoryTaskItemsFast(this.props.task.id, !this.props.onlyNotFound, from, rowsPerPage)
            .then(items => this.setState({
                loading: false,
                data: items,
                from: from,
                rowsPerPage: rowsPerPage,
                pageCurrent: page
            }))
    }

    onChangeObjects = (id) => (param, data) => {
        let selectedIds = this.state.selectedIds;
        if (data.checked) {
            if (!selectedIds.includes(id)) {
                selectedIds.push(id);
            }
        } else {
            if (selectedIds.includes(id)) {
                selectedIds.remove(id);
            }
        }
        this.setState({
            selectedIds: selectedIds
        });
    };
    onSelectAll() {
        this.setState({selectedIds: this.state.data.map(i => i.id)})
        this.setState({selectall: true})
    }
    onDeSelectAll(){
        this.setState({selectedIds: []})
        this.setState({selectall: false})
    };
    onPageSizeChanged(e, { name, value })
    {
        this.updateItems(1, 0, value )
    };
    renderTaskItemInfoStates(inventoryItemState) {

        switch (inventoryItemState) {
            case 1:
                return <Segment inverted color='green'>Найдено по ТСД</Segment>;
            case 2:
                return <Segment inverted color='green'>Найдено вручную</Segment>;
            case 3:
                return <Segment inverted color='violet'>Списано</Segment>;
            case 4:
                return <Segment inverted color='red'>Утрачено</Segment>;
            case 5:
                return <Segment inverted color='orange'>Оставлено не найденным</Segment>;
            default:
                return <Segment inverted color='red'>Ошибочное значением</Segment>;
        }}
    render() {
        const {data, loading, pageCurrent, rowsPerPage} = this.state;
        const task = this.props.task;
        const pagesizes = [
            {
                text: api.paging.longTableRowsCount,
                value: api.paging.longTableRowsCount,
            },
            {
                text: 20,
                value:20,
            },
            {
                text: 50,
                value:50,
            },
            {
                text: 100,
                value:100,
            },
        ];
        if (this.props.onlyNotFound && task.itemInfoFound===task.itemInfoAll)
            return null;

        if(loading)
            return (
                <div key={task.id}><h1>{task.text}</h1>
                    <Message icon>
                        <Icon name="circle notched" loading/>
                        <Message.Header>Загрузка</Message.Header>
                    </Message>
                </div>
            );
        return (
                <div key={task.id}><h1>{task.text}</h1>
                    <Table>
                        <Table.Header>
                        <Table.Row>
                            <Pagger colSpan={2}
                                    onPageChanged={this.updateItems}
                                    pageCurrent={pageCurrent}
                                    totalItems={this.props.onlyNotFound?task.itemInfoAll- task.itemInfoFound:task.itemInfoAll}
                                    rowsPerPage={this.state.rowsPerPage}
                                    currentItems={data.length}

                            />
                            <Table.Cell>
                                На странице <Select downward compact onChange={this.onPageSizeChanged} value={rowsPerPage}  options={pagesizes}/>шт
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            {this.props.selectable && <Table.HeaderCell/>}
                            <Table.HeaderCell>Название</Table.HeaderCell>
                            <Table.HeaderCell>Инв. номер</Table.HeaderCell>
                            {!this.props.selectable && <Table.HeaderCell>Результат</Table.HeaderCell>}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(data, (obj) => {
                                return (
                                    <Table.Row key={obj.id}>
                                        {this.props.selectable && <Table.Cell><Checkbox
                                            onChange={this.onChangeObjects(obj.id)}
                                            checked={this.state.selectedIds.indexOf(obj.id)>=0}
                                        /></Table.Cell>}
                                        <Table.Cell>{obj.name}</Table.Cell>
                                        <Table.Cell>{obj.inventoryNumber}</Table.Cell>
                                        {!this.props.selectable && <Table.Cell>{this.renderTaskItemInfoStates(obj.inventoryItemState)}</Table.Cell>}
                                    </Table.Row>
                                );
                            })
                        }
                    </Table.Body>
                    </Table>
                    {this.props.action && <Button.Group>
                        <Button content="Списать"
                                onClick={this.itemsInfosAction}
                                disabled={this.state.selectedIds.length===0}/>
                        <Button content="Найдено"
                                onClick={this.itemsInfosActionFound}
                                disabled={this.state.selectedIds.length===0}/>
                        <Button content="Утрачено"
                                onClick={this.itemsInfosActionLost}
                                disabled={this.state.selectedIds.length===0}/>
                        <Button content="Оставить ненайденным"
                                onClick={this.itemsInfosActionLeftNotFound}
                                disabled={this.state.selectedIds.length===0}/>
                    </Button.Group>}

                    {this.props.selectable && <Button.Group floated='right'>
                    {!this.state.selectall &&
                    <Button
                        onClick={()=>this.onSelectAll()}
                        floated='right'
                        icon
                        labelPosition='left'
                        positive
                        size='small'>
                        <Icon name='edit'/> Выбрать все
                    </Button>}
                    {this.state.selectall &&
                    <Button
                        onClick={()=>this.onDeSelectAll()}
                        floated='right'
                        icon
                        labelPosition='left'
                        negative
                        size='small'>
                        <Icon name='edit'/> Отменить выбор
                    </Button>}
                    </Button.Group>}
                </div>
        );
    }
}


InventoryTaskItemsInfos.propTypes = {
    task: PropTypes.object.isRequired,
    selectable: PropTypes.bool.isRequired,
    action: PropTypes.bool.isRequired,
    onlyNotFound: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    inv: PropTypes.string.isRequired,
};

export default InventoryTaskItemsInfos;