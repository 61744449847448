import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Dropdown, Menu, Modal, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth'
import AuthStore from "../../stores/AuthStore";
import LicenseForm from "../forms/license/LicenseForm";

class TopNavigation extends React.Component {
    state = {activeItem: ''};
    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };

    constructor(state)
    {
        super(state);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.calcState = this.calcState.bind(this);
    }

    componentDidMount() {
        this.calcState();
    }

    calcState(){
        this.setState({activeItem: AuthStore.getRoute()});
    }

    handleItemClick = (e, {name}) => this.setState({activeItem: name});

    render() {
        const {activeItem} = this.state;
        return (
            <Menu stackable inverted size="large">
                <Menu.Item header>
                    Inventory Cloud
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to="/mtc"
                    active={activeItem === 'mtc' || activeItem === ''}
                    name='mtc'
                    onClick={this.handleItemClick}>
                    ТМЦ
                </Menu.Item>
                <Menu.Item

                    as={Link}
                    to="/import"
                    active={activeItem === 'import' || activeItem === ''}
                    name='import'
                    onClick={this.handleItemClick}>
                    Импорт
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to="/inventory"
                    active={activeItem === 'inventory' || activeItem === ''}
                    name='inventory'
                    onClick={this.handleItemClick}>
                    Инвентаризация
                </Menu.Item>

                <Menu.Menu position="right">
                    <Menu.Item
                        as={Link}
                        to='/profile'
                        active={activeItem === 'profile'}
                        name="profile"
                        onClick={this.handleItemClick}>
                        Профиль
                    </Menu.Item>

                    <Dropdown item text='Справочники'>
                        <Dropdown.Menu>
                            <Menu.Item
                                as={Link}
                                to="/departments"
                                active={activeItem === 'departments'}
                                name='departments'
                                onClick={this.handleItemClick}>
                                Подразделения
                            </Menu.Item>
                            <Menu.Item
                                as={Link}
                                to="/positions"
                                active={activeItem === 'positions'}
                                name='positions'
                                onClick={this.handleItemClick}>
                                Должности
                            </Menu.Item>
                            <Menu.Item
                                as={Link}
                                to="/applicationUsers"
                                active={activeItem === 'applicationUsers'}
                                name='applicationUsers'
                                onClick={this.handleItemClick}>
                                Сотрудники
                            </Menu.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown item text='Настройки'>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                as={Link}
                                to="/license"
                                active={activeItem === 'license'}
                                name='license'
                                onClick={this.handleItemClick}>Лицензия</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.props.logout()}>Выход</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
        );
    }

}

TopNavigation.propTypes = {
    user: PropTypes.shape({
        email: PropTypes.string.isRequired,
        permission: PropTypes.shape({
            id: PropTypes.string.isRequired,
            allowCreateItem: PropTypes.bool.isRequired,
            allowEditItem: PropTypes.bool.isRequired,
            allowProgrammingTags: PropTypes.bool.isRequired,
            allowCreateInventory: PropTypes.bool.isRequired,
            allowIssueInventoryTask: PropTypes.bool.isRequired,
            allowCloseInventory: PropTypes.bool.isRequired,
            allowEditCatalogs: PropTypes.bool.isRequired,
            allowCreateUser: PropTypes.bool.isRequired,
            allowEditUser: PropTypes.bool.isRequired,
            allowPrint: PropTypes.bool.isRequired
        }).isRequired
    }).isRequired
};

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, {logout})(TopNavigation);