import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import InventoryTaskItemsInfos from "./InventoryTaskItemsInfos";
import api from "../../../api";


class InventoryItemsInfos extends Component {
    state = {
        tasks: [],
    };


    componentDidMount() {
        this.setState({tasks: this.props.tasks});

    }

    render() {
        const {tasks} = this.state;
        return (
            <div>
                {_.map(tasks, (t) => {
                    return <InventoryTaskItemsInfos
                        task={t}
                        onlyNotFound={this.props.onlyNotFound}
                        selectable={this.props.selectable}
                        action={this.props.action}
                        inv={this.props.inv}
                        onUpdate={this.props.onUpdate}
                    />
                })}
            </div>
        );
    }
}


InventoryItemsInfos.propTypes = {
    tasks: PropTypes.array.isRequired,
    selectable: PropTypes.bool.isRequired,
    action: PropTypes.bool.isRequired,
    onlyNotFound: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    inv: PropTypes.string.isRequired,
};

export default InventoryItemsInfos;