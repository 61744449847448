import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Icon, Message, Modal, Table} from "semantic-ui-react";
import _ from "lodash";
import api from "../../../api";
import TextStore from "../../../stores/TextStore";

class InventoryHoldersView extends Component {
    state = {
        column: '',
        direction: '',
        holderId: '',
        holderName: '',
        open: false,
        loadingAddHolder: false,
        loadingDeleteHolder: false,
        openAddHolder: false,
        holders: [],
        errorsAddHolder: {},
        errorsDeleteHolder: {},
        loading: true,
        selectedHolder: ''
    };

    componentDidMount() {
        api.applicationUsers.getApplicationUsers()
            .then(res => {
                this.setState({
                    loading: false,
                    holders: res.data.applicationUsers.map(x => ({
                        key: x.id,
                        text: x.lastName + " " + x.firstName + " " + x.surName,
                        value: x.id
                    })),
                    selectedHolder: res.data.applicationUsers[0].id
                })
            })
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending'
            });

            return
        }

        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    deleteHolderInternal = (id, name) => () => {
        this.setState({
            holderId: id,
            open: true,
            holderName: name
        })
    };

    close = (id) => () => {
        this.setState({errorsDeleteHolder: {}});
        if (id && id.length > 0) {
            this.setState({loadingDeleteHolder: true});
            this.props.deleteHolder(id)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errorsDeleteHolder: err.response.data.errors, loadingDeleteHolder: false});
                        } else {
                            this.setState({
                                errorsDeleteHolder: {global: TextStore.getUnknownNetworkError()},
                                loadingDeleteHolder: false
                            });
                        }
                    } else {
                        this.setState({
                            errorsDeleteHolder: {global: TextStore.getMethodNotFoundError()},
                            loadingDeleteHolder: false
                        });
                    }
                });
        } else {
            this.setState({open: false});
        }
    };

    showAddHolderItem = () => {
        this.setState({
            openAddHolder: true
        });
    };

    closeAddHolderItem = (id) => () => {
        this.setState({errorsAddHolder: {}});
        if (id && id.length > 0) {
            this.setState({loadingAddHolder: true});
            if (this.props.holders.some(item => item.id === id)) {
                this.setState({
                    errorsAddHolder: {global: 'Держатель уже привязан к инвентаризации.'},
                    loadingAddHolder: false
                });
            } else {
                this.props.addHolder(id)
                // .then(() => this.setState({openAddHolder: false}))
                    .catch(err => {
                        if (!!err.response) {
                            if (err.response.data) {
                                this.setState({errorsAddHolder: err.response.data.errors, loadingAddHolder: false});
                            } else {
                                this.setState({
                                    errorsAddHolder: {global: TextStore.getUnknownNetworkError()},
                                    loadingAddHolder: false
                                });
                            }
                        } else {
                            this.setState({
                                errorsAddHolder: {global: TextStore.getMethodNotFoundError()},
                                loadingAddHolder: false
                            });
                        }
                    });
            }
        } else {
            this.setState({openAddHolder: false});
        }
    };

    onChangeHolder = (param, data) => {
        if (data.value) {
            return this.setState({
                selectedHolder: data.value
            });
        }
    };

    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto',
            position: 'static'
        }
    };

    render() {
        let {
            column, direction, holderId, holderName, open, openAddHolder, holders,
            selectedHolder, loadingAddHolder, errorsAddHolder, loadingDeleteHolder,
            errorsDeleteHolder
        } = this.state;

        const filterholders = holders.filter((v)=>Object.values(this.props.holders).every(l=>l.id!=v.key));

        if(filterholders.length>0 && !filterholders.find(m=>m.key==selectedHolder))
            selectedHolder = filterholders[0].key;

        return (
            <div>
                <Table sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={column === 'lastName' ? direction : null}
                                              onClick={this.handleSort('lastName')}>
                                Имя
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>
                                Действия
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(this.props.holders, ({id, name}) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{name}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {!this.props.readOnly && <Button
                                            onClick={this.deleteHolderInternal(id, name)}
                                            size="mini"
                                            color="red"
                                            icon>
                                            <Icon name="delete"/>
                                        </Button>}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    {!this.props.readOnly && (filterholders.length>0) && <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell>
                                <Button
                                    onClick={this.showAddHolderItem}
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    positive
                                    size='small'>
                                    <Icon name='plus'/>Добавить
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>}
                </Table>
                <Modal size="mini" open={open} style={this.inlineStyle.modal}>
                    <Modal.Header>
                        Удаление держателя
                    </Modal.Header>
                    <Modal.Content>
                        {!loadingDeleteHolder &&
                        <p>Вы уверены, что хотите удалить держателя [{holderName}] из инвентаризации?</p>}
                        {loadingDeleteHolder &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}
                        {errorsDeleteHolder.global &&
                        <Message negative><Message.Header>Ошибка</Message.Header><p>{errorsDeleteHolder.global}</p>
                        </Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Нет"
                            onClick={this.close('')}
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Да'
                            onClick={this.close(holderId)}
                        />
                    </Modal.Actions>
                </Modal>

                {holders && <Modal open={openAddHolder}
                                   style={this.inlineStyle.modal}
                >
                    <Modal.Header>
                        Добавление держателя
                    </Modal.Header>
                    <Modal.Content>
                        {!loadingAddHolder && <Dropdown
                            options={filterholders}
                            button
                            basic
                            fluid
                            scrolling
                            id="holder"
                            name="holder"
                            value={selectedHolder}
                            onChange={this.onChangeHolder}/>}
                        {loadingAddHolder &&
                        <Message icon><Icon name="circle notched"
                                            loading/><Message.Header>Загрузка</Message.Header></Message>}
                        {errorsAddHolder.global &&
                        <Message negative><Message.Header>Ошибка</Message.Header><p>{errorsAddHolder.global}</p>
                        </Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            negative
                            icon="remove"
                            labelPosition="right"
                            content="Нет"
                            onClick={this.closeAddHolderItem('')}
                        />
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Да'
                            onClick={this.closeAddHolderItem(selectedHolder)}
                        />
                    </Modal.Actions>
                </Modal>}
            </div>
        );
    }
}


InventoryHoldersView.propTypes = {
    holders: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        surName: PropTypes.string.isRequired
    })).isRequired,
    readOnly: PropTypes.bool.isRequired,
    deleteHolder: PropTypes.func.isRequired,
    addHolder: PropTypes.func.isRequired
};

export default InventoryHoldersView;