export default class TextStore {
    static UNKNOWN_NETWORK_ERROR = "Сервер временно недоступен";
    static CANT_BE_BLANK_ERROR = "Не может быть пустым";
    static PASSWORD_LENGTH_ERROR = "Пароль не может быть короче 4 символов";

    static getUnknownNetworkError() {
        return this.UNKNOWN_NETWORK_ERROR;
    }

    static getMethodNotFoundError() {
        return this.UNKNOWN_NETWORK_ERROR;
    }

    static getCantBeBlankError() {
        return this.CANT_BE_BLANK_ERROR;
    }

    static getPasswordLengthError() {
        return this.PASSWORD_LENGTH_ERROR;
    }
}