import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button, Dimmer, Dropdown, Form, Icon, Image, Loader, Message, Segment} from "semantic-ui-react";
import api from "../../../api";
import InlineError from "../../messages/InlineError";
import TextStore from "../../../stores/TextStore";




class ItemForm extends Component {
    state = {
        loading: true,
        imgLoading: true,
        data: {
            name: '',
            inventoryNumber: '',
            serialNumber: '',
            dateOfShipment: '',
            barcode: '',
            notes: '',
            price: 0,
            state: 0
        },
        bloburl: undefined,
        errors: {},
        applicationUsers: {},
        locationItems: {},
        states: [{
            key: 0,
            value: 0,
            text: 'Получено'
        }, {
            key: 1,
            value: 1,
            text: 'Оприходовано'
        }, {
            key: 2,
            value: 2,
            text: 'Списано'
        }, {
            key: 3,
            value: 3,
            text: 'Утрачено'
        }
        ]
    };
    /**@type HTMLInputElement */
    fileInput = null;

    constructor(state){
        super(state);
        this.onPhotoUpload = this.onPhotoUpload.bind(this);
        this.onShowSelectPhoto = this.onShowSelectPhoto.bind(this);
    }

    componentDidMount() {
        this.reload()

    }

    reload(){
        this.setState({
            loading: true,
            imgLoading: true
        });
        if (!this.props.readOnly) {
            api.applicationUsers.getApplicationUsers()
                .then(users => {
                    api.locationItems.getNotDeletedLocationItems()
                        .then(locationItems => {
                            let builds = locationItems.flatMap(b => b.children!==null?
                                b.children.flatMap(l=>l.children!==null?
                                    l.children.flatMap( r =>
                                        ({
                                            key: r.id,
                                            text: b.name+"/" + l.name + "/"+ r.name,
                                            value: r.id
                                        })
                                    ):[]):[]);
                            if (this.props.id === '') {
                                let mol = users.data.applicationUsers[0].id;
                                let location = builds[0].key;
                                this.setState({
                                    loading: false,
                                    data: {
                                        name: '',
                                        inventoryNumber: '',
                                        serialNumber: '',
                                        dateOfShipment: new Date().toISOString() ,
                                        barcode: '',
                                        price: 0,
                                        state: 0,
                                        mol: mol,
                                        holder: mol,
                                        location: location
                                    },
                                    applicationUsers: users.data.applicationUsers.map(x => ({
                                        key: x.id,
                                        text: x.lastName + " " + x.firstName + " " + x.surName,
                                        value: x.id
                                    })),
                                    locationItems: builds
                                });
                            } else {

                                api.items.getSingle(this.props.id, this.props.infoId)
                                    .then(res => {
                                        this.setState({
                                            loading: false,
                                            data: res,
                                            applicationUsers: users.data.applicationUsers.map(x => ({
                                                key: x.id,
                                                text: x.lastName + " " + x.firstName + " " + x.surName,
                                                value: x.id
                                            })),
                                            locationItems: builds
                                        });
                                        if(res.hasImage)
                                        {
                                            api.items.downloadItemPhoto(res.id,(url)=>{
                                                this.setState({
                                                    imgLoading: false,

                                                    bloburl: url,
                                                });
                                            })
                                        }
                                    });
                            }
                        })
                })
        } else {
            api.locationItems.getLocationItems()
                .then(locationItems => {
                    let builds = locationItems.flatMap(b => b.children !== null ?
                        b.children.flatMap(l => l.children !== null ?
                            l.children.flatMap(r =>
                                ({
                                    key: r.id,
                                    text: b.name + "/" + l.name + "/" + r.name,
                                    value: r.id
                                })
                            ) : []) : []);

                    api.items.getSingle(this.props.id, this.props.infoId)
                        .then(res => {
                            res.location.name = builds.filter(b=>b.key===res.location.id)[0].text;
                            this.setState({loading: false, data: res, });
                            if (res.hasImage) {
                                api.items.downloadItemPhoto(res.id, (url) => {
                                    this.setState({imgLoading: false, bloburl: url,});
                                })
                            }
                        });
                });
        }
    }

    onChange = e =>
        this.setState({
            ...this.state,
            data: {...this.state.data, [e.target.name]: e.target.value}
        });

    onChangeMol = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, mol: {id: data.value}}
            });
        }
    };
    onChangeHolder = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, holder: {id: data.value}}
            });
        }
    };

    onChangeLocation = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, location: {id: data.value}}
            });
        }
    };

    onChangeState = (param, data) => {
        if (data.value) {
            return this.setState({
                ...this.state,
                data: {...this.state.data, state: data.value}
            });
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {
            this.setState({loading: true});
            this.props.submit(this.state.data)
                .catch(err => {
                    if (!!err.response) {
                        if (err.response.data) {
                            this.setState({errors: err.response.data.errors, loading: false});
                        } else {
                            this.setState({errors: {global: TextStore.getUnknownNetworkError()}, loading: false});
                        }
                    } else {
                        this.setState({errors: {global: TextStore.getMethodNotFoundError()}, loading: false});
                    }
                });
        }
    };

    onPhotoUpload(){
        if(this.fileInput.files.length===0)
            return;
        api.items.uploadItemPhoto(this.props.id, this.fileInput.files[0]).then(()=>this.reload());
    };

    onShowSelectPhoto(){
        this.fileInput.click()
    };


    validate = data => {
        const errors = {};

        return errors;
    };


    render() {
        const {loading,imgLoading, errors, data, applicationUsers, locationItems, states} = this.state;
        const readOnly = this.props.readOnly;
        let stateText = '';
        switch (data.state) {
            case 0:
                stateText = 'Получено';
                break;
            case 1:
                stateText = 'Оприходовано';
                break;
            case 2:
                stateText = 'Списано';
                break;
            case 3:
                stateText = 'Утрачено';
                break;
            default:
                stateText = '';
                break;
        }
        return (
            <Form
                loading={loading}
                onSubmit={this.onSubmit}
                className="large">
                <Form.Field error={!!errors.name}>
                    <label htmlFor="name">Название</label>
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Название"
                        value={data.name}
                        onChange={this.onChange}/>
                    {errors.name && <InlineError text={errors.name}/>}
                </Form.Field>


                {this.props.id!=="" && (data.hasImage||!this.props.readOnly) && (
                    <Form.Field>
                        <input id="photo"
                               multiple={false}
                               hidden
                               type="file"
                               accept="image/jpeg"
                               ref={element => this.fileInput = element}
                               onChange={this.onPhotoUpload}/>
                        {<label htmlFor="itemPhoto">Фото</label>}
                        {data.hasImage && (
                            <Segment>
                                <Dimmer active={imgLoading}>
                                    <Loader>Загрузка изображения</Loader>
                                </Dimmer>
                                <Image id='itemPhoto' centered src={imgLoading?"/noimage.png":this.state.bloburl}/>
                            </Segment>)}
                        {!this.props.readOnly && <Button type="button"
                                                         icon
                                                         labelPosition='left'
                                                         onClick={this.onShowSelectPhoto}>
                            <Icon name="pencil" />
                            Изменить фото
                        </Button>}

                        {errors.photo && <InlineError text={errors.photo}/>}
                    </Form.Field>)}

                <Form.Field error={!!errors.mol}>
                    <label htmlFor="mol">МОЛ</label>
                    {readOnly && data.mol &&
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="mol"
                        name="mol"
                        placeholder="МОЛ"
                        value={data.mol.lastName + " " + data.mol.firstName + " " + data.mol.surName}/>}
                    {!readOnly && data.mol &&
                    <Dropdown
                        options={applicationUsers}
                        button
                        scrolling
                        fluid
                        id="mol"
                        name="mol"
                        value={data.mol.id}
                        onChange={this.onChangeMol}/>}
                    {errors.mol && <InlineError text={errors.mol}/>}
                </Form.Field>

                <Form.Field error={!!errors.holder}>
                    <label htmlFor="holder">Держатель</label>
                    {readOnly && data.holder &&
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="mol"
                        name="mol"
                        placeholder="Держатель"
                        value={data.holder.lastName + " " + data.holder.firstName + " " + data.holder.surName}/>}
                    {!readOnly && data.holder &&
                    <Dropdown
                        readOnly={readOnly}
                        options={applicationUsers}
                        button
                        scrolling
                        fluid
                        id="holder"
                        name="holder"
                        value={data.holder.id}
                        onChange={this.onChangeHolder}/>}
                    {errors.holder && <InlineError text={errors.holder}/>}
                </Form.Field>

                <Form.Field error={!!errors.location}>
                    <label htmlFor="location">Местоположение</label>
                    {readOnly && data.location &&
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="mol"
                        name="mol"
                        placeholder="Местоположение"
                        value={data.location.name}/>}
                    {!readOnly && data.location &&
                    <Dropdown
                        readOnly={readOnly}
                        options={locationItems}
                        button
                        scrolling
                        fluid
                        id="location"
                        name="location"
                        value={data.location.id}
                        onChange={this.onChangeLocation}/>}
                    {errors.location && <InlineError text={errors.location}/>}
                </Form.Field>

                <Form.Field error={!!errors.state}>
                    <label htmlFor="state">Статус</label>
                    {readOnly &&
                    <input
                        readOnly={true}
                        type="text"
                        id="state"
                        name="state"
                        placeholder="Статус"
                        value={stateText}/>}
                    {!readOnly &&
                    <Dropdown
                        readOnly={readOnly}
                        options={states}
                        button
                        scrolling
                        fluid
                        id="state"
                        name="state"
                        value={data.state}
                        onChange={this.onChangeState}/>}
                    {errors.state && <InlineError text={errors.state}/>}
                </Form.Field>

                <Form.Field error={!!errors.inventoryNumber}>
                    <label htmlFor="inventoryNumber">Инвентарный номер</label>
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="inventoryNumber"
                        name="inventoryNumber"
                        placeholder="Инвентарный номер"
                        value={data.inventoryNumber}
                        onChange={this.onChange}
                    />
                    {errors.inventoryNumber && <InlineError text={errors.inventoryNumber}/>}
                </Form.Field>
                <Form.Field error={!!errors.serialNumber}>
                    <label htmlFor="serialNumber">Серийный номер</label>
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="serialNumber"
                        name="serialNumber"
                        placeholder="Серийный номер"
                        value={data.serialNumber}
                        onChange={this.onChange}
                    />
                    {errors.serialNumber && <InlineError text={errors.serialNumber}/>}
                </Form.Field>
                <Form.Field error={!!errors.price}>
                    <label htmlFor="price">Цена</label>
                    <input
                        readOnly={readOnly}
                        type="number"
                        id="price"
                        name="price"
                        placeholder="Цена"
                        value={data.price}
                        onChange={this.onChange}
                    />
                    {errors.price && <InlineError text={errors.price}/>}
                </Form.Field>
                <Form.Field error={!!errors.dateOfShipment}>
                    <label htmlFor="dateOfShipment">Дата поставки на баланс</label>
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="dateOfShipment"
                        name="dateOfShipment"
                        placeholder="Дата потавки на баланс"
                        value={ api.formatDate(data.dateOfShipment)}
                        onChange={this.onChange}
                    />
                    {errors.dateOfShipment && <InlineError text={errors.dateOfShipment}/>}
                </Form.Field>
                <Form.Field error={!!errors.barcode}>
                    <label htmlFor="barcode">Штрихкод</label>
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="barcode"
                        name="barcode"
                        placeholder="Штрихкода"
                        value={data.barcode}
                        onChange={this.onChange}
                    />
                    {errors.barcode && <InlineError text={errors.barcode}/>}
                </Form.Field>
                <Form.Field error={!!errors.notes}>
                    <label htmlFor="notes">Примечание</label>
                    <input
                        readOnly={readOnly}
                        type="text"
                        id="notes"
                        name="notes"
                        placeholder="Примечания"
                        value={data.notes}
                        onChange={this.onChange}
                    />
                    {errors.notes && <InlineError text={errors.notes}/>}
                </Form.Field>


                {!this.props.isShow &&
                <Button fluid primary>{this.props.readOnly ? "Подтвердить" : "Сохранить"}</Button>}
                {
                    errors.global &&
                    <Message negative>
                        <Message.Header>Ошибка</Message.Header>
                        <p>{errors.global}</p>
                    </Message>
                }
            </Form>
        );
    }
}


ItemForm.propTypes = {
    isShow: PropTypes.bool.isRequired,
    submit: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    infoId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default ItemForm;