import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import {Button, Icon, Message, Modal, Table} from "semantic-ui-react";
import api from "../../../api";
import ItemTagForm from '../items/ItemTagForm';

class ItemTagsForm extends Component {
    state = {
        loading: false,
        column: '',
        direction: '',
        data: {},
        open: false,
        readOnly: false,
        tagId: ''
    };

    constructor(state) {
        super(state);
        this.closeItemTag = this.closeItemTag.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        this.updateItemTags();
    }

    updateItemTags() {
        this.setState({loading: true});
        api.items.getItemTags(this.props.id)
            .then(tags => {
                this.setState({
                    loading: false,
                    data: tags
                })
            })
    }

    handleSort = clickedColumn => () => {
        const {column, data, direction} = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: _.sortBy(data, [clickedColumn]),
                direction: 'ascending',
            });
            return
        }
        this.setState({
            data: data.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    closeItemTag = () => this.setState({open: false});

    showItemTag = (caption, id, data, readOnly) => () => {
        this.setState({
            open: true,
            tagId: id,
            tagData: data,
            caption: caption,
            readOnly: readOnly
        })
    };


    submit(data) {
        const {readOnly, tagId} = this.state;
        if (readOnly) {
            return api.items.deleteItemTag(this.props.id, tagId)
                .then(() => {
                    this.closeItemTag();
                    this.updateItemTags();
                });
        } else {
            return api.items.saveItemTag(this.props.id, data)
                .then(() => {
                    this.closeItemTag();
                    this.updateItemTags();
                    console.log('qwe');
                });
        }
    }


    inlineStyle = {
        modal : {
            marginTop: '60px !important',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    };


    render() {
        const {loading, column, direction, data, open, tagId, tagData, readOnly} = this.state;
        return (
            <div>
                {loading &&
                <Message icon><Icon name="circle notched"
                                    loading/><Message.Header>Загрузка</Message.Header></Message>}
                {!loading &&
                <Table sortable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell sorted={column === 'createdBy' ? direction : null}
                                              onClick={this.handleSort('createdBy')}>
                                Метка
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Действия
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {_.map(data, ({id, data}) => {
                            return (
                                <Table.Row key={id}>
                                    <Table.Cell>{data}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        {this.props.readOnly && (
                                            <Button
                                                onClick={this.showItemTag('Удалить', id, data, true)}
                                                size="mini"
                                                color="red"
                                                icon>
                                                <Icon name="delete"/>
                                            </Button>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell/>
                            <Table.HeaderCell>
                                {this.props.readOnly && (
                                    <Button
                                        onClick={this.showItemTag('Создание', '', '', false)}
                                        floated='right'
                                        icon
                                        labelPosition='left'
                                        positive
                                        size='small'>
                                        <Icon name='plus'/>Добавить
                                    </Button>
                                )}
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                }

                <Modal
                    closeIcon
                    className="middle aligned center aligned"
                    open={open}
                    dimmer="blurring"
                    closeOnEscape={true}
                    closeOnRootNodeClick={true}
                    style={this.inlineStyle.modal}
                    onClose={this.closeItemTag}>
                    <Modal.Header>
                        Приложите метку
                    </Modal.Header>
                    <Modal.Content size="medium" scrolling>
                        <ItemTagForm tagId={tagId} readOnly={readOnly} submit={this.submit} tagData={tagData}/>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}


ItemTagsForm.propTypes = {
    id: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default ItemTagsForm;